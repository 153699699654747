<template>
  <div id="global-loader"></div>
</template>

<script setup lang="ts"></script>

<style scoped>
#global-loader {
  top: 50vh;
  left: 50vw;
  position: absolute;
  z-index: 999999;
  width: 200px;
  height: 200px;
  display: block;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #939393;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
</style>
