import PdfIndirectObject from './PdfIndirectObject';
import PdfNumber from './PdfNumber';

export default class PdfIndirectObjectReference {
  static fromIndirectObject(indirectObject: PdfIndirectObject): PdfIndirectObjectReference {
    return new PdfIndirectObjectReference(indirectObject.id, indirectObject.generation);
  }

  constructor(
    public objectNumber: PdfNumber,
    public generation: PdfNumber
  ) {}

  toString(): string {
    return `${this.objectNumber} ${this.generation} R`;
  }
}
