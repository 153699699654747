import { PdfPrimitive } from './PdfPrimitive';

export default class PdfArray<T extends PdfPrimitive> extends Array<T> {
  constructor(value: T[]) {
    super();
    for (const element of value) {
      this.push(element);
    }
  }

  map<U>(callbackfn: (value: T, index: number, array: T[]) => U): U[] {
    const result: U[] = [];
    for (let i = 0; i < this.length; i++) {
      result.push(callbackfn(this[i], i, this));
    }
    return result;
  }

  filter(callbackfn: (value: T, index: number, array: T[]) => unknown): T[] {
    const result: T[] = [];
    for (let i = 0; i < this.length; i++) {
      if (callbackfn(this[i], i, this)) {
        result.push(this[i]);
      }
    }
    return result;
  }

  toString(): string {
    return `[${this.join(' ')}]`;
  }
}
