import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import type Parser from './Parser';
import ReadablePdfStream from '../ReadablePdfStream';
import PdfNull from '../pdfPrimitives/PdfNull';

export default function parseNull(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfNull {
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(4);
  if (nextString !== 'null') {
    throw new NotTheRightPdfElementError(`Expected 'null' but got '${nextString}'`);
  }
  document.read(charactersRead);
  return new PdfNull();
}
