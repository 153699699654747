import { PdfArray, PdfDirectObject, PdfStream } from '..';
import { RawPdfDocument } from './RawPdfDocument';
import DictParser from './parsing/DictParser';
import NumberParser from './parsing/NumberParser';
import StreamParser from './parsing/StreamParser';
import Parser from './parsing/Parser';
import PdfDict from './pdfPrimitives/PdfDict';
import PdfNumber from './pdfPrimitives/PdfNumber';
import ReadablePdfStream from './ReadablePdfStream';

const findFirstXref = (pdfDocument: RawPdfDocument) => {
  pdfDocument.pointer = pdfDocument.bytes.length - 10;
  while (pdfDocument.peekString(9) !== 'startxref') {
    pdfDocument.pointer--;
  }
  pdfDocument.read(9);
  const startXrefOffset = NumberParser(pdfDocument).valueOf();
  pdfDocument.pointer = startXrefOffset;
};

export class PdfReference {
  constructor(
    readonly objectNumber: number,
    readonly generationNumber: number,
    readonly offset: number,
    readonly inUse: boolean,
    readonly objectStreamNumber: number | undefined = undefined
  ) {}
}

export class PdfXrefTable {
  constructor(
    readonly xrefMap: XrefMap,
    readonly trailer: PdfDict,
    readonly nextXrefTable: PdfXrefTable | null
  ) {}
}

export class XrefMap {
  // this is basically a double Map. Map(objectNumber -> Map(generationNumber -> PdfReference))
  readonly map: Map<number, Map<number, PdfReference>> = new Map();

  addReference(reference: PdfReference) {
    if (!this.map.has(reference.objectNumber)) {
      this.map.set(reference.objectNumber, new Map());
    }
    this.map.get(reference.objectNumber)!.set(reference.generationNumber, reference);
  }

  getReference(objectNumber: number, generationNumber: number): PdfReference | null {
    if (!this.map.has(objectNumber)) {
      return null;
    }
    return this.map.get(objectNumber)!.get(generationNumber) || null;
  }

  addContentsOf(other: XrefMap) {
    other.map.forEach((generations, objectNumber) => {
      generations.forEach((reference, generationNumber) => {
        this.addReference(reference);
      });
    });
  }
}

const processXrefInformation = (pdfDocument: RawPdfDocument, parser: Parser): PdfXrefTable => {
  const xref = pdfDocument.peekStringIgnoringLeadingWhitespace(4)[0];
  if (xref === 'xref') {
    pdfDocument.read(4);
    return processXrefTable(pdfDocument, parser);
  }
  const stream = parser.parse(pdfDocument, false) as PdfStream;
  if (!(stream instanceof PdfStream)) {
    throw new Error('xref is not a table and not a stream');
  }
  return processXrefStream(stream, pdfDocument, parser);
};

const processXrefStream = (
  stream: PdfStream,
  pdfDocument: RawPdfDocument,
  parser: Parser
): PdfXrefTable => {
  const xrefs = new XrefMap();

  const size = (stream.dict.get('Size') as PdfNumber).valueOf();
  const index = (stream.dict.get('Index') as PdfArray<PdfNumber> | null)?.map((n) =>
    n.valueOf()
  ) || [0, size];
  const w = (stream.dict.get('W') as PdfArray<PdfNumber>).map((n) => n.valueOf());
  const prev = (stream.dict.get('Prev') as PdfNumber | null)?.valueOf();

  const equivalentTrailerDict = new PdfDict();
  stream.dict.forEach((value, key) => {
    if (key !== 'Size' && key !== 'Prev') {
      equivalentTrailerDict.set(key, value);
    }
  });

  const readeableStream = new ReadablePdfStream(stream.value);

  // console.log('size', size)
  // console.log('index', index)
  // console.log('w', w)
  // console.log('prev', prev)
  // let formatted = '';
  // for (let i = 0; i < index.length; i += 2) {
  //   formatted += `Subsection ${i / 2} from ${index[i]}, containing ${index[i + 1]} entries\n`;

  //   const subsectionStart = index[i];
  //   const subsectionLength = index[i + 1];
  //   for (let j = 0; j < subsectionLength; j++) {
  //     const entry = subsectionStart + j;
  //     formatted += `Entry ${entry}: `;
  //     let peek = ""
  //     let doPeek = false;
  //     for (let k = 0; k < w.length; k++) {
  //       const bytes = readeableStream.read(w[k]);
  //       let value = 0;
  //       // high-order byte first
  //       for (let l = 0; l < bytes.length; l++) {
  //         value = (value << 8) + bytes[l];
  //       }
  //       formatted += `${value} `;
  //       if( k === 0 ) {
  //         doPeek = value === 1;
  //       }
  //       if(k === 1) {
  //         const p = pdfDocument.pointer;
  //         pdfDocument.pointer = value;
  //         peek = pdfDocument.peekString(10);
  //         pdfDocument.pointer = p;
  //       }
  //     }
  //     formatted += `peek: ${peek}`;
  //     formatted += '\n';
  //   }

  // }

  // console.log(formatted)
  // readeableStream.pointer = 0;

  for (let i = 0; i < index.length; i += 2) {
    const subsectionStart = index[i];
    const subsectionLength = index[i + 1];
    for (let j = 0; j < subsectionLength; j++) {
      let type = 1;
      if (w[0] !== 0) {
        const typeBytes = readeableStream.read(w[0]);
        type = 0;
        for (let k = 0; k < typeBytes.length; k++) {
          type = (type << 8) + typeBytes[k];
        }
      }
      if (type === 0) {
        readeableStream.read(w[1] + w[2]);
        continue;
      }
      if (type === 1) {
        let offset = 0;
        if (w[1] !== 0) {
          const offsetBytes = readeableStream.read(w[1]);
          offset = 0;
          for (let k = 0; k < offsetBytes.length; k++) {
            offset = (offset << 8) + offsetBytes[k];
          }
        }
        let generation = 0;
        if (w[2] !== 0) {
          const generationBytes = readeableStream.read(w[2]);
          generation = 0;
          for (let k = 0; k < generationBytes.length; k++) {
            generation = (generation << 8) + generationBytes[k];
          }
        }
        xrefs.addReference(new PdfReference(subsectionStart + j, generation, offset, true));
      } else if (type === 2) {
        let objectStreamNumber = 0;
        if (w[1] !== 0) {
          const objectStreamNumberBytes = readeableStream.read(w[1]);
          objectStreamNumber = 0;
          for (let k = 0; k < objectStreamNumberBytes.length; k++) {
            objectStreamNumber = (objectStreamNumber << 8) + objectStreamNumberBytes[k];
          }
        }
        let indexWithinObjectStream = 0;
        if (w[2] !== 0) {
          const indexWithinObjectStreamBytes = readeableStream.read(w[2]);
          indexWithinObjectStream = 0;
          for (let k = 0; k < indexWithinObjectStreamBytes.length; k++) {
            indexWithinObjectStream =
              (indexWithinObjectStream << 8) + indexWithinObjectStreamBytes[k];
          }
        }
        xrefs.addReference(
          new PdfReference(
            subsectionStart + j,
            0,
            indexWithinObjectStream,
            true,
            objectStreamNumber
          )
        );
      }
    }
  }
  if (!prev) {
    return new PdfXrefTable(xrefs, equivalentTrailerDict, null);
  }
  pdfDocument.pointer = prev;
  return new PdfXrefTable(
    xrefs,
    equivalentTrailerDict,
    processXrefInformation(pdfDocument, parser)
  );
};

const processXrefTable = (pdfDocument: RawPdfDocument, parser: Parser): PdfXrefTable => {
  const xrefs = new XrefMap();

  while (pdfDocument.peekStringIgnoringLeadingWhitespace(7)[0] !== 'trailer') {
    // parse section of xref table
    const firstObjectNumber = NumberParser(pdfDocument).valueOf();
    const numberOfObjects = NumberParser(pdfDocument).valueOf();

    for (let i = firstObjectNumber; i < firstObjectNumber + numberOfObjects; i++) {
      const offset = NumberParser(pdfDocument).valueOf();
      const generation = NumberParser(pdfDocument).valueOf();
      const inUse = pdfDocument.readStringIgnoringLeadingWhitespace(1)[0] === 'n';
      xrefs.addReference(new PdfReference(i, generation, offset, inUse));
    }
  }

  const trailer = pdfDocument.readStringIgnoringLeadingWhitespace(7)[0];
  if (trailer !== 'trailer') {
    throw new Error("pointer not at trailer, expected 'trailer', got " + trailer);
  }

  const trailerDict = DictParser(pdfDocument, parser, false);
  const prev = trailerDict.get('Prev') as PdfNumber | null;
  if (!prev) {
    return new PdfXrefTable(xrefs, trailerDict, null);
  }
  pdfDocument.pointer = prev.valueOf();
  return new PdfXrefTable(xrefs, trailerDict, processXrefInformation(pdfDocument, parser));
};

const concatenateXrefTables = (xrefTable: PdfXrefTable): PdfXrefTable => {
  if (!xrefTable.nextXrefTable) {
    return xrefTable;
  }
  const concatenatedXrefTable = concatenateXrefTables(xrefTable.nextXrefTable);

  const concatenatedTrailer = new PdfDict();
  concatenatedXrefTable.trailer.forEach((value, key) => {
    if (key !== 'Prev') {
      concatenatedTrailer.set(key, value);
    }
  });
  xrefTable.trailer.forEach((value, key) => {
    if (key !== 'Prev') {
      concatenatedTrailer.set(key, value);
    }
  });

  const concatenatedXrefs = new XrefMap();
  concatenatedXrefs.addContentsOf(concatenatedXrefTable.xrefMap);
  concatenatedXrefs.addContentsOf(xrefTable.xrefMap);

  return new PdfXrefTable(concatenatedXrefs, concatenatedTrailer, null);
};

export const generateXref = (pdfDocument: RawPdfDocument, parser: Parser): PdfXrefTable => {
  findFirstXref(pdfDocument);
  const xrefTable = processXrefInformation(pdfDocument, parser);
  const concatenatedXrefTable = concatenateXrefTables(xrefTable);
  return concatenatedXrefTable;
};
