import { type PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import PdfArray from '../pdfPrimitives/PdfArray';
import ArrayCompiler from './ArrayCompiler';
import PdfBoolean from '../pdfPrimitives/PdfBoolean';
import BooleanCompiler from './BooleanCompiler';
import PdfDict from '../pdfPrimitives/PdfDict';
import DictCompiler from './DictCompiler';
import PdfHexString from '../pdfPrimitives/PdfHexString';
import HexStringCompiler from './HexStringCompiler';
import PdfIndirectObject from '../pdfPrimitives/PdfIndirectObject';
import IndirectObjectCompiler from './IndirectObjectCompiler';
import PdfIndirectObjectReference from '../pdfPrimitives/PdfIndirectObjectReference';
import IndirectObjectReferenceCompiler from './IndirectObjectReferenceCompiler';
import PdfName from '../pdfPrimitives/PdfName';
import NameCompiler from './NameCompiler';
import PdfNumber from '../pdfPrimitives/PdfNumber';
import NumberCompiler from './NumberCompiler';
import PdfStream from '../pdfPrimitives/PdfStream';
import StreamCompiler from './StreamCompiler';
import PdfString from '../pdfPrimitives/PdfString';
import StringCompiler from './StringCompiler';
import ContentStreamOperatorCompiler from './ContentStreamOperatorCompiler';
import { PdfCatalog } from '../pdfPrimitives/PdfDict';
import { PdfReference, XrefMap } from '../PdfXrefTable';
import PdfContentStreamOperator from '../pdfPrimitives/PdfContentStreamOperator';

export default class Compiler {
  nextObjectId = 1;
  toBeCompiledIndirectObjects: [PdfIndirectObject, any[]][] = [];
  compiledIndirectObjects: CompiledIndirectObject[] = [];

  currentlyCompilingStack: PdfIndirectObject[] = [];

  compile(catalog: PdfCatalog): Uint8Array {
    this.addIndirectObject(catalog);

    while (this.toBeCompiledIndirectObjects.length > 0) {
      const [indirectObject, compilationArgs] = this.toBeCompiledIndirectObjects.shift()!;
      this.currentlyCompilingStack.push(indirectObject);
      this.compiledIndirectObjects.push(
        new CompiledIndirectObject(
          indirectObject.id.valueOf(),
          indirectObject.generation.valueOf(),
          this.compilePrimitive(indirectObject, ...compilationArgs)
        )
      );
    }

    const headerBytes = Compiler.fromString('%PDF-1.6\r\n%äöüß\r\n');

    const trailer = new PdfDict();
    trailer.set('Size', new PdfNumber(this.nextObjectId));
    trailer.set('Root', this.compiledIndirectObjects[0].toReference());

    const compiledTrailer = this.compilePrimitive(trailer);

    const fillBuffer = (buffer?: Uint8Array) => {
      let pointer = 0;
      const writeToBuffer = (bytes: Uint8Array) => {
        if(buffer) buffer.set(bytes, pointer);
        pointer += bytes.length;
      }

      const xrefMap = new XrefMap();

      writeToBuffer(headerBytes);
      for (const indirectObject of this.compiledIndirectObjects) {
        const reference = new PdfReference(
          indirectObject.objectNumber,
          indirectObject.generationNumber,
          pointer,
          true
        );
        xrefMap.addReference(reference);
        writeToBuffer(indirectObject.bytes);
        const newline = Compiler.fromString('\r\n');
        writeToBuffer(newline);
      }
      const startXrefOffset = pointer;
      writeToBuffer(Compiler.fromString('xref\r\n0 '))
      const numberOfObjects = this.compiledIndirectObjects.length + 1;
      const numberOfObjectsString = numberOfObjects.toString();
      writeToBuffer(Compiler.fromString(numberOfObjectsString))
      writeToBuffer(Compiler.fromString('\r\n'))
      writeToBuffer(Compiler.fromString('0000000000 65535 f\r\n'))
      for (const indirectObject of this.compiledIndirectObjects) {
        const reference = xrefMap.getReference(
          indirectObject.objectNumber,
          indirectObject.generationNumber
        )!;
        const offsetString = reference.offset.toString().padStart(10, '0');
        writeToBuffer(Compiler.fromString(offsetString));
        writeToBuffer(Compiler.fromString(' '));
        const generationString = reference.generationNumber.toString().padStart(5, '0');
        writeToBuffer(Compiler.fromString(generationString));
        writeToBuffer(Compiler.fromString(' n\r\n'));
      }
      writeToBuffer(Compiler.fromString('trailer\r\n'));
      writeToBuffer(compiledTrailer);
      writeToBuffer(Compiler.fromString('\r\nstartxref\r\n'));
      const startXrefOffsetString = startXrefOffset.toString();
      writeToBuffer(Compiler.fromString(startXrefOffsetString));
      writeToBuffer(Compiler.fromString('\r\n%%EOF'));
      return pointer;
    }

    const bufferSize = fillBuffer();
    const buffer = new Uint8Array(bufferSize);
    fillBuffer(buffer);
    return buffer;
  }

  addIndirectObject(object: PdfPrimitive, ...compilationArgs: any[]): PdfIndirectObjectReference {
    const newIndirectObject = new PdfIndirectObject(
      new PdfNumber(this.nextObjectId++),
      new PdfNumber(0),
      object
    );
    const newIndirectObjectReference =
      PdfIndirectObjectReference.fromIndirectObject(newIndirectObject);
    this.toBeCompiledIndirectObjects.push([newIndirectObject, compilationArgs]);
    return newIndirectObjectReference;
  }

  static fromString(str: string) {
    let buffer = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      buffer[i] = str.charCodeAt(i);
    }
    return buffer;
    // character code might be utf-16. In that case it should span multiple bytes
    // const converted = str.split('').map((char) => char.charCodeAt(0));
    // const convertedBytes = converted.map((charCode) => {
    //   const numBytes = charCode === 0 ? 1 : Math.ceil(Math.log2(charCode) / 8);
    //   const bytes = new Uint8Array(numBytes);
    //   for (let i = 0; i < numBytes; i++) {
    //     bytes[i] = charCode % 256;
    //     charCode = Math.floor(charCode / 256);
    //   }
    //   return bytes;
    // })
    // const bytes = new Uint8Array(convertedBytes.reduce((acc, cur) => acc + cur.length, 0));
    // let pointer = 0;
    // for (const byte of convertedBytes) {
    //   bytes.set(byte, pointer);
    //   pointer += byte.length;
    // }
    // return bytes;
  }

  compilePrimitive(primitive: PdfPrimitive, ...compilationArgs: any[]) {
    if (primitive instanceof PdfArray) {
      return ArrayCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfBoolean) {
      return BooleanCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfDict) {
      return DictCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfHexString) {
      return HexStringCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfIndirectObject) {
      return IndirectObjectCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfIndirectObjectReference) {
      return IndirectObjectReferenceCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfName) {
      return NameCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfNumber) {
      return NumberCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfStream) {
      return StreamCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfString) {
      return StringCompiler(primitive, this, ...compilationArgs);
    }
    if (primitive instanceof PdfContentStreamOperator) {
      return ContentStreamOperatorCompiler(primitive, this, ...compilationArgs);
    }
    throw new Error(`Unknown primitive type: ${primitive.constructor.name}`);
  }
}

class CompiledIndirectObject {
  constructor(
    readonly objectNumber: number,
    readonly generationNumber: number,
    readonly bytes: Uint8Array
  ) {}

  toReference() {
    return new PdfIndirectObjectReference(
      new PdfNumber(this.objectNumber),
      new PdfNumber(this.generationNumber)
    );
  }
}
