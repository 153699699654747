import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfIndirectObjectReference from '../pdfPrimitives/PdfIndirectObjectReference';
import { type PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import ReadablePdfStream from '../ReadablePdfStream';
import ArrayParser from './ArrayParser';
import BooleanParser from './BooleanParser';
import DictParser from './DictParser';
import HexStringParser from './HexStringParser';
import IndirectObjectParser from './IndirectObjectParser';
import NameParser from './NameParser';
import NullParser from './NullParser';
import NumberParser from './NumberParser';
import NumberParserWithReferenceCheck from './NumberParserWithReferenceCheck';
import PdfIndirectObjectReferenceParser from './IndirectObjectReferenceParser';
import StreamParser from './StreamParser';
import StringParser from './StringParser';
import ContentStreamOperatorParser from './ContentStreamOperatorParser';
import EofError from '../errors/EofError';

export default class Parser {
  private parsersToTry: ((
    document: ReadablePdfStream,
    parser: Parser,
    resolveIndirect: boolean
  ) => PdfPrimitive)[] = [];
  private parserMode: keyof typeof ParserMode;

  indirectResolver: ((indirectObject: PdfIndirectObjectReference) => PdfPrimitive) | undefined;

  constructor(
    parserMode: keyof typeof ParserMode,
    indirectResolver?: (indirectObject: PdfIndirectObjectReference) => PdfPrimitive
  ) {
    this.parserMode = parserMode;
    this.parsersToTry = ParserMode[parserMode];
    this.indirectResolver = indirectResolver;
  }

  parse(document: ReadablePdfStream, resolveIndirect: boolean): PdfPrimitive {
    let parsedElement: PdfPrimitive | null = null;
    for (const parser of this.parsersToTry) {
      try {
        parsedElement = parser(document, this, resolveIndirect);
        break;
      } catch (e) {
        if (e instanceof NotTheRightPdfElementError || e instanceof EofError) {
          continue;
        } else {
          throw e;
        }
      }
    }
    if (!parsedElement) {
      console.trace();
      console.warn(document.peekForErrorMessage(50));
      document.pointer = 0;
      console.warn(document.bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), ''));
      throw new Error('Could not find matching class for next element');
    }
    if (
      resolveIndirect &&
      this.indirectResolver &&
      parsedElement instanceof PdfIndirectObjectReference
    ) {
      const pointerBeforeReolve = document.pointer;
      parsedElement = this.indirectResolver(parsedElement);
      document.pointer = pointerBeforeReolve;
    }
    return parsedElement;
  }
}

export const ParserMode = {
  PDF: [
    NameParser,
    ArrayParser,
    DictParser,
    StreamParser,
    StringParser,
    HexStringParser,
    NumberParserWithReferenceCheck,
    BooleanParser,
    NullParser,
    IndirectObjectParser,
    PdfIndirectObjectReferenceParser
  ],
  CONTENT_STREAM: [
    NameParser,
    ArrayParser,
    ContentStreamOperatorParser,
    DictParser,
    StringParser,
    HexStringParser,
    NumberParser,
    BooleanParser,
    NullParser
  ]
};
