import PdfBoolean from '../pdfPrimitives/PdfBoolean';
import Compiler from './Compiler';

export default function compile(
  pdfBoolean: PdfBoolean,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  return Compiler.fromString(pdfBoolean.toString());
}
