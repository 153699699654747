<template>
  <div
    @dragover.prevent="handleDragOver"
    @dragenter.prevent
    @dragleave="handleDragLeave"
    @drop.prevent="handleDrop"
    :class="{ hovering: isHovering }"
    class="uploader"
    @click="triggerFileInput"
  >
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      style="display: none"
      accept="application/pdf"
    />
    <Button @click.stop="triggerFileInput">Upload File</Button>
    <span>or drag and drop here.</span>
    <InlineMessage severity="error" v-if="errorMessage" class="error">{{
      errorMessage
    }}</InlineMessage>
    <div v-if="errorMessage">
      If you believe this is an error with the application, you can help us by sending the file to
      <a href="mailto:technik@printweb.de" @click.stop target="_blank">our customer support.</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import { ref } from 'vue';
import InlineMessage from 'primevue/inlinemessage';

const props = defineProps<{
  errorMessage: string | null;
}>();

const fileInput = ref<HTMLInputElement | null>(null);
const isHovering = ref(false);

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    const file = target.files[0];
    upload(file);
  }
};

const handleDrop = (event: DragEvent) => {
  isHovering.value = false;

  if (event.dataTransfer && event.dataTransfer.files) {
    const file = event.dataTransfer.files[0];
    upload(file);
  }
};

const upload = (file: File) => {
  emit('upload', file);
};

const handleDragOver = () => {
  isHovering.value = true;
};

const handleDragLeave = () => {
  isHovering.value = false;
};

const triggerFileInput = () => {
  fileInput.value?.click();
};

const emit = defineEmits<{
  (event: 'upload', file: File): void;
}>();
</script>

<style scoped>
.uploader {
  width: 100%;
  min-height: 180px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  padding-inline: 1rem;
  padding-block: 3rem;
  text-align: center;
}

.uploader.hovering {
  border: 2px dashed #8a8a8a;
}

button {
  padding: 10px 20px;
  cursor: pointer;
}

span {
  display: block;
  margin-top: 1rem;
}

.error {
  margin-top: 2rem;
}
</style>
