import type { PdfPrimitive } from './pdfPrimitives/PdfPrimitive';
import { AbstractPdfDocument } from './AbstractPdfDocument';
import { RawPdfDocument } from './RawPdfDocument';
import PdfDict, { PdfFont, PdfPage } from './pdfPrimitives/PdfDict';
import PdfStream from './pdfPrimitives/PdfStream';
import PdfArray from './pdfPrimitives/PdfArray';
import PdfName from './pdfPrimitives/PdfName';
import PdfNumber from './pdfPrimitives/PdfNumber';

class PdfDocument extends AbstractPdfDocument {
  constructor(bytes: Uint8Array) {
    super(new RawPdfDocument(bytes));
    console.log(this);
  }

  private onChangeHandler: (newBytes: Uint8Array) => void = () => {};

  setOnChangeHandler = (onChangeHandler: (newBytes: Uint8Array) => void) => {
    this.onChangeHandler = onChangeHandler;
  };

  triggerChange(newBytes: Uint8Array) {
    this.onChangeHandler(newBytes);
  }
}

export { PdfDocument };
