import PdfNumber from './pdfPrimitives/PdfNumber';
import { PdfXrefTable, generateXref } from './PdfXrefTable';
import { RawPdfDocument } from './RawPdfDocument';
import PdfArray from './pdfPrimitives/PdfArray';
import PdfDict, { PdfCatalog, PdfPage, PdfPages } from './pdfPrimitives/PdfDict';
import PdfIndirectObjectReference from './pdfPrimitives/PdfIndirectObjectReference';
import Parser from './parsing/Parser';
import { type PdfPrimitive } from './pdfPrimitives/PdfPrimitive';
import PdfNull from './pdfPrimitives/PdfNull';
import Compiler from './compiling/Compiler';
import { ContentStream } from './contentStream/ContentStream';
import { PdfStream } from '..';
import NumberParser from './parsing/NumberParser';

export class AbstractPdfDocument {
  pages: PdfPage[] = [];
  parser: Parser;
  rawDocument: RawPdfDocument;

  parsedContentStreamPerPage: ContentStream[] = [];

  pageViewport: { x1: number; x2: number; y1: number; y2: number }[] = [];

  constructor(rawPdfDocument: RawPdfDocument) {
    this.rawDocument = rawPdfDocument;
    this.parser = new Parser('PDF', (obj) => {
      return this.parseElement(obj.objectNumber.valueOf(), obj.generation.valueOf(), xref);
    });

    const xref = generateXref(rawPdfDocument, this.parser);
    const root = xref.trailer.get('Root')! as PdfIndirectObjectReference;

    const catalog = this.parseElement(
      root.objectNumber.valueOf(),
      root.generation.valueOf(),
      xref
    ) as PdfCatalog;

    const parsePageTreeNode = (primitivePageTreeNode: PdfPages): PdfPage[] => {
      const pages: (PdfPage | PdfPages)[] = [];
      for (const pageNode of primitivePageTreeNode.get('Kids') as PdfArray<PdfPage | PdfPages>) {
        pages.push(pageNode);
      }
      return pages.flatMap((pageNode) => {
        let result;

        if (pageNode instanceof PdfPage) result = pageNode;
        else result = parsePageTreeNode(pageNode);
        return result;
      });
    };
    this.pages = parsePageTreeNode(catalog.get('Pages') as PdfPages);

    for (const page of this.pages) {
      const MediaBox = page.get('MediaBox')! as PdfArray<PdfNumber>;
      this.pageViewport.push({
        x1: MediaBox[0]!.valueOf(),
        x2: MediaBox[2]!.valueOf(),
        y1: MediaBox[1]!.valueOf(),
        y2: MediaBox[3]!.valueOf()
      });

      const contentStream = page.get('Contents');
      if (contentStream instanceof PdfStream)
        this.parsedContentStreamPerPage.push(new ContentStream(contentStream.value, page));
      else if (contentStream instanceof PdfArray) {
        const contentStreamValues: Uint8Array[] = [];
        for (const contentStreamElement of contentStream) {
          contentStreamValues.push((contentStreamElement as PdfStream).value);
        }
        const joinedContentStream = new Uint8Array(
          contentStreamValues.reduce((acc, cur) => acc + cur.length, contentStreamValues.length - 1)
        );
        let pointer = 0;
        for (const contentStreamValue of contentStreamValues) {
          joinedContentStream.set(contentStreamValue, pointer);
          pointer += contentStreamValue.length;
          joinedContentStream.set(['\n'.charCodeAt(0)], pointer);
        }
        this.parsedContentStreamPerPage.push(new ContentStream(joinedContentStream, page));
      }
    }
  }

  compile(): Uint8Array {
    const compiler = new Compiler();
    const catalog = new PdfCatalog();
    const pages = new PdfPages();
    this.pages.forEach((page, index) => {
      page.setContentStream(this.parsedContentStreamPerPage[index].toPdfStream());
    });

    pages.set('Kids', new PdfArray(this.pages));
    pages.set('Count', new PdfNumber(this.pages.length));

    catalog.set('Pages', pages);

    return compiler.compile(catalog);
  }

  getPdfWithoutSpotColors(names: string[]): Uint8Array {
    const compiler = new Compiler();
    const catalog = new PdfCatalog();
    const pages = new PdfPages();
    this.pages.forEach((page, index) => {
      page.setContentStream(this.parsedContentStreamPerPage[index].getWithoutSpotColors(names));
    });

    pages.set('Kids', new PdfArray(this.pages));
    pages.set('Count', new PdfNumber(this.pages.length));

    catalog.set('Pages', pages);

    return compiler.compile(catalog);
  }

  getPdfWithOnlySpotColor(spotColorName: string): Uint8Array {
    const compiler = new Compiler();
    const catalog = new PdfCatalog();
    const pages = new PdfPages();
    this.pages.forEach((page, index) => {
      page.setContentStream(
        this.parsedContentStreamPerPage[index].getWithOnlySpotColor(spotColorName)
      );
    });

    pages.set('Kids', new PdfArray(this.pages));
    pages.set('Count', new PdfNumber(this.pages.length));

    catalog.set('Pages', pages);

    return compiler.compile(catalog);
  }

  parseElement(objectNumber: number, generationNumber: number, xref: PdfXrefTable): PdfPrimitive {
    const generations = xref.xrefMap.map.get(objectNumber);
    if (!generations) {
      console.warn(`No generations found for object number ${objectNumber}`);
      return new PdfNull();
    }
    const reference = generations.get(generationNumber);
    if (!reference) {
      console.warn(
        `No reference found for object number ${objectNumber} and generation ${generationNumber}`
      );
      return new PdfNull();
    }
    if (typeof reference.objectStreamNumber === 'undefined') {
      const position = reference!.offset;
      this.rawDocument.pointer = position;

      const element = this.parser.parse(this.rawDocument, true);
      return element;
    } else {
      const objectStream = this.parseElement(reference.objectStreamNumber, 0, xref) as PdfStream;
      const readeableStream = new RawPdfDocument(objectStream.value);
      const n = (objectStream.dict.get('N') as PdfNumber).valueOf();
      const first = (objectStream.dict.get('First') as PdfNumber).valueOf();
      const localXref = new Map<number, number>();
      for (let i = 0; i < n; i++) {
        const objectNumber = NumberParser(readeableStream).valueOf();
        const offset = NumberParser(readeableStream).valueOf();
        localXref.set(objectNumber, offset);
      }
      const position = localXref.get(objectNumber);
      if (typeof position === 'undefined')
        throw new Error(
          `No position found for object number ${objectNumber} in object stream ${reference.objectStreamNumber}`
        );
      readeableStream.pointer = first + position;
      const element = this.parser.parse(readeableStream, true);

      return element;
    }
  }
}
