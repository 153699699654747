import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { PdfDocument, SvgPathElement, TextElement } from 'pdfLib';

export type ObjectWithId = { idForSelection: string };
export type TextElementWithId = TextElement &
  ObjectWithId & { page: number; rawElement: TextElement };
export type PathElementWithId = SvgPathElement &
  ObjectWithId & { page: number; rawElement: SvgPathElement, fillColorSpaceNames: string[], strokeColorSpaceNames: string[] };

export const usePdfStore = defineStore('pdf', () => {
  const pdfDocument = ref<PdfDocument | null>(null);
  const previewBlobs = ref<Blob[] | null>(null);
  const previewUrls = ref<string[] | null>(null);
  const generatePreviews = async (canvases: HTMLCanvasElement[]) => {
    previewBlobs.value = await Promise.all(
      canvases.map((canvas) =>
        new Promise<Blob>((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob!);
          });
        })
      )
    );
    previewUrls.value = canvases.map((canvas) => canvas.toDataURL('image/png'));
  }

  const textElements = computed<TextElementWithId[]>(() => {
    if (!pdfDocument.value) return [];
    return pdfDocument.value.parsedContentStreamPerPage.flatMap((page, pageIndex) => {
      return (
        page.elements.filter((element) => {
          return element instanceof TextElement && element.text.length > 0;
        }) as TextElement[]
      )
        .map((element, index) => {
          return {
            ...element,
            hasStroke: element.hasStroke,
            hasFill: element.hasFill,
            text: element.text,
            canBeSplitIntoLetters: element.canBeSplitIntoLetters,
            canBeSplitIntoWords: element.canBeSplitIntoWords,
            isUnsplittableWithMultipleWords: element.isUnsplittableWithMultipleWords,
            fillColorSpaceNames: element.fillColorSpaceNames,
            strokeColorSpaceNames: element.strokeColorSpaceNames,
            page: pageIndex,
            rawElement: element,
            idForSelection: `${pageIndex}-${element.printwebId}`
          } as TextElementWithId;
        })
        .reduce((acc, element) => {
          const indexOfElementWithSameId = acc.findIndex(
            (elementInAcc) => elementInAcc.idForSelection === element.idForSelection
          );
          if (indexOfElementWithSameId === -1) {
            acc.push(element);
            return acc;
          }
          const elementWithSameId = acc[indexOfElementWithSameId];
          const concattedElement = {
            ...elementWithSameId,
            hasStroke: elementWithSameId.hasStroke || element.hasStroke,
            hasFill: elementWithSameId.hasFill || element.hasFill,
            fillColorSpaceNames: [
              ...new Set([...elementWithSameId.fillColorSpaceNames, ...element.fillColorSpaceNames])
            ],
            strokeColorSpaceNames: [
              ...new Set([
                ...elementWithSameId.strokeColorSpaceNames,
                ...element.strokeColorSpaceNames
              ])
            ],
            hasUndoeableOperations:
              elementWithSameId.hasUndoeableOperations || element.hasUndoeableOperations
          } as TextElementWithId;
          acc.splice(indexOfElementWithSameId, 1, concattedElement);
          return acc;
        }, [] as TextElementWithId[]);
    });
  });

  const selectedTextElements = ref<ObjectWithId[]>([]);

  const toggleTextElementSelectionForId = (id: string) => {
    const index = selectedTextElements.value.findIndex((element) => element.idForSelection === id);
    if (index === -1) {
      selectedTextElements.value.push(
        textElements.value.find((element) => element.idForSelection === id)!
      );
    } else {
      selectedTextElements.value.splice(index, 1);
    }
  };

  const textElementSelectionFilter = ref<ObjectWithId[] | null>(null);

  const pathElements = computed<PathElementWithId[]>(() => {
    if (!pdfDocument.value) return [];
    return pdfDocument.value.parsedContentStreamPerPage.flatMap((page, pageIndex) => {
      return (
        page.elements.filter((element) => {
          return element instanceof SvgPathElement;
        }) as SvgPathElement[]
      ).map((element, index) => {
        return {
          ...element,
          page: pageIndex,
          rawElement: element,
          fillColorSpaceNames: [element.fillSpace.name],
          strokeColorSpaceNames: [element.strokeSpace.name],
          idForSelection: `${pageIndex}-${element.printwebId}`
        } as PathElementWithId;
      }).reduce((acc, element) => {
        const indexOfElementWithSameId = acc.findIndex(
          (elementInAcc) => elementInAcc.idForSelection === element.idForSelection
        );
        if (indexOfElementWithSameId === -1) {
          acc.push(element);
          return acc;
        }
        const elementWithSameId = acc[indexOfElementWithSameId];
        const concattedElement = {
          ...elementWithSameId,
          fillColorSpaceNames: [
            ...new Set([...elementWithSameId.fillColorSpaceNames, ...element.fillColorSpaceNames])
          ],
          strokeColorSpaceNames: [
            ...new Set([
              ...elementWithSameId.strokeColorSpaceNames,
              ...element.strokeColorSpaceNames
            ])
          ],
          hasUndoeableOperations:
            elementWithSameId.hasUndoeableOperations || element.hasUndoeableOperations
        } as PathElementWithId;
        acc.splice(indexOfElementWithSameId, 1, concattedElement);
        return acc;
      }, [] as PathElementWithId[]);
    });
  });

  const selectedPathElements = ref<ObjectWithId[]>([]);

  const togglePathElementSelectionForId = (id: string) => {
    const index = selectedPathElements.value.findIndex((element) => element.idForSelection === id);
    if (index === -1) {
      selectedPathElements.value.push(
        pathElements.value.find((element) => element.idForSelection === id)!
      );
    } else {
      selectedPathElements.value.splice(index, 1);
    }
  };

  const pathElementSelectionFilter = ref<ObjectWithId[] | null>(null);

  const proMode = ref(false);
  const devMode = ref(false);

  const clear = () => {
    pdfDocument.value = null;
    previewBlobs.value = null;
    selectedTextElements.value = [];
    selectedPathElements.value = [];
    textElementSelectionFilter.value = null;
    pathElementSelectionFilter.value = null;
    proMode.value = false;
    devMode.value = false;
  };

  return {
    pdfDocument,
    clear,
    previewBlobs,
    previewUrls,
    generatePreviews,
    proMode,
    devMode,
    textElements,
    selectedTextElements,
    textElementSelectionFilter,
    toggleTextElementSelectionForId,
    pathElements,
    selectedPathElements,
    togglePathElementSelectionForId,
    pathElementSelectionFilter
  };
});
