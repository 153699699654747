import PdfIndirectObjectReference from '../pdfPrimitives/PdfIndirectObjectReference';
import Compiler from './Compiler';

export default function compile(
  reference: PdfIndirectObjectReference,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  return Compiler.fromString(reference.toString());
}
