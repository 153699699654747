<template>
  <div ref="container" class="pdf-page-container">
    <svg width="100%" height="100%" @click="handleClick">
      <rect v-for="pathElement in pathElements" :key="pathElement.id" :x="pathElement.x" :y="pathElement.y" width="15"
        height="15" class="path-selector" :class="{ selected: pathElement.selected }" />
      <text v-for="pathElement in pathElements" :key="pathElement.id" :x="pathElement.x" :y="pathElement.y"
        font-size="13px" class="path-selector-text" :class="{ selected: pathElement.selected }">/</text>

      <rect v-for="textElement in textElements" :key="textElement.id" :x="textElement.x" :y="textElement.y" width="15"
        height="15" class="text-selector" :class="{ selected: textElement.selected }" />
      <text v-for="textElement in textElements" :key="textElement.id" :x="textElement.x" :y="textElement.y"
        font-size="13px" class="text-selector-text" :class="{ selected: textElement.selected }">T</text>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { usePdfStore } from '@/stores/pdf';
import { SVG } from '@svgdotjs/svg.js';
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  canvas: HTMLCanvasElement;
  pageIndex: number;
}>();
const container = ref<HTMLElement>();

const pdfStore = usePdfStore();

const pdfViewport = computed(() => pdfStore.pdfDocument!.pageViewport[props.pageIndex]);
const pdfWidth = computed(() => pdfViewport.value.x2 - pdfViewport.value.x1);
const pdfHeight = computed(() => pdfViewport.value.y2 - pdfViewport.value.y1);

const renderedCanvasSize = ref({
  width:0,
  height:0
});


const pathElements = computed(() => pdfStore.pathElements
  .filter((element) => element.page === props.pageIndex)
  .map((element) => ({
    x: ((element.anchor!.x - pdfViewport.value.x1) / pdfWidth.value) * renderedCanvasSize.value.width,
    y: ((1 - (element.anchor!.y - pdfViewport.value.y1) / pdfHeight.value)) * renderedCanvasSize.value.height,
    id: element.idForSelection,
    selected: pdfStore.selectedPathElements.map(e => e.idForSelection).includes(element.idForSelection)
  }))
);

const textElements = computed(() => pdfStore.textElements
  .filter((element) => element.page === props.pageIndex)
  .map((element) => ({
    x: ((element.anchor!.x - pdfViewport.value.x1) / pdfWidth.value) * renderedCanvasSize.value.width,
    y: ((1 - (element.anchor!.y - pdfViewport.value.y1) / pdfHeight.value)) * renderedCanvasSize.value.height,
    id: element.idForSelection,
    selected: pdfStore.selectedTextElements.map(e => e.idForSelection).includes(element.idForSelection)
  }))
);

const handleClick = (e: MouseEvent) => {
  const div = document.createElement('div');
  div.classList.add('circleRiple');
  div.style.top = e.offsetY + 'px';
  div.style.left = e.offsetX + 'px';
  container.value!.append(div);

  const x = e.offsetX;
  const y = e.offsetY;
  const potentialPathElements = pathElements.value.filter((element) => {
    const distance = Math.sqrt((element.x - x) ** 2 + (element.y - y) ** 2);
    return distance < 30;
  }).map((element) => pdfStore.pathElements.find((pathElement) => pathElement.idForSelection === element.id)!);
  const potentialTextElements = textElements.value.filter((element) => {
    const distance = Math.sqrt((element.x - x) ** 2 + (element.y - y) ** 2);
    return distance < 30;
  }).map((element) => pdfStore.textElements.find((textElement) => textElement.idForSelection === element.id)!);

  if (potentialTextElements.length === 0 && potentialPathElements.length === 0) {
    pdfStore.textElementSelectionFilter = null;
    pdfStore.pathElementSelectionFilter = null;
    return;
  }

  potentialPathElements.forEach((element) => pdfStore.togglePathElementSelectionForId(element.idForSelection));
  pdfStore.pathElementSelectionFilter = potentialPathElements;
  potentialTextElements.forEach((element) => pdfStore.toggleTextElementSelectionForId(element.idForSelection));
  pdfStore.textElementSelectionFilter = potentialTextElements;

  setTimeout(() => {
    div.remove();
  }, 400);
};


const resizeObserver = ref();
const updateCanvas = (canvas: HTMLCanvasElement) => {
  const existingCanvas = container.value!.querySelector('canvas');
  if (existingCanvas) {
    resizeObserver.value?.disconnect();
    existingCanvas.remove();
  }
  container.value!.appendChild(canvas);
  renderedCanvasSize.value = {
    width: canvas.clientWidth,
    height: canvas.clientHeight
  };
  resizeObserver.value = new ResizeObserver(() => {
    renderedCanvasSize.value = {
      width: canvas.clientWidth,
      height: canvas.clientHeight
    };
  }).observe(canvas);
};

onMounted(() => {
  updateCanvas(props.canvas);
});
watch(() => props.canvas, (canvas) => {
  updateCanvas(canvas);
});
</script>
<style scoped>
.pdf-page-container {
  position: relative;
  margin-top: 3.5em;

  canvas {
    background-color: transparent;
    max-width: 100vh;
    width: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: visible;
    cursor: pointer;

    & * {
      pointer-events: none;
    }

    .path-selector,
    .text-selector {
      fill: var(--surface-d);
      stroke: black;
      translate: -7.5px -7.5px;

      &.selected {
        fill: black;
        stroke: white;
      }

      &:hover {
        fill: #4a4a4a;
        stroke: white;
      }
    }

    .path-selector-text,
    .text-selector-text {
      translate: -2px 4px;

      &.selected {
        fill: white;
      }
    }

    .text-selector-text {
      translate: -4px 4px;
      font-family: 'Times New Roman', serif;
    }
  }
}
</style>