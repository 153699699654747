// stores/counter.js
import { defineStore } from 'pinia';
import { useSubscription } from '@vue/apollo-composable';

import { watch } from 'vue';
import { apollo } from '@/graphql/apollo';
import type {
  BackgroundMaterialFragment,
  InsertMaterialLabelMutationVariables,
  MaterialLabelFragment,
  Printweb_Instance_Background_Material_Updates,
  Printweb_Material_Label_Insert_Input,
  Printweb_Material_Label_Updates
} from '@/gql_gen/graphql';
import {
  FETCH_BACKGROUND_MATERIALS_SUBSCRIPTION,
  FETCH_MATERIAL_LABELS_SUBSCRIPTION,
  FETCH_SPOT_COLORS_SUBSCRIPTION
} from '@/graphql/subscriptions';
import {
  DELETE_BACKGROUND_MATERIAL_MUTATION,
  DELETE_MATERIAL_LABEL_MUTATION,
  DELETE_SPOT_COLOR_MUTATION,
  INSERT_BACKGROUND_MATERIAL_MUTATION,
  INSERT_MATERIAL_LABEL,
  INSERT_SPOT_COLOR_MUTATION,
  UPDATE_BACKGROUND_MATERIAL_MUTATION,
  UPDATE_MATERIAL_LABEL_MUTATION,
  UPDATE_SPOT_COLOR_MUTATION
} from '@/graphql/mutations';

type MaterialLabelState = {
  labels: MaterialLabelFragment[];
  isLoading: boolean;
  totalCount: number;
};
export const useMaterialLabelStore = defineStore('materialLabelAdmin', {
  state: (): MaterialLabelState => {
    return {
      labels: [],
      isLoading: true, // -- initially set to true until subscription completes the first time
      totalCount: 0
    };
  },
  getters: {
    getLabelById: (state) => {
      return (labelId: string) => state.labels.find((label) => label.id === labelId);
    }
  },
  actions: {
    async subscribeMaterialLabels() {
      const { result, loading, error } = useSubscription(FETCH_MATERIAL_LABELS_SUBSCRIPTION);
      watch(result, (data) => {
        this.labels = data?.printweb_material_label ?? [];
      });

      watch(loading, (loading) => {
        this.isLoading = loading;
        console.log('labels Loading', loading);
      });

      watch(error, (error) => {
        console.error('error', error);
      });
    },
    async createMaterialLabel(data: Printweb_Material_Label_Insert_Input) {
      await apollo.query({
        query: INSERT_MATERIAL_LABEL,
        variables: {
          updateFields: data
        }
      });
    },
    async updateMaterialLabel(data: MaterialLabelFragment) {
      const { id, ...dataWithoutId } = data;
      await apollo.query({
        query: UPDATE_MATERIAL_LABEL_MUTATION,
        variables: {
          id: data.id,
          _set: dataWithoutId
        }
      });
    },
    async deleteMaterialLabel(id: string) {
      await apollo.query({
        query: DELETE_MATERIAL_LABEL_MUTATION,
        variables: {
          id: id
        }
      });
    }
  }
});
