import Compiler from './Compiler';
import PdfHexString from '../pdfPrimitives/PdfHexString';

export default function compile(
  hexString: PdfHexString,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  return Compiler.fromString(hexString.toString());
}
