<template>
  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
    <router-link to="/admin/instance" v-slot="{ isActive }">
      <span :class="isActive ? 'text-black' : 'text-grey'" class="text-xl font-bold"
        >Instances</span
      >
    </router-link>

    <router-link to="/admin/color" v-slot="{ isActive }">
      <span :class="isActive ? 'text-black' : 'text-grey'" class="text-xl font-bold">Colors</span>
    </router-link>

    <router-link to="/admin/material" v-slot="{ isActive }">
      <span :class="isActive ? 'text-black' : 'text-grey'" class="text-xl font-bold"
        >Materials</span
      >
    </router-link>

    <router-link to="/admin/label" v-slot="{ isActive }">
      <span :class="isActive ? 'text-black' : 'text-grey'" class="text-xl font-bold">Labels</span>
    </router-link>

    <router-link to="/admin/group" v-slot="{ isActive }">
      <span :class="isActive ? 'text-black' : 'text-grey'" class="text-xl font-bold">Groups</span>
    </router-link>
  </div>
</template>

<style>
.text-black {
  color: black;
}

.text-grey {
  color: grey;
}

a:-webkit-any-link {
  text-decoration: none;
}
</style>
<script setup lang="ts"></script>
