import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import type Parser from './Parser';
import ReadablePdfStream from '../ReadablePdfStream';
import NumberParser from './NumberParser';
import { PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';

export default function parseIndirectObject(
  document: ReadablePdfStream,
  parser: Parser,
  resolveIndirect: boolean
): PdfPrimitive {
  const pointerBefore = document.pointer;
  const id = NumberParser(document, parser, resolveIndirect);
  const generation = NumberParser(document, parser, resolveIndirect);
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(3);
  if (nextString !== 'obj') {
    document.pointer = pointerBefore;
    throw new NotTheRightPdfElementError(`Expected 'obj' but got '${nextString}'`);
  }
  document.read(charactersRead);
  const element = parser.parse(document, resolveIndirect);
  const [nextString2, charactersRead2] = document.peekStringIgnoringLeadingWhitespace(6);
  if (nextString2 !== 'endobj') {
    throw new Error(`Expected 'endobj' for ${id} ${generation} but got '${nextString2}'`);
  }
  document.read(charactersRead2);
  return element;
}
