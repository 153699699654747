import { defineStore } from 'pinia';

export enum Severity {
  success,
  info,
  danger
}

export const useToastStore = defineStore('toast', {
  state: () => ({
    severity: undefined as Severity | undefined,
    summary: '' as string,
    detail: '' as string
  }),
  actions: {
    errorWithSummaryAndDetail(summary: string, detail: string): void {
      if (summary) this.summary = summary;
      if (detail) this.detail = detail;
      this.error();
    },
    errorWithSummary(summary: string): void {
      if (summary) this.summary = summary;
      this.error();
    },
    error(): void {
      // this.severity = Severity.danger;
    },
    successWithSummaryAndDetail(summary: string, detail: string): void {
      if (summary) this.summary = summary;
      if (detail) this.detail = detail;
      this.success();
    },
    successWithSummary(summary: string) {
      if (summary) this.summary = summary;
      this.success();
    },
    success() {
      this.severity = Severity.success;
    },
    infoWithSummaryAndDetail(summary: string, detail: string): void {
      if (summary) this.summary = summary;
      if (detail) this.detail = detail;
      this.info();
    },
    infoWithSummary(summary: string) {
      if (summary) this.summary = summary;
      this.info();
    },
    info() {
      this.severity = Severity.info;
    }
  }
});
