/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment Instance on printweb_instance {\n    allowed_url\n    created_at\n    updated_at\n    id\n    name\n  }\n": types.InstanceFragmentDoc,
    "\n  fragment SpotColor on printweb_spot_color {\n    id\n    display_name\n    technical_name\n    internal_name\n    fill_allowed\n    do_overprint\n    min_intensity\n    max_intensity\n    stroke_allowed\n    mixing_allowed\n    line_width\n    created_at\n    updated_at\n    overprint_mode\n  }\n": types.SpotColorFragmentDoc,
    "\n  fragment BackgroundMaterial on printweb_background_material {\n    id\n    display_name\n    internal_name\n    label_id\n    created_at\n    updated_at\n    background_material_groups {\n      material_group {\n        id\n        name\n      }\n    }\n  }\n": types.BackgroundMaterialFragmentDoc,
    "\n  fragment MaterialLabel on printweb_material_label {\n    id\n    name\n    created_at\n    updated_at\n  }\n": types.MaterialLabelFragmentDoc,
    "\n  fragment MaterialGroup on printweb_material_group {\n    id\n    name\n    created_at\n    updated_at\n  }\n": types.MaterialGroupFragmentDoc,
    "\n  mutation DeleteInstance($id: uuid = \"\") {\n    delete_printweb_instance_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteInstanceDocument,
    "\n  mutation InsertInstance($objects: [printweb_instance_insert_input!] = []) {\n    insert_printweb_instance(objects: $objects) {\n      returning {\n        id\n      }\n    }\n  }\n": types.InsertInstanceDocument,
    "\n  mutation UpdateInstance(\n    $id: uuid = \"\"\n    $name: String = \"\"\n    $header_logo: bytea = \"\"\n    $allowed_url: String = \"\"\n  ) {\n    update_printweb_instance(\n      where: { id: { _eq: $id } }\n      _set: { allowed_url: $allowed_url, header_logo: $header_logo, name: $name }\n    ) {\n      returning {\n        id\n      }\n    }\n  }\n": types.UpdateInstanceDocument,
    "\n  mutation DeleteInstanceMaterials($instance_id: uuid = \"\") {\n    delete_printweb_instance_background_material(where: { instance_id: { _eq: $instance_id } }) {\n      returning {\n        instance_id\n        background_material_id\n      }\n    }\n  }\n": types.DeleteInstanceMaterialsDocument,
    "\n  mutation DeleteInstanceColors($instance_id: uuid = \"\") {\n    delete_printweb_instance_spot_color(where: { instance_id: { _eq: $instance_id } }) {\n      returning {\n        instance_id\n        spot_color_id\n      }\n    }\n  }\n": types.DeleteInstanceColorsDocument,
    "\n  mutation DeleteSpotColor($id: uuid = \"\") {\n    delete_printweb_spot_color_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteSpotColorDocument,
    "\n  mutation InsertSpotColor($updateFields: printweb_spot_color_insert_input!) {\n    insert_printweb_spot_color_one(object: $updateFields) {\n      id\n    }\n  }\n": types.InsertSpotColorDocument,
    "\n  mutation UpdateSpotColor($id: uuid = \"\", $_set: printweb_spot_color_set_input = {}) {\n    update_printweb_spot_color_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n": types.UpdateSpotColorDocument,
    "\n  mutation DeleteBackgroundMaterial($id: uuid = \"\") {\n    delete_printweb_background_material_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteBackgroundMaterialDocument,
    "\n  mutation InsertBACKGROUND_MATERIAL($updateFields: printweb_background_material_insert_input!) {\n    insert_printweb_background_material_one(object: $updateFields) {\n      id\n    }\n  }\n": types.InsertBackground_MaterialDocument,
    "\n  mutation UpdateMaterialBackground(\n    $id: uuid = \"\"\n    $_set: printweb_background_material_set_input = {}\n  ) {\n    update_printweb_background_material_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n": types.UpdateMaterialBackgroundDocument,
    "\n  mutation InsertInstanceMaterialBackground(\n    $objects: [printweb_instance_background_material_insert_input!] = {}\n  ) {\n    insert_printweb_instance_background_material(objects: $objects) {\n      returning {\n        background_material_id\n        instance_id\n      }\n      affected_rows\n    }\n  }\n": types.InsertInstanceMaterialBackgroundDocument,
    "\n  mutation InsertInstanceSpotColor($objects: [printweb_instance_spot_color_insert_input!] = {}) {\n    insert_printweb_instance_spot_color(objects: $objects) {\n      returning {\n        instance_id\n        spot_color_id\n      }\n      affected_rows\n    }\n  }\n": types.InsertInstanceSpotColorDocument,
    "\n  mutation InsertMaterialLabel($updateFields: printweb_material_label_insert_input!) {\n    insert_printweb_material_label_one(object: $updateFields) {\n      id\n    }\n  }\n": types.InsertMaterialLabelDocument,
    "\n  mutation UpdateMaterialLabel($id: uuid = \"\", $_set: printweb_material_label_set_input = {}) {\n    update_printweb_material_label_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n": types.UpdateMaterialLabelDocument,
    "\n  mutation DeleteMaterialLabel($id: uuid = \"\") {\n    delete_printweb_material_label_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteMaterialLabelDocument,
    "\n  mutation InsertMaterialGroup($updateFields: printweb_material_group_insert_input!) {\n    insert_printweb_material_group_one(object: $updateFields) {\n      id\n    }\n  }\n": types.InsertMaterialGroupDocument,
    "\n  mutation UpdateMaterialGroup($id: uuid = \"\", $_set: printweb_material_group_set_input = {}) {\n    update_printweb_material_group_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n": types.UpdateMaterialGroupDocument,
    "\n  mutation DeleteMaterialGroup($id: uuid = \"\") {\n    delete_printweb_material_group_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteMaterialGroupDocument,
    "\n  mutation DeleteBackgroundMaterialGroups($materialId: uuid = \"\") {\n    delete_printweb_background_material_group(\n      where: { background_material_id: { _eq: $materialId } }\n    ) {\n      returning {\n        background_material_id\n        material_group_id\n      }\n    }\n  }\n": types.DeleteBackgroundMaterialGroupsDocument,
    "\n  mutation InsertBackgroundMaterialGroups(\n    $objects: [printweb_background_material_group_insert_input!] = {}\n  ) {\n    insert_printweb_background_material_group(objects: $objects) {\n      returning {\n        background_material_id\n        material_group_id\n      }\n    }\n  }\n": types.InsertBackgroundMaterialGroupsDocument,
    "\n  mutation DeleteCombinableColors($spotColorId: uuid = \"\") {\n    delete_printweb_combinable_spot_colors(where: { spot_color_id: { _eq: $spotColorId } }) {\n      returning {\n        combinable_spot_color_id\n        spot_color_id\n      }\n    }\n  }\n": types.DeleteCombinableColorsDocument,
    "\n  mutation InsertCombinableColors($objects: [printweb_combinable_spot_colors_insert_input!] = {}) {\n    insert_printweb_combinable_spot_colors(objects: $objects) {\n      returning {\n        combinable_spot_color_id\n        spot_color_id\n      }\n      affected_rows\n    }\n  }\n": types.InsertCombinableColorsDocument,
    "\n  query InstanceById($id: uuid = \"\") {\n    printweb_instance(where: { id: { _eq: $id } }, limit: 1) {\n      ...Instance\n      background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n      spot_colors {\n        spot_color {\n          ...SpotColor\n          combinable_colors {\n            spot_color {\n              ...SpotColor\n            }\n          }\n        }\n      }\n    }\n  }\n": types.InstanceByIdDocument,
    "\n  query InstanceImageById($instanceIds: [uuid!] = \"\") {\n    printweb_instance(where: { id: { _in: $instanceIds } }) {\n      id\n      header_logo\n    }\n  }\n": types.InstanceImageByIdDocument,
    "\n  query ColorImageById($ids: [uuid!] = \"\") {\n    printweb_spot_color(where: { id: { _in: $ids } }) {\n      id\n      image\n    }\n  }\n": types.ColorImageByIdDocument,
    "\n  query MaterialImageById($ids: [uuid!] = \"\") {\n    printweb_background_material(where: { id: { _in: $ids } }) {\n      id\n      image\n    }\n  }\n": types.MaterialImageByIdDocument,
    "\n  subscription fetchInstances {\n    printweb_instance(order_by: { created_at: asc }) {\n      ...Instance\n      background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n      spot_colors {\n        spot_color {\n          ...SpotColor\n        }\n      }\n    }\n  }\n": types.FetchInstancesDocument,
    "\n  subscription fetchSpotColors {\n    printweb_spot_color(order_by: { created_at: asc }) {\n      ...SpotColor\n      combinable_colors {\n        spot_color {\n          ...SpotColor\n        }\n      }\n    }\n  }\n": types.FetchSpotColorsDocument,
    "\n  subscription fetchBackgroundMaterials {\n    printweb_background_material(\n      order_by: [{ material_label: { name: asc } }, { created_at: asc }]\n    ) {\n      ...BackgroundMaterial\n      material_label {\n        id\n        name\n      }\n      background_material_groups {\n        material_group {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.FetchBackgroundMaterialsDocument,
    "\n  subscription subscribeMaterialLabels {\n    printweb_material_label(order_by: { created_at: asc }) {\n      ...MaterialLabel\n      background_materials {\n        ...BackgroundMaterial\n      }\n    }\n  }\n": types.SubscribeMaterialLabelsDocument,
    "\n  subscription subscribeMaterialGroups {\n    printweb_material_group(order_by: { created_at: asc }) {\n      ...MaterialGroup\n    }\n  }\n": types.SubscribeMaterialGroupsDocument,
    "\n  subscription subscribeMaterialGroupsAndMaterials {\n    printweb_material_group(order_by: { created_at: asc }) {\n      ...MaterialGroup\n      material_group_background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n    }\n  }\n": types.SubscribeMaterialGroupsAndMaterialsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Instance on printweb_instance {\n    allowed_url\n    created_at\n    updated_at\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment Instance on printweb_instance {\n    allowed_url\n    created_at\n    updated_at\n    id\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SpotColor on printweb_spot_color {\n    id\n    display_name\n    technical_name\n    internal_name\n    fill_allowed\n    do_overprint\n    min_intensity\n    max_intensity\n    stroke_allowed\n    mixing_allowed\n    line_width\n    created_at\n    updated_at\n    overprint_mode\n  }\n"): (typeof documents)["\n  fragment SpotColor on printweb_spot_color {\n    id\n    display_name\n    technical_name\n    internal_name\n    fill_allowed\n    do_overprint\n    min_intensity\n    max_intensity\n    stroke_allowed\n    mixing_allowed\n    line_width\n    created_at\n    updated_at\n    overprint_mode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BackgroundMaterial on printweb_background_material {\n    id\n    display_name\n    internal_name\n    label_id\n    created_at\n    updated_at\n    background_material_groups {\n      material_group {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BackgroundMaterial on printweb_background_material {\n    id\n    display_name\n    internal_name\n    label_id\n    created_at\n    updated_at\n    background_material_groups {\n      material_group {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MaterialLabel on printweb_material_label {\n    id\n    name\n    created_at\n    updated_at\n  }\n"): (typeof documents)["\n  fragment MaterialLabel on printweb_material_label {\n    id\n    name\n    created_at\n    updated_at\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MaterialGroup on printweb_material_group {\n    id\n    name\n    created_at\n    updated_at\n  }\n"): (typeof documents)["\n  fragment MaterialGroup on printweb_material_group {\n    id\n    name\n    created_at\n    updated_at\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteInstance($id: uuid = \"\") {\n    delete_printweb_instance_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteInstance($id: uuid = \"\") {\n    delete_printweb_instance_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertInstance($objects: [printweb_instance_insert_input!] = []) {\n    insert_printweb_instance(objects: $objects) {\n      returning {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InsertInstance($objects: [printweb_instance_insert_input!] = []) {\n    insert_printweb_instance(objects: $objects) {\n      returning {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateInstance(\n    $id: uuid = \"\"\n    $name: String = \"\"\n    $header_logo: bytea = \"\"\n    $allowed_url: String = \"\"\n  ) {\n    update_printweb_instance(\n      where: { id: { _eq: $id } }\n      _set: { allowed_url: $allowed_url, header_logo: $header_logo, name: $name }\n    ) {\n      returning {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateInstance(\n    $id: uuid = \"\"\n    $name: String = \"\"\n    $header_logo: bytea = \"\"\n    $allowed_url: String = \"\"\n  ) {\n    update_printweb_instance(\n      where: { id: { _eq: $id } }\n      _set: { allowed_url: $allowed_url, header_logo: $header_logo, name: $name }\n    ) {\n      returning {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteInstanceMaterials($instance_id: uuid = \"\") {\n    delete_printweb_instance_background_material(where: { instance_id: { _eq: $instance_id } }) {\n      returning {\n        instance_id\n        background_material_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteInstanceMaterials($instance_id: uuid = \"\") {\n    delete_printweb_instance_background_material(where: { instance_id: { _eq: $instance_id } }) {\n      returning {\n        instance_id\n        background_material_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteInstanceColors($instance_id: uuid = \"\") {\n    delete_printweb_instance_spot_color(where: { instance_id: { _eq: $instance_id } }) {\n      returning {\n        instance_id\n        spot_color_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteInstanceColors($instance_id: uuid = \"\") {\n    delete_printweb_instance_spot_color(where: { instance_id: { _eq: $instance_id } }) {\n      returning {\n        instance_id\n        spot_color_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSpotColor($id: uuid = \"\") {\n    delete_printweb_spot_color_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSpotColor($id: uuid = \"\") {\n    delete_printweb_spot_color_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertSpotColor($updateFields: printweb_spot_color_insert_input!) {\n    insert_printweb_spot_color_one(object: $updateFields) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertSpotColor($updateFields: printweb_spot_color_insert_input!) {\n    insert_printweb_spot_color_one(object: $updateFields) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSpotColor($id: uuid = \"\", $_set: printweb_spot_color_set_input = {}) {\n    update_printweb_spot_color_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSpotColor($id: uuid = \"\", $_set: printweb_spot_color_set_input = {}) {\n    update_printweb_spot_color_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteBackgroundMaterial($id: uuid = \"\") {\n    delete_printweb_background_material_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteBackgroundMaterial($id: uuid = \"\") {\n    delete_printweb_background_material_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertBACKGROUND_MATERIAL($updateFields: printweb_background_material_insert_input!) {\n    insert_printweb_background_material_one(object: $updateFields) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertBACKGROUND_MATERIAL($updateFields: printweb_background_material_insert_input!) {\n    insert_printweb_background_material_one(object: $updateFields) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterialBackground(\n    $id: uuid = \"\"\n    $_set: printweb_background_material_set_input = {}\n  ) {\n    update_printweb_background_material_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterialBackground(\n    $id: uuid = \"\"\n    $_set: printweb_background_material_set_input = {}\n  ) {\n    update_printweb_background_material_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertInstanceMaterialBackground(\n    $objects: [printweb_instance_background_material_insert_input!] = {}\n  ) {\n    insert_printweb_instance_background_material(objects: $objects) {\n      returning {\n        background_material_id\n        instance_id\n      }\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation InsertInstanceMaterialBackground(\n    $objects: [printweb_instance_background_material_insert_input!] = {}\n  ) {\n    insert_printweb_instance_background_material(objects: $objects) {\n      returning {\n        background_material_id\n        instance_id\n      }\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertInstanceSpotColor($objects: [printweb_instance_spot_color_insert_input!] = {}) {\n    insert_printweb_instance_spot_color(objects: $objects) {\n      returning {\n        instance_id\n        spot_color_id\n      }\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation InsertInstanceSpotColor($objects: [printweb_instance_spot_color_insert_input!] = {}) {\n    insert_printweb_instance_spot_color(objects: $objects) {\n      returning {\n        instance_id\n        spot_color_id\n      }\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertMaterialLabel($updateFields: printweb_material_label_insert_input!) {\n    insert_printweb_material_label_one(object: $updateFields) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertMaterialLabel($updateFields: printweb_material_label_insert_input!) {\n    insert_printweb_material_label_one(object: $updateFields) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterialLabel($id: uuid = \"\", $_set: printweb_material_label_set_input = {}) {\n    update_printweb_material_label_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterialLabel($id: uuid = \"\", $_set: printweb_material_label_set_input = {}) {\n    update_printweb_material_label_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMaterialLabel($id: uuid = \"\") {\n    delete_printweb_material_label_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMaterialLabel($id: uuid = \"\") {\n    delete_printweb_material_label_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertMaterialGroup($updateFields: printweb_material_group_insert_input!) {\n    insert_printweb_material_group_one(object: $updateFields) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertMaterialGroup($updateFields: printweb_material_group_insert_input!) {\n    insert_printweb_material_group_one(object: $updateFields) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMaterialGroup($id: uuid = \"\", $_set: printweb_material_group_set_input = {}) {\n    update_printweb_material_group_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMaterialGroup($id: uuid = \"\", $_set: printweb_material_group_set_input = {}) {\n    update_printweb_material_group_by_pk(pk_columns: { id: $id }, _set: $_set) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMaterialGroup($id: uuid = \"\") {\n    delete_printweb_material_group_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMaterialGroup($id: uuid = \"\") {\n    delete_printweb_material_group_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteBackgroundMaterialGroups($materialId: uuid = \"\") {\n    delete_printweb_background_material_group(\n      where: { background_material_id: { _eq: $materialId } }\n    ) {\n      returning {\n        background_material_id\n        material_group_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteBackgroundMaterialGroups($materialId: uuid = \"\") {\n    delete_printweb_background_material_group(\n      where: { background_material_id: { _eq: $materialId } }\n    ) {\n      returning {\n        background_material_id\n        material_group_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertBackgroundMaterialGroups(\n    $objects: [printweb_background_material_group_insert_input!] = {}\n  ) {\n    insert_printweb_background_material_group(objects: $objects) {\n      returning {\n        background_material_id\n        material_group_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InsertBackgroundMaterialGroups(\n    $objects: [printweb_background_material_group_insert_input!] = {}\n  ) {\n    insert_printweb_background_material_group(objects: $objects) {\n      returning {\n        background_material_id\n        material_group_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCombinableColors($spotColorId: uuid = \"\") {\n    delete_printweb_combinable_spot_colors(where: { spot_color_id: { _eq: $spotColorId } }) {\n      returning {\n        combinable_spot_color_id\n        spot_color_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCombinableColors($spotColorId: uuid = \"\") {\n    delete_printweb_combinable_spot_colors(where: { spot_color_id: { _eq: $spotColorId } }) {\n      returning {\n        combinable_spot_color_id\n        spot_color_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertCombinableColors($objects: [printweb_combinable_spot_colors_insert_input!] = {}) {\n    insert_printweb_combinable_spot_colors(objects: $objects) {\n      returning {\n        combinable_spot_color_id\n        spot_color_id\n      }\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation InsertCombinableColors($objects: [printweb_combinable_spot_colors_insert_input!] = {}) {\n    insert_printweb_combinable_spot_colors(objects: $objects) {\n      returning {\n        combinable_spot_color_id\n        spot_color_id\n      }\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InstanceById($id: uuid = \"\") {\n    printweb_instance(where: { id: { _eq: $id } }, limit: 1) {\n      ...Instance\n      background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n      spot_colors {\n        spot_color {\n          ...SpotColor\n          combinable_colors {\n            spot_color {\n              ...SpotColor\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query InstanceById($id: uuid = \"\") {\n    printweb_instance(where: { id: { _eq: $id } }, limit: 1) {\n      ...Instance\n      background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n      spot_colors {\n        spot_color {\n          ...SpotColor\n          combinable_colors {\n            spot_color {\n              ...SpotColor\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InstanceImageById($instanceIds: [uuid!] = \"\") {\n    printweb_instance(where: { id: { _in: $instanceIds } }) {\n      id\n      header_logo\n    }\n  }\n"): (typeof documents)["\n  query InstanceImageById($instanceIds: [uuid!] = \"\") {\n    printweb_instance(where: { id: { _in: $instanceIds } }) {\n      id\n      header_logo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ColorImageById($ids: [uuid!] = \"\") {\n    printweb_spot_color(where: { id: { _in: $ids } }) {\n      id\n      image\n    }\n  }\n"): (typeof documents)["\n  query ColorImageById($ids: [uuid!] = \"\") {\n    printweb_spot_color(where: { id: { _in: $ids } }) {\n      id\n      image\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MaterialImageById($ids: [uuid!] = \"\") {\n    printweb_background_material(where: { id: { _in: $ids } }) {\n      id\n      image\n    }\n  }\n"): (typeof documents)["\n  query MaterialImageById($ids: [uuid!] = \"\") {\n    printweb_background_material(where: { id: { _in: $ids } }) {\n      id\n      image\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription fetchInstances {\n    printweb_instance(order_by: { created_at: asc }) {\n      ...Instance\n      background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n      spot_colors {\n        spot_color {\n          ...SpotColor\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription fetchInstances {\n    printweb_instance(order_by: { created_at: asc }) {\n      ...Instance\n      background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n      spot_colors {\n        spot_color {\n          ...SpotColor\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription fetchSpotColors {\n    printweb_spot_color(order_by: { created_at: asc }) {\n      ...SpotColor\n      combinable_colors {\n        spot_color {\n          ...SpotColor\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription fetchSpotColors {\n    printweb_spot_color(order_by: { created_at: asc }) {\n      ...SpotColor\n      combinable_colors {\n        spot_color {\n          ...SpotColor\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription fetchBackgroundMaterials {\n    printweb_background_material(\n      order_by: [{ material_label: { name: asc } }, { created_at: asc }]\n    ) {\n      ...BackgroundMaterial\n      material_label {\n        id\n        name\n      }\n      background_material_groups {\n        material_group {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription fetchBackgroundMaterials {\n    printweb_background_material(\n      order_by: [{ material_label: { name: asc } }, { created_at: asc }]\n    ) {\n      ...BackgroundMaterial\n      material_label {\n        id\n        name\n      }\n      background_material_groups {\n        material_group {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription subscribeMaterialLabels {\n    printweb_material_label(order_by: { created_at: asc }) {\n      ...MaterialLabel\n      background_materials {\n        ...BackgroundMaterial\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription subscribeMaterialLabels {\n    printweb_material_label(order_by: { created_at: asc }) {\n      ...MaterialLabel\n      background_materials {\n        ...BackgroundMaterial\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription subscribeMaterialGroups {\n    printweb_material_group(order_by: { created_at: asc }) {\n      ...MaterialGroup\n    }\n  }\n"): (typeof documents)["\n  subscription subscribeMaterialGroups {\n    printweb_material_group(order_by: { created_at: asc }) {\n      ...MaterialGroup\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription subscribeMaterialGroupsAndMaterials {\n    printweb_material_group(order_by: { created_at: asc }) {\n      ...MaterialGroup\n      material_group_background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription subscribeMaterialGroupsAndMaterials {\n    printweb_material_group(order_by: { created_at: asc }) {\n      ...MaterialGroup\n      material_group_background_materials {\n        background_material {\n          ...BackgroundMaterial\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;