import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfHexString from '../pdfPrimitives/PdfHexString';
import type Parser from './Parser';

export default function parseHexString(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfHexString {
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(1);
  if (nextString !== '<') {
    throw new NotTheRightPdfElementError(`Expected '<' but got '${nextString}'`);
  }
  // make sure it is not a dict
  if (document.peekStringIgnoringLeadingWhitespace(2)[0] === '<<') {
    throw new NotTheRightPdfElementError("'<' was part of dict");
  }
  document.read(charactersRead); // <
  const string = document.readStringUntilPdfHexStringEnd();
  document.read(1); // >
  return new PdfHexString(string);
}
