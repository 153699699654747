import PdfArray from '../pdfPrimitives/PdfArray';
import { PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import Compiler from './Compiler';

export default function compile(
  array: PdfArray<PdfPrimitive>,
  compiler: Compiler,
  makeAllEntriesIndirect: boolean = false,
  ...compilationArgs: any[]
): Uint8Array {
  const elements: Uint8Array[] = [];
  for (const element of array) {
    if (makeAllEntriesIndirect) {
      const reference = compiler.addIndirectObject(element, ...compilationArgs);
      elements.push(compiler.compilePrimitive(reference));
    } else {
      elements.push(compiler.compilePrimitive(element, ...compilationArgs));
    }
  }
  const res = new Uint8Array(
    elements.reduce((acc, element) => acc + element.length, 2 + elements.length)
  );
  res[0] = '['.charCodeAt(0);
  res[res.length - 1] = ']'.charCodeAt(0);
  let pointer = 1;
  for (const element of elements) {
    res.set(element, pointer);
    pointer += element.length;
    res.set([0x20], pointer); // space
    pointer++;
  }
  return res;
}
