import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { BackgroundMaterialFragment } from '@/gql_gen/graphql';
import { getBase64Image } from '@/helpers/formHelper';
import { apollo } from '@/graphql/apollo';
import { MATERIAL_IMAGE_BY_ID } from '@/graphql/queries';

export type BackgroundMaterialWithImageData = BackgroundMaterialFragment & {
  src: string;
};

export const useBackgroundMaterialStore = defineStore('backgroundMaterial', () => {
  const materials = ref<BackgroundMaterialWithImageData[]>([]);

  const getMaterialById = (id: string) => {
    const index = materials.value.findIndex((color) => color.id === id);
    return materials.value[index];
  };

  const loadTextureFromDb = async (id: string) => {
    const result = await apollo.query({
      query: MATERIAL_IMAGE_BY_ID,
      variables: {
        ids: [id]
      }
    });
    return result.data.printweb_background_material[0].image;
  };

  const init = async (materialFragments: BackgroundMaterialFragment[]) => {
    materials.value = await Promise.all(
      materialFragments.map(async (material) => {
        return {
          ...material,
          src: getBase64Image(await loadTextureFromDb(material.id))
        };
      })
    );
  };

  return { materials, init, getMaterialById };
});
