<template>
  <InputNumber
    v-if="props.min != props.max"
    :modelValue="props.modelValue"
    :input-id="props.inputId"
    @click="(e: Event) => overlayPanel?.show(e)"
    @update:modelValue="onUpdateModelValue"
    input-class="input-number"
  />
  <span v-else v-tooltip.left="'This color has a fixed intensity.'">{{ props.max }}</span>
  <OverlayPanel ref="overlayPanel">
    <Slider
      class="slider"
      :modelValue="props.modelValue"
      :min="props.min"
      :max="props.max + 1"
      @update:modelValue="onUpdateModelValue"
    />
  </OverlayPanel>
</template>
<script lang="ts" setup>
import InputNumber from 'primevue/inputnumber';
import OverlayPanel from 'primevue/overlaypanel';
import Slider from 'primevue/slider';
import { ref } from 'vue';

const props = defineProps<{
  modelValue: number;
  min: number;
  max: number;
  inputId: string;
}>();
const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void;
}>();

const overlayPanel = ref<OverlayPanel | null>(null);

const onUpdateModelValue = (value: number) => {
  if (value < props.min) value = props.min;
  if (value > props.max) value = 100;
  emit('update:modelValue', value);
};
</script>
<style scoped lang="scss">
.slider {
  width: 200px;
}

:deep(.input-number) {
  padding-inline: 0;
  width: 2.5em;
  padding-block: 0.2em;
  text-align: center;
}
</style>
