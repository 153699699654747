import { PdfArray, PdfHexString, PdfNumber, PdfString } from '../..';
import type { ContentStream } from '../contentStream/ContentStream';
import PdfName from './PdfName';
import { type PdfPrimitive } from './PdfPrimitive';
import PdfStream from './PdfStream';

export default class PdfDict extends Map<string, PdfPrimitive> {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS: string[] = [];

  constructor(map: Map<PdfName | string, PdfPrimitive> | object | null = null) {
    super();
    if (!map) return;
    if (map instanceof Map)
      for (const [key, value] of map.entries()) {
        this.set(key instanceof PdfName ? key.valueOf() : key, value);
      }
    else {
      for (const [key, value] of Object.entries(map)) {
        this.set(key, value as PdfPrimitive);
      }
    }
  }

  toString(): string {
    return `<<${Array.from(this.entries())
      .map(([key, value]) => `/${key} ${value}`)
      .join('\n')}>>`;
  }
}

export class PdfCatalog extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = ['Pages', 'Metadata'];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('Catalog'));
  }
}

export class PdfPage extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = ['Parent', 'Resources', 'Contents'];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('Page'));

    this.delete('PieceInfo');
    this.delete('Annots');
  }

  setContentStream(contentStream: PdfStream) {
    this.set('Contents', contentStream);
  }
}

export class PdfPages extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = ['Kids', 'Parent'];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('Pages'));
  }
}

export class PdfExtGState extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = [];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('ExtGState'));
  }
}

export class PdfFont extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = ['ToUnicode', 'FontDescriptor'];

  cidToUnicodeMapping: Map<string, string> | null = null;
  longestCidLength: number = 0;

  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('Font'));
    if (this.has('ToUnicode')) {
      this.cidToUnicodeMapping = new Map();
      const cmap = (this.get('ToUnicode') as PdfStream).getDecodedValue();
      // Parse bfchar entries
      const bfcharPattern = /<(.+?)> *<(.+?)>/g;
      let bfcharMatch;
      while ((bfcharMatch = bfcharPattern.exec(cmap)) !== null) {
        const cid = parseInt(bfcharMatch[1], 16).toString(16).padStart(bfcharMatch[1].length, '0');
        const unicode = String.fromCharCode(parseInt(bfcharMatch[2], 16));
        this.cidToUnicodeMapping.set(cid, unicode);
        this.longestCidLength = Math.max(this.longestCidLength, cid.length);
      }

      // Parse bfrange entries
      const bfrangePattern = /<(.+?)> *<(.+?)> *<(.+?)>/g;
      let bfrangeMatch;
      while ((bfrangeMatch = bfrangePattern.exec(cmap)) !== null) {
        const startRange = parseInt(bfrangeMatch[1], 16);
        const endRange = parseInt(bfrangeMatch[2], 16);
        const unicodeStart = parseInt(bfrangeMatch[3], 16);

        for (let i = startRange; i <= endRange; i++) {
          const cid = i.toString(16).padStart(bfrangeMatch[1].length, '0');
          const unicode = String.fromCharCode(unicodeStart + (i - startRange));
          this.cidToUnicodeMapping.set(cid, unicode);
          this.longestCidLength = Math.max(this.longestCidLength, cid.length);
        }
      }
    }
  }

  translateTextWithToUnicode(textBytes: Uint8Array): string {
    if (!this.cidToUnicodeMapping) {
      throw new Error('Font has no ToUnicode mapping');
    }
    let translatedText = '';
    let i = 0;
    while (i < textBytes.length) {
      // read 1 byte from text, check if it is a valid cid, if not read 2 bytes, check, if not 3, ...
      let cid = textBytes[i++];
      let hexLength = 2;
      let cidHex = cid.toString(16).padStart(hexLength, '0');
      while (
        !this.cidToUnicodeMapping?.has(cidHex) &&
        i < textBytes.length &&
        cidHex.length < this.longestCidLength
      ) {
        hexLength += 2;
        cid = cid * 256 + textBytes[i++];
        cidHex = cid.toString(16).padStart(hexLength, '0');
      }
      if (this.cidToUnicodeMapping.has(cidHex)) {
        translatedText += this.cidToUnicodeMapping.get(cidHex) as string;
      } else {
        translatedText += cidHex
          .match(/.{1,2}/g)
          ?.map((h) => String.fromCharCode(parseInt(h, 16)))
          .join('');
      }
    }

    return translatedText;
  }

  translateTextWithEncoding(textBytes: Uint8Array): string {
    if (!this.has('Encoding')) {
      throw new Error('Font has no Encoding');
    }
    const encoding = this.get('Encoding') as PdfEncoding;
    return textBytes.reduce((acc, cur) => acc + encoding.translateCidToUnicode(cur), '');
  }

  translateText(textBytes: Uint8Array): string {
    if (this.has('ToUnicode')) {
      return this.translateTextWithToUnicode(textBytes);
    } else if (this.has('Encoding') && this.get('Encoding') instanceof PdfEncoding) {
      return this.translateTextWithEncoding(textBytes);
    } else {
      return textBytes.reduce((acc, cur) => acc + String.fromCharCode(cur), '');
    }
  }

  turnTextIntoBytes(text: PdfString | PdfHexString): Uint8Array {
    if (text instanceof PdfHexString) {
      let hex = text.valueOf();
      if (hex.length % 2 === 1) {
        hex = '0' + hex;
      }
      const textBytes = new Uint8Array(hex.length / 2);
      for (let i = 0; i < hex.length; i += 2) {
        textBytes[i / 2] = parseInt(hex.substr(i, 2), 16);
      }
      return textBytes;
    }

    if (text instanceof PdfString) {
      const textBytes = new Uint8Array(text.valueOf().length);
      for (let i = 0; i < text.valueOf().length; i++) {
        textBytes[i] = text.valueOf().charCodeAt(i);
      }
      return textBytes;
    }

    throw new Error(`Expected PdfString or PdfHexString but got ${(text as any).constructor.name}`);
  }

  translatePdfText(text: PdfString | PdfHexString): string {
    return this.translateText(this.turnTextIntoBytes(text));
  }

  splitIntoCharacters(textBytes: Uint8Array): Uint8Array[] {
    // splits text into multiple characters, based on CID, if available, if not based on .split('')
    if (!this.has('ToUnicode')) {
      return Array.from(textBytes).map((byte) => new Uint8Array([byte]));
    }
    const characters: Uint8Array[] = [];
    let i = 0;
    while (i < textBytes.length) {
      // read 1 byte from text, check if it is a valid cid, if not read 2 bytes, check, if not 3, ...
      let cid = textBytes[i++];
      let hexLength = 2;
      let cidHex = cid.toString(16).padStart(hexLength, '0');
      while (
        !this.cidToUnicodeMapping!.has(cidHex) &&
        i < textBytes.length &&
        cidHex.length < this.longestCidLength
      ) {
        hexLength += 2;
        cid = cid * 256 + textBytes[i++];
        cidHex = cid.toString(16).padStart(hexLength, '0');
      }
      if (this.cidToUnicodeMapping!.has(cidHex)) {
        characters.push(new Uint8Array([cid]));
      } else {
        console.warn(`Could not find character for cid ${cidHex}`);
        // throw new Error(`Could not find character for cid ${cidHex}`);
      }
    }
    return characters;
  }
}

export class PdfXObject extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = [];

  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('XObject'));
  }
}

export class PdfForm extends PdfXObject {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = [];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('XObject'));
    this.set('Subtype', new PdfName('Form'));
  }
}

export class PdfImage extends PdfXObject {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = [];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('XObject'));
    this.set('Subtype', new PdfName('Image'));
  }
}

export class PdfStructElem extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = ['P'];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('StructElem'));
  }
}

export class PdfEncoding extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = [];
  constructor(...args: any[]) {
    super(...args);
    this.set('Type', new PdfName('Encoding'));

    if (this.has('Differences')) {
      const differences = this.get('Differences') as PdfArray<PdfName | PdfNumber>;
      let unicode = 0;
      for (const difference of differences) {
        if (difference instanceof PdfNumber) {
          unicode = difference.valueOf();
        } else if (difference instanceof PdfName) {
          this.differencesToUnicodeMap.set(unicode, difference.valueOf());
          unicode++;
        }
      }
    }
  }

  differencesToUnicodeMap: Map<number, string> = new Map();

  translateCidToUnicode(cid: number): string {
    if (this.differencesToUnicodeMap.has(cid)) {
      return this.differencesToUnicodeMap.get(cid) as string;
    }
    return String.fromCharCode(cid);
  }
}

export class PdfFunction extends PdfDict {
  KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS = [];

  // all functions
  domain: number[];
  range: number[] | undefined;

  // type 2 functions
  C0: number[] | undefined;
  C1: number[] | undefined;
  N: number | undefined;

  constructor(...args: any[]) {
    super(...args);
    // all functions
    this.domain = (this.get('Domain') as PdfArray<PdfNumber>).map((n) => n.valueOf());

    if ((this.get('FunctionType') as PdfNumber).valueOf() !== 2) {
      console.warn('Only type 2 functions are supported');
      return;
    }

    // type 2 functions
    if ((this.get('FunctionType') as PdfNumber).valueOf() === 2) {
      this.range = (this.get('Range') as PdfArray<PdfNumber>)?.map((n) => n.valueOf());
      this.C0 = (this.get('C0') as PdfArray<PdfNumber>)?.map((n) => n.valueOf()) || [0.0];
      this.C1 = (this.get('C1') as PdfArray<PdfNumber>)?.map((n) => n.valueOf()) || [1.0];
      this.N = (this.get('N') as PdfNumber).valueOf();
    }
  }

  calculate(...inputs: number[]): number[] {
    if (inputs.length !== this.domain.length / 2) {
      throw new Error(
        `Wrong number of inputs. Expected ${this.domain.length / 2}, got ${inputs.length}`
      );
    }

    let results;
    if ((this.get('FunctionType') as PdfNumber).valueOf() === 2) {
      results = this.calculateType2(...inputs);
    } else {
      throw new Error(
        `Unsupported function type: ${(this.get('FunctionType') as PdfNumber).valueOf()}`
      );
    }

    if (this.range && results.length !== this.range.length / 2) {
      throw new Error(
        `Wrong number of outputs. Expected ${this.range.length / 2}, got ${results.length}`
      );
    }
    if (this.range) {
      results = results.map((result, index) => {
        const lowerBound = this.range![index * 2];
        const upperBound = this.range![index * 2 + 1];
        return Math.min(Math.max(result, lowerBound), upperBound);
      });
    }
    return results;
  }

  calculateType2(...inputs: number[]): number[] {
    return inputs.flatMap((input) => {
      const results: number[] = [];
      for (let j = 0; j < this.C0!.length; j++) {
        results.push(this.C0![j] + input ** this.N! * (this.C1![j] - this.C0![j]));
      }
      return results;
    });
  }

  static newType2(domain: number[], range: number[], c0: number[], c1: number[], n: number) {
    return new PdfFunction({
      FunctionType: new PdfNumber(2),
      Domain: new PdfArray(domain.map((d) => new PdfNumber(d))),
      Range: new PdfArray(range.map((d) => new PdfNumber(d))),
      C0: new PdfArray(c0.map((d) => new PdfNumber(d))),
      C1: new PdfArray(c1.map((d) => new PdfNumber(d))),
      N: new PdfNumber(n)
    });
  }
}
