import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import type Parser from './Parser';
import ReadablePdfStream from '../ReadablePdfStream';
import PdfNumber from '../pdfPrimitives/PdfNumber';
import NumberParser from './NumberParser';

const isNumeric = (str: string) => {
  if (str === '.' || str === '-') return true;
  return !isNaN(str as any) && !isNaN(parseFloat(str));
};

export default function parseNumber(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfNumber {
  const pointer = document.pointer;

  const number = NumberParser(document, parser, resolveIndirect);

  // check if number is part of reference
  // try to read a second number, then check for R
  try {
    const [number2, charactersRead2] = document.peekStringUntilWhitespaceOrOtherDelimiter();
    const [nextString, charactersRead3] = document.peekStringIgnoringLeadingWhitespaceWithOffset(
      3,
      charactersRead2
    );
    if (!(isNumeric(number2) && (nextString[0] === 'R' || nextString === 'obj'))) {
      throw new Error('Number is not part of reference');
    }
  } catch (e) {
    return number;
  }
  document.pointer = pointer;
  throw new NotTheRightPdfElementError('Number is part of reference');
}
