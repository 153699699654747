<template>
  <div id="login-form-container">
    <Card
      id="login-form"
      class="text-center flex justify-content-center"
      :pt="{
        subtitle: { class: 'max-w-20rem m-auto' },
        footer: { class: 'pb-5' }
      }"
    >
      <template #title>Login</template>
      <template #content>
        <div class="text-left flex flex-column gap-2 mb-5 max-w-18rem m-auto">
          <!--          <label for="email">Email</label>-->
          <!--          <InputText-->
          <!--            placeholder="Email Address"-->
          <!--            v-model="email"-->
          <!--            aria-describedby="username-help"-->
          <!--            :pt="{-->
          <!--              root: { class: invalidLogIn ? 'border-red-500	' : '' }-->
          <!--            }"-->
          <!--          />-->
        </div>
        <div class="text-left flex flex-column gap-2 mb-1 max-w-18rem m-auto">
          <label for="password">Password</label>
          <Password
            :pt="{
              input: {
                class: 'w-18rem ' + (invalidLogIn ? 'border-red-500' : '')
              }
            }"
            placeholder="Password"
            v-model="password"
            :feedback="false"
            @keydown.enter="login"
            aria-describedby="username-help"
          />
        </div>
      </template>
      <template #footer>
        <p class="text-sm text-red-500" v-if="invalidLogIn">
          {{ errorMessage }}
        </p>
        <Button
          class="w-13rem"
          @click="
            () => {
              login();
            }
          "
          severity="secondary"
          label="Login"
          :loading="loading"
          :disabled="disableLoginIn"
          enter
          space
          >Login</Button
        >
      </template>
    </Card>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';

import Card from 'primevue/card';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { useUserStore } from '@/stores/admin/user';

const password = ref('');
const invalidLogIn = ref(false);
const errorMessage = ref('');
const loading = ref(false);
const email = ref('');
const userStore = useUserStore();

const validateEmail = () => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email.value);
};
const disableLoginIn = computed(() => {
  // return !validateEmail() || !password.value.length;
  return !password.value.length;
});

const login = async () => {
  // if (!validateEmail()) {
  //   invalidLogIn.value = true;
  //   errorMessage.value = 'Please enter a valid email address';
  //   return;
  // }
  if (!password.value.length) {
    invalidLogIn.value = true;
    errorMessage.value = 'Please enter a password';
    return;
  }
  invalidLogIn.value = false;
  errorMessage.value = '';
  loading.value = true;

  try {
    await userStore.login(email.value, password.value);
  } catch (e) {
    console.log(e);
    invalidLogIn.value = true;
    errorMessage.value = 'Invalid email or password';
  } finally {
    loading.value = false;
  }
};
</script>
<style scoped>
/* center the card in the middle of the screen and limit the width of the card to 600px. Use grid */
#login-form-container {
  align-items: center;
  display: grid;
  justify-content: center;
  width: 100%;
}

#login-form {
  width: 400px;
}

:deep(.p-button-danger) {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

:deep(.p-button.p-button-danger:enabled:focus) {
  box-shadow:
    0 0 0 2px #ffffff,
    0 0 0 4px #939393,
    0 1px 2px 0 black;
}
</style>
