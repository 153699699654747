import { ApolloClient, InMemoryCache, HttpLink, split, from } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useUserStore } from '@/stores/admin/user';
import { useRouter } from 'vue-router';

function getHeaders() {
  const userStore = useUserStore();
  const headers: any = {};
  const token = userStore.authToken;
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
}

function getHasuraWsLink(): string {
  // get hostname
  return import.meta.env.VITE_APP_hasura;
}

function getAuthToken() {
  const userStore = useUserStore();

  return userStore.authToken;
}

// Create an http link:
const httpLink = new HttpLink({
  uri: '/v1/graphql', // routing defined in vite.config.ts or firebase.json
  fetch: (uri: RequestInfo, options: RequestInit) => {
    options.headers = getHeaders();
    return fetch(uri, options);
  }
});

const wsUrl: string = getHasuraWsLink();
console.warn('Using hasura ws url: ', wsUrl);
const wsLink = new GraphQLWsLink(
  createClient({
    url: wsUrl,
    lazy: true,
    connectionParams: () => ({
      headers: getHeaders()
    })
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const errorLink = onError((error) => {
  const userStore = useUserStore();
  const router = useRouter();
  console.error('Apollo Error', error);
  logErrorMessages(error);
  if (
    error?.graphQLErrors &&
    error?.graphQLErrors.length &&
    error?.graphQLErrors[0].extensions?.code == 'invalid-jwt'
  ) {
    console.warn('invalid jwt, logging out');
    userStore.logout();
    router?.push({ name: 'login' });
  }
});

// Create the apollo client
export const apollo = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([errorLink, splitLink]),
  connectToDevTools: ['localhost'].includes(window.location.hostname),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true
    }
  }
});
