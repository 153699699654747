import Compiler from './Compiler';
import PdfName from '../pdfPrimitives/PdfName';
import PdfStream from '../pdfPrimitives/PdfStream';
import { PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import PdfDict from '../pdfPrimitives/PdfDict';
import NameCompiler from './NameCompiler';
import PdfIndirectObjectReference from '../pdfPrimitives/PdfIndirectObjectReference';

export default function compile(
  pdfDict: PdfDict,
  compiler: Compiler,
  parentReference?: PdfIndirectObjectReference,
  ...compilationArgs: any[]
): Uint8Array {
  const elements = Array.from(pdfDict.entries()).map(([key, value]) => {
    const keyBytes = compiler.compilePrimitive(new PdfName(key), ...compilationArgs);
    let valueBytes;
    if (pdfDict.KEYS_THAT_SHOULD_BE_INDIRECT_OBJECTS.includes(key) || value instanceof PdfStream) {
      if (key === 'Kids') {
        const reference =
          compiler.currentlyCompilingStack[
            compiler.currentlyCompilingStack.length - 1
          ].toReference();
        valueBytes = compiler.compilePrimitive(value, true, reference, ...compilationArgs);
      } else if (key === 'Parent') {
        valueBytes = compiler.compilePrimitive(parentReference!, ...compilationArgs);
      } else {
        const reference = compiler.addIndirectObject(value as PdfPrimitive);
        valueBytes = compiler.compilePrimitive(reference, ...compilationArgs);
      }
    } else {
      valueBytes = compiler.compilePrimitive(value, ...compilationArgs);
    }
    const res = new Uint8Array(keyBytes.length + valueBytes.length + 1);
    res.set(keyBytes);
    res[keyBytes.length] = ' '.charCodeAt(0);
    res.set(valueBytes, keyBytes.length + 1);
    return res;
  });
  const res = new Uint8Array(
    elements.reduce((acc, element) => acc + element.length, 4 + elements.length)
  );
  res[0] = '<'.charCodeAt(0);
  res[1] = '<'.charCodeAt(0);
  res[res.length - 1] = '>'.charCodeAt(0);
  res[res.length - 2] = '>'.charCodeAt(0);
  let pointer = 2;
  for (const element of elements) {
    res.set(element, pointer);
    pointer += element.length;
    res.set([0x0a], pointer); // newline
    pointer++;
  }
  return res;
}
