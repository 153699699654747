import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import type Parser from './Parser';
import ReadablePdfStream from '../ReadablePdfStream';
import PdfNumber from '../pdfPrimitives/PdfNumber';

const isNumeric = (str: string) => {
  if (str === '.' || str === '-') return true;
  return !isNaN(str as any) && !isNaN(parseFloat(str));
};

export default function parseNumber(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfNumber {
  const [number, charactersRead] = document.peekStringUntilWhitespaceOrOtherDelimiter();
  if (!isNumeric(number)) {
    throw new NotTheRightPdfElementError(`Expected number but got '${number}'`);
  }
  document.read(charactersRead);
  return new PdfNumber(parseFloat(number));
}
