import PdfDict from './PdfDict';

export default class PdfStream {
  constructor(
    public dict: PdfDict,
    public value: Uint8Array
  ) {}

  getDecodedValue(): string {
    const decoded = this.value
      .reduce((acc, cur) => acc + String.fromCharCode(cur), '')
      .replace(/\\r/g, '\r')
      .replace(/\\\(/g, '(');
    return decoded;
  }

  toString(): string {
    return `${this.dict.toString()}\nstream\n${this.value}\nendstream`;
  }
}
