<template>
  <div>
    <!-- <Toast /> -->
    <DynamicDialog />
    <div
      id="application-container"
      :class="{
        'application-container-blurred': showLoadingAnimation || (idError && routeRequiresValidUuid)
      }"
    >
      <ApplicationHeader />
      <GlobalLoader v-show="showLoadingAnimation" />
      <router-view></router-view>
    </div>
    <div class="id-error" v-if="idError && routeRequiresValidUuid">
      {{ idError }}
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationHeader from './components/ApplicationHeader.vue';
import { computed, provide, ref, watch } from 'vue';
import GlobalLoader from '@/components/GlobalLoader.vue';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css';
import { useToast } from 'primevue/usetoast';
import { Severity, useToastStore } from '@/stores/toast';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { apollo } from './graphql/apollo';
import DynamicDialog from 'primevue/dynamicdialog';
import { useBackgroundMaterialStore } from './stores/instance/backgroundMaterial';
import { useSpotColorStore } from './stores/instance/spotColor';
import { QUERY_INSTANCE_BY_ID } from './graphql/queries';
import { useInstanceStore } from './stores/instance/instance';

const toast = useToast();
const toastStore = useToastStore();
const { severity, summary, detail } = storeToRefs(toastStore);
watch(severity, (value) => {
  if (severity.value != null)
    toast.add({
      severity: Severity[severity.value] as 'success' | 'info' | 'warn' | 'error' | undefined,
      summary: summary.value,
      detail: detail.value,
      life: 4000
    });
});

const route = useRoute();

const loadingScreenRequested = ref<Boolean>(false);
const areColorsInitialized = ref<Boolean>(false);
const areMaterialsInitialized = ref<Boolean>(false);
const showLoadingAnimation = computed(() => {
  return (
    loadingScreenRequested.value ||
    (route.name === 'edit' && !areColorsInitialized.value && !areMaterialsInitialized.value)
  );
});

const bgStore = useBackgroundMaterialStore();
const scStore = useSpotColorStore();
const instanceStore = useInstanceStore();

const instanceId = new URLSearchParams(window.location.search).get('uuid');
const idError = ref<string | null>(null);
if (instanceId == null) {
  idError.value = 'No instance UUID provided.';
  instanceStore.isError = true;
} else {
  apollo
    .query({
      query: QUERY_INSTANCE_BY_ID,
      variables: {
        id: instanceId
      }
    })
    .then((result) => {
      const instance = result.data.printweb_instance[0];
      instanceStore.name = instance.name;
      instanceStore.allowedUrl = instance.allowed_url || null;
      instanceStore.id = instance.id;
      instanceStore.isReady = true;

      const browserUrl =
        window.location != window.parent.location ? document.referrer : document.location.href;

      if (
        instance.allowed_url &&
        (!browserUrl || new URL(browserUrl).origin !== instance.allowed_url)
      ) {
        idError.value = 'This instance is not allowed to be used on this domain.';
        instanceStore.isError = true;
        return;
      }

      bgStore
        .init(instance.background_materials.map((bg) => bg.background_material))
        .then(() => {
          areMaterialsInitialized.value = true;
        })
        .catch((err) => {
          idError.value = 'Error while loading background materials.';
          instanceStore.isError = true;
        });
      scStore
        .init(instance.spot_colors.map((sc) => sc.spot_color))
        .then(() => {
          areColorsInitialized.value = true;
        })
        .catch((err) => {
          idError.value = 'Error while loading spot colors.';
          instanceStore.isError = true;
        });
    })
    .catch((err) => {
      idError.value = 'Instance UUID not valid';
      instanceStore.isError = true;
    });
}

const routeRequiresValidUuid = computed(() => {
  return !!route.meta.requiresValidUUID;
});

export interface IsLoading {
  set: (value: boolean) => void;
  value: boolean;
}
provide('isLoading', {
  set: (value: boolean) => {
    console.trace()
    loadingScreenRequested.value = value;
  },
  value: loadingScreenRequested.value
} as IsLoading);
</script>

<style scoped>
#application-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  width: 100vw;
}

.application-container-blurred > *:not(#global-loader):not(:first-child) {
  filter: blur(3px) !important;
  z-index: 1;
  pointer-events: none;
}
</style>

<style>
.id-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  background-color: #bbbbbb;
  z-index: 9999999;
  padding: 1rem;
  border-radius: 1rem;
  color: red;
}
</style>
