<template>
  <div id="upload-form-container">
    <Card id="upload-form">
      <template #title>Upload your PDF file here:</template>
      <template #content>
        <Accordion>
          <AccordionTab header="File requirements (English)">
            <p>
              Please use only closed formats to save your print data, preferably PDF (PDF/X-4 or PDF
              1.6). No registration, bleed or fold marks. Fonts must be embedded in the document or
              converted to paths to prevent discrepancies in the typeface. Use fonts that have no
              licensing restrictions and correct font encoding within the PDF.
            </p>
            <ul>
              <li>For Photos, images, and graphics: 300 dpi</li>
              <li>Line art (e.g., text with a small font size and drawings): 1,200 dpi</li>
              <li>Posters, large format advertising end-products: 150 dpi</li>
            </ul>
            <p>
              Always create your data in CMYK and not in RGB.<br />
              At best, choose the following color profile when creating your PDF:<br />
              FOGRA 39L / ISO Coated v2 300 (ECI).
            </p>
          </AccordionTab>
          <AccordionTab header="Datei-Anforderungen (Deutsch)">
            <p>
              Bitte nutzen Sie zum Speichern Ihrer Druckdaten nur geschlossene Formate, vorzugsweise
              PDF (PDF/X-4 bzw. PDF 1.6). Keine Pass-, Beschnitt- oder Falzmarken. Schriften müssen
              ins Dokument eingebettet oder in Pfade konvertiert sein, um Abweichungen im
              Schriftbild zu verhindern. Verwenden Sie Fonts, die keine lizenzrechtlichen
              Beschränkungen und eine korrekte Font-Kodierung im PDF aufweisen.
            </p>
            <ul>
              <li>Fotos, Bilder und Grafiken: 300 dpi</li>
              <li>
                Strichbilder (z. B. Texte mit einer geringen Schriftgröße und Zeichnungen): 1.200
                dpi
              </li>
              <li>Plakate, Werbetechnik-Produkte (Großformat): 150 dpi</li>
            </ul>
            <p>
              Legen Sie Ihre Daten immer in CMYK und nicht in RGB an.<br />
              Wählen Sie beim Erzeugen Ihres PDFs bestenfalls folgendes Farbprofil:<br />
              FOGRA 39L / ISO Coated v2 300 (ECI)
            </p>
          </AccordionTab>
        </Accordion>
        <FileUploader id="file-upload" @upload="upload" :errorMessage="errorMessage" />
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import Card from 'primevue/card';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { usePdfStore } from '@/stores/pdf';
import { PdfDocument } from 'pdfLib';
import { inject, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToastStore } from '@/stores/toast';
import type { IsLoading } from '@/App.vue';
import FileUploader from '@/components/FileUploader.vue';
import { useSpotColorStore } from '@/stores/instance/spotColor';
import ColorSpace from '../../../pdfLib/lib/contentStream/ColorSpace';
import { PdfFunction } from '../../../pdfLib/lib/pdfPrimitives/PdfDict';

const router = useRouter();
const pdfStore = usePdfStore();

const errorMessage = ref<string | null>(null);
const isLoading = inject('isLoading') as IsLoading;

const upload = (file: File) => {
  errorMessage.value = null;
  if (file.type !== 'application/pdf') {
    errorMessage.value = 'Uploaded file is not a PDF file.';
    return;
  }
  file.arrayBuffer().then((buffer) => {
    const bytes = new Uint8Array(buffer);
    if (
      bytes.subarray(0, 4).reduce((acc, value) => acc + String.fromCharCode(value), '') !== '%PDF'
    ) {
      errorMessage.value = 'Uploaded file is not a PDF file.';
      return;
    }
    const version = bytes
      .subarray(5, 8)
      .reduce((acc, value) => acc + String.fromCharCode(value), '');
    if (!['1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7'].includes(version)) {
      errorMessage.value = `PDF-Version ${version} is not supported. Please upload a PDF file with a version between 1.0 and 1.6.`;
      return;
    }

    handleNewPdfDocument(bytes);

  });
};

const colorStore = useSpotColorStore();
const toastStore = useToastStore();

const handleNewPdfDocument = (bytes: Uint8Array) => {
  isLoading!.set(true);
  setTimeout(() => {
    try {
      const document = new PdfDocument(bytes);
      document.parsedContentStreamPerPage.forEach((stream) => {
        colorStore.spotColors.forEach((color) => {
          const colorSpace = ColorSpace.fromIndividialComponents(
            'Separation',
            color.technical_name,
            new ColorSpace('DeviceGray'),
            PdfFunction.newType2([0, 1], [0, 1], [1], [0], 1)
          );
          stream.addSpotColorToRessources(colorSpace, 'spot_color' + color.display_name);
          stream.addExtGStatesForOverprintAndMultiply();
        });
      });
      document.setOnChangeHandler(handleNewPdfDocument);
      pdfStore.pdfDocument = document;
      nextTick(() => {
        router.push({ path: '/edit' });
        isLoading!.set(false);
        toastStore.successWithSummary('Loaded PDF file successfully.');
      });
    } catch (e: any) {
      isLoading!.set(false);
      console.error(e);
      errorMessage.value = 'Failed to parse PDF file.';
    }
  }, 100);
};
</script>

<style scoped>
/* center the card in the middle of the screen and limit the width of the card to 600px. Use grid */
#upload-form-container {
  align-items: center;
  display: grid;
  justify-content: center;
  width: 100%;
}

#upload-form {
  max-width: 600px;
  width: 80vw;
  max-height: 600px;
  overflow: auto;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

#file-upload {
  margin-top: 1rem;
}
</style>
