// stores/counter.js
import { defineStore } from 'pinia';
import { useSubscription } from '@vue/apollo-composable';

import { watch } from 'vue';
import { apollo } from '@/graphql/apollo';
import type {
  BackgroundMaterialFragment,
  Printweb_Instance_Background_Material_Insert_Input
} from '@/gql_gen/graphql';
import { FETCH_BACKGROUND_MATERIALS_SUBSCRIPTION } from '@/graphql/subscriptions';
import {
  DELETE_BACKGROUND_MATERIAL_GROUPS,
  DELETE_BACKGROUND_MATERIAL_MUTATION,
  INSERT_BACKGROUND_MATERIAL_GROUPS,
  INSERT_BACKGROUND_MATERIAL_MUTATION,
  UPDATE_BACKGROUND_MATERIAL_MUTATION
} from '@/graphql/mutations';
import { MATERIAL_IMAGE_BY_ID } from '@/graphql/queries';

type StoreBackgroundMaterial = BackgroundMaterialFragment & {
  image?: string; // extend fragment manually for postLoadImages
};

type BackgroundMaterialState = {
  materials: StoreBackgroundMaterial[];
  isLoading: boolean;
  totalCount: number;
};
export const useBackgroundMaterialStore = defineStore('materialBackgroundAdmin', {
  state: (): BackgroundMaterialState => {
    return {
      materials: [],
      isLoading: true, // -- initially set to true until subscription completes the first time
      totalCount: 0
    };
  },
  getters: {
    getMaterialById: (state) => {
      return (materialId: string) => state.materials.find((material) => material.id === materialId);
    }
  },
  actions: {
    async subscribeBackgroundMaterials() {
      const { result, loading, error } = useSubscription(FETCH_BACKGROUND_MATERIALS_SUBSCRIPTION);
      watch(result, (data) => {
        this.materials = data?.printweb_background_material ?? [];
        this.postLoadImages(this.materials);
      });

      watch(loading, (loading) => {
        this.isLoading = loading;
        console.log('colors Loading', loading);
      });

      watch(error, (error) => {
        console.error('error', error);
      });
    },
    async postLoadImages(materials: StoreBackgroundMaterial[]) {
      const result = await apollo.query({
        query: MATERIAL_IMAGE_BY_ID,
        variables: {
          ids: materials.map((material) => material.id)
        }
      });
      const images = result.data.printweb_background_material;
      materials.forEach((material) => {
        const image = images.find((image) => image.id === material.id);
        material.image = image?.image;
      });
    },
    async createBackgroundMaterial(data: BackgroundMaterialFragment) {
      await apollo.query({
        query: INSERT_BACKGROUND_MATERIAL_MUTATION,
        variables: {
          updateFields: data
        }
      });
    },
    async updateBackgroundMaterial(data: BackgroundMaterialFragment) {
      console.log('updateBackgroundMaterial', data);
      const { id, ...dataWithoutId } = data;
      await apollo.query({
        query: UPDATE_BACKGROUND_MATERIAL_MUTATION,
        variables: {
          id: data.id,
          _set: dataWithoutId
        }
      });
    },
    async updateBackgroundMaterialWithGroup(data: BackgroundMaterialFragment, groupIds: string[]) {
      console.log('updateBackgroundMaterialWithGroup', data, groupIds);
      await this.updateBackgroundMaterial(data);
      await this.updateGroupsOnMaterial(data.id, groupIds);
    },
    async updateGroupsOnMaterial(materialId: string, groupIds: string[]) {
      // first delete all groups
      await apollo.query({
        query: DELETE_BACKGROUND_MATERIAL_GROUPS,
        variables: {
          materialId: materialId
        }
      });
      const groups: Printweb_Instance_Background_Material_Insert_Input[] = groupIds.map(
        (groupId) => {
          return {
            background_material_id: materialId,
            material_group_id: groupId
          };
        }
      );

      await apollo.query({
        query: INSERT_BACKGROUND_MATERIAL_GROUPS,
        variables: {
          objects: groups
        }
      });
    },
    async deleteBackgroundMaterial(id: string) {
      console.log('deletBackgroundMaterial', id);
      await apollo.query({
        query: DELETE_BACKGROUND_MATERIAL_MUTATION,
        variables: {
          id: id
        }
      });
    }
  }
});
