import PdfArray from '../pdfPrimitives/PdfArray';
import PdfName from '../pdfPrimitives/PdfName';
import Ascii85Decoder from './Ascii85Decoder';
import FlateDecoder from './FlateDecoder';
import AsciiHexDecoder from './AsciiHexDecoder';
import DCTDecoder from './DctDecoder';
import { PdfDict } from '../..';

const DECODER_METHODS: any = {
  FlateDecode: FlateDecoder,
  ASCII85Decode: Ascii85Decoder,
  ASCIIHexDecode: AsciiHexDecoder,
  DCTDecode: DCTDecoder
};

const decodeWithMethod = (stream: Uint8Array, method: PdfName, params?: PdfDict): Uint8Array => {
  try {
    if (!DECODER_METHODS[method.valueOf()]) {
      console.warn(`Unknown filter method ${method.valueOf()}, returning original stream`);
      return stream;
    }
    return DECODER_METHODS[method.valueOf()](stream, params);
  } catch (e) {
    console.warn(`Decoding with method ${method.valueOf()} failed, returning original stream`, e);
    return stream;
  }
};

export const decodePdfStream = (
  stream: Uint8Array,
  filter: PdfName | PdfArray<PdfName>,
  params?: PdfDict
): Uint8Array => {
  let finalStream = stream;
  if (filter instanceof PdfArray) {
    for (const method of filter) {
      finalStream = decodeWithMethod(finalStream, method, params);
    }
  } else {
    finalStream = decodeWithMethod(finalStream, filter, params);
  }
  if (!finalStream) {
    console.warn(`Decoding stream failed, returning original stream`);
    return stream;
  }
  return finalStream;
};
