import Compiler from './Compiler';
import PdfNumber from '../pdfPrimitives/PdfNumber';

export default function compile(
  number: PdfNumber,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  const string = number.toString();
  // 0.2 -> .2
  // -0.2 -> -.2
  const stringWithoutLeadingZero = string.replace(/^(-?)0\./, '$1.');
  return Compiler.fromString(stringWithoutLeadingZero);
}
