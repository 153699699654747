import axios from 'axios';
console.log(import.meta.env.VITE_APP_API);
export const api = axios.create({
  baseURL: '/',
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});
/*
// Request interceptor for API calls
api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${userStore.authToken}`;
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
/*
// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);*/
