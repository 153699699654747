import { PdfDict } from '../..';
import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import { decodePdfStream } from '../pdfFilters/DecoderUtils';
import PdfArray from '../pdfPrimitives/PdfArray';
import PdfName from '../pdfPrimitives/PdfName';
import PdfNumber from '../pdfPrimitives/PdfNumber';
import PdfStream from '../pdfPrimitives/PdfStream';
import DictParser from './DictParser';
import type Parser from './Parser';

export default function parseStream(
  document: ReadablePdfStream,
  parser: Parser,
  resolveIndirect: boolean
): PdfStream {
  const pointerBefore = document.pointer;
  const dict = DictParser(document, parser, resolveIndirect, true);
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(6);
  if (nextString !== 'stream') {
    document.pointer = pointerBefore;
    throw new NotTheRightPdfElementError(`Expected 'stream' but got '${nextString}'`);
  }
  document.read(charactersRead);
  document.readNextNewline();
  const stream = document.read((dict.get('Length') as PdfNumber).valueOf());
  document.readBytesUntilBeforeNeedle('endstream');
  document.read(9); // endstream

  let finalStream = stream;
  // decoding
  if (dict.has('Filter')) {
    finalStream = decodePdfStream(
      finalStream,
      dict.get('Filter') as PdfArray<PdfName> | PdfName,
      dict.get('DecodeParms') as PdfDict | undefined
    );
    // compate finalStream and shortenedStream, if they are not the same remove the Filter from the dict
    if (finalStream.length !== stream.length) dict.delete('Filter');
  }
  dict.set('Length', new PdfNumber(finalStream.length));

  return new PdfStream(dict, finalStream);
}
