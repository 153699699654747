import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfArray from '../pdfPrimitives/PdfArray';
import { PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import type Parser from './Parser';

export default function parseArray(
  document: ReadablePdfStream,
  parser: Parser,
  resolveIndirect: boolean
): PdfArray<PdfPrimitive> {
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(1);
  if (nextString !== '[') {
    throw new NotTheRightPdfElementError(`Expected '[' but got '${nextString}'`);
  }
  document.read(charactersRead); // [
  const array: PdfPrimitive[] = [];
  while (document.peekStringIgnoringLeadingWhitespace(1)[0] !== ']') {
    array.push(parser.parse(document, resolveIndirect));
  }
  document.readStringIgnoringLeadingWhitespace(1); // ]
  return new PdfArray(array);
}
