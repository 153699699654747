import Compiler from './Compiler';
import PdfString from '../pdfPrimitives/PdfString';

export default function compile(
  string: PdfString,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  const stringRaw = string.valueOf();
  let processedString = stringRaw
    .replace(/\\/g, '\\\\')
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t')
    .replace(/\x08/g, '\\b')
    .replace(/\f/g, '\\f')
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)');
  // replace anything that is not a printable ASCII character with its octal code
  processedString = processedString.replace(/[^ -~]/g, (match) => {
    const charCode = match.charCodeAt(0);
    return `\\${charCode.toString(8).padStart(3, '0')}`;
  });
  const finalString = `(${processedString})`;
  return Compiler.fromString(finalString);
}
