import { text } from 'node:stream/consumers';
import {
  PdfArray,
  PdfBoolean,
  PdfDict,
  PdfHexString,
  PdfName,
  PdfNumber,
  PdfStream,
  PdfString,
  SvgPathElement,
  TextElement
} from '../..';
import ReadablePdfStream from '../ReadablePdfStream';
import Compiler from '../compiling/Compiler';
import Parser from '../parsing/Parser';
import PdfContentStreamOperator from '../pdfPrimitives/PdfContentStreamOperator';
import { PdfExtGState, PdfFont, PdfImage, type PdfPage } from '../pdfPrimitives/PdfDict';
import { type PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import ColorSpace from './ColorSpace';
import GraphicsState from './GraphicsState';
import ImageElement from './ImageElement';
import OperatorWithOperands from './OperatorWithOperands';
import type { SpotColorFragment } from '@/gql_gen/graphql';

export class ContentStream extends ReadablePdfStream {
  operations: OperatorWithOperands[];

  // need to store all elements in one array to keep the order
  elements: (SvgPathElement | TextElement)[] = [];

  ressources: PdfDict;
  page: PdfPage;

  constructor(bytes: Uint8Array, page: PdfPage) {
    super(bytes);

    // console.log(bytes.reduce((acc, cur) => acc + String.fromCharCode(cur), ''))

    this.page = page;
    this.ressources = page.get('Resources') as PdfDict;

    const parser = new Parser('CONTENT_STREAM');
    let operands: PdfPrimitive[] = [];
    const operators: OperatorWithOperands[] = [];
    while (!this.peekOnlyWhitespaceLeftUntilEOF()) {
      const nextElement = parser.parse(this, false);
      if (nextElement instanceof PdfContentStreamOperator) {
        operators.push(new OperatorWithOperands(nextElement, operands));
        operands = [];
      } else {
        operands.push(nextElement);
      }
    }

    this.operations = operators;

    this.execute();
  }

  getColorSpaceFromName(nameIn: string | PdfName): ColorSpace {
    let name;
    if (nameIn instanceof PdfName) name = nameIn.valueOf();
    else name = nameIn;

    if (name !== 'DeviceGray' && name !== 'DeviceRGB' && name !== 'DeviceCMYK') {
      // look up the name in the ressource directory
      const colorSpaceRessources = this.ressources.get('ColorSpace') as PdfDict;
      const colorSpace = colorSpaceRessources.get(name) as PdfArray<PdfPrimitive> | PdfName;
      return new ColorSpace(colorSpace);
    }

    return new ColorSpace(name);
  }

  execute() {
    // console.log(this.operations.reduce((acc, cur) => acc + cur.operands.reduce((accOp, curOp) => accOp + curOp.toString() + ' ', '') + cur.operator + '\n', ''));
    // console.log(this.bytes.reduce((acc, cur) => acc + String.fromCharCode(cur), ''));

    const initialState = new GraphicsState();
    const graphicsStateStack = [initialState];

    let currentPathSvg: SvgPathElement | null = null;
    let currentPathPoint = { x: 0, y: 0 };

    let currentTextElement: TextElement | null = null;

    const strokePath = (path: SvgPathElement) => {
      const strokeColor = graphicsStateStack[graphicsStateStack.length - 1].colorStroke;
      const strokeSpace = graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke;
      path.setStrokeColor(strokeColor, strokeSpace);
      path.hasStroke = true;
      path.strokeWidth =
        graphicsStateStack[graphicsStateStack.length - 1].lineWidth.valueOf();
      this.elements.push(path);
    };

    const fillPath = (path: SvgPathElement, mode: 'evenodd' | 'nonzero') => {
      const fillColor = graphicsStateStack[graphicsStateStack.length - 1].colorFill;
      const fillSpace = graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill;
      path.setFillColor(fillColor, fillSpace);
      path.fillMode = mode;
      path.hasFill = true;
      path.strokeWidth =
        graphicsStateStack[graphicsStateStack.length - 1].lineWidth.valueOf();
      this.elements.push(path);
    };

    const strokeAndFillPath = (path: SvgPathElement, mode: 'evenodd' | 'nonzero') => {
      const fillColor = graphicsStateStack[graphicsStateStack.length - 1].colorFill;
      const fillSpace = graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill;
      path.setFillColor(fillColor, fillSpace);
      path.fillMode = mode;
      path.hasStroke = true;
      path.hasFill = true;
      const strokeColor = graphicsStateStack[graphicsStateStack.length - 1].colorStroke;
      const strokeSpace = graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke;
      path.setStrokeColor(strokeColor, strokeSpace);
      path.strokeWidth =
        graphicsStateStack[graphicsStateStack.length - 1].lineWidth.valueOf();
      this.elements.push(path);
    };

    const closeOpenPath = () => {
      if (!currentPathSvg) throw new Error('No current path');
      if (!currentPathSvg.path.endsWith('Z ')) {
        currentPathSvg.path += 'Z ';
      }
    };

    const transformCoordinates = (x: number, y: number, transformationMatrix: number[]) => {
      const transformedX =
        transformationMatrix[0] * x + transformationMatrix[2] * y + transformationMatrix[4];
      const transformedY =
        transformationMatrix[1] * x + transformationMatrix[3] * y + transformationMatrix[5];
      return [transformedX, transformedY];
    };

    const transformCoordinatesCTM = (x: number, y: number) => {
      const transformationMatrix = graphicsStateStack[graphicsStateStack.length - 1].ctm;
      return transformCoordinates(x, y, transformationMatrix);
    };

    const transformCoordinatesTextMatrix = (x: number, y: number) => {
      const transformationMatrix =
        graphicsStateStack[graphicsStateStack.length - 1].textState.textMatrix;
      return transformCoordinates(x, y, transformationMatrix);
    };

    const getCurrentFont = (): PdfFont => {
      const fontName = graphicsStateStack[graphicsStateStack.length - 1].textState.font;
      if (!fontName) throw new Error('No font name');
      const fontRessources = this.ressources.get('Font') as PdfDict;
      const fontDict = fontRessources.get(fontName.valueOf()) as PdfFont;
      return fontDict;
    };

    const getCurrentStrokeSpaceNameForText = (): string | null => {
      const state = graphicsStateStack[graphicsStateStack.length - 1];
      const doStroke = [1, 2, 5, 6].includes(state.textState.renderingMode.valueOf());
      if (!doStroke) return null;
      return graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke.name;
    };

    const getCurrentFillSpaceNameForText = (): string | null => {
      const state = graphicsStateStack[graphicsStateStack.length - 1];
      const doFill = [0, 2, 4, 6].includes(state.textState.renderingMode.valueOf());
      if (!doFill) return null;
      return graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill.name;
    };

    let currentMarkedId: string | null = null;
    let currentMarkedIdStartIndex = 0;
    let elementToSetEndIndex: SvgPathElement | TextElement | null = null;
    let numberOfEmcToIgnoreUntilMarkerClosed = 0;
    let addEmcAtEnd = false;
    let nextPrintwebID = 0;

    for (let index = 0; index < this.operations.length; index++) {
      const operation = this.operations[index];
      const args = operation.operands;
      // console.log(index)
      switch (operation.operator.valueOf()) {
        // Graphics State Operators
        case 'q': {
          graphicsStateStack.push(graphicsStateStack[graphicsStateStack.length - 1].clone());
          break;
        }
        case 'Q': {
          graphicsStateStack.pop();
          break;
        }
        case 'cm': {
          graphicsStateStack[graphicsStateStack.length - 1].ctm = multiplyMatrices(
            graphicsStateStack[graphicsStateStack.length - 1].ctm,
            args as number[]
          );
          break;
        }
        case 'w': {
          graphicsStateStack[graphicsStateStack.length - 1].lineWidth = args[0] as PdfNumber;
          break;
        }
        case 'J': {
          graphicsStateStack[graphicsStateStack.length - 1].lineCap = args[0] as PdfNumber;
          break;
        }
        case 'j': {
          graphicsStateStack[graphicsStateStack.length - 1].lineJoin = args[0] as PdfNumber;
          break;
        }
        case 'M': {
          graphicsStateStack[graphicsStateStack.length - 1].miterLimit = args[0] as PdfNumber;
          break;
        }
        case 'd': {
          graphicsStateStack[graphicsStateStack.length - 1].dashPatternArray =
            args[0] as PdfArray<PdfNumber>;
          graphicsStateStack[graphicsStateStack.length - 1].dashPatternNumber =
            args[1] as PdfNumber;
          break;
        }
        case 'ri': {
          graphicsStateStack[graphicsStateStack.length - 1].renderingIntent = args[0] as PdfName;
          break;
        }
        case 'i': {
          graphicsStateStack[graphicsStateStack.length - 1].flatness = args[0] as PdfNumber;
          break;
        }
        case 'gs': {
          // load graphics state from ressources
          const graphicsStateName = args[0] as PdfName;
          const graphicsStateRessources = this.ressources.get('ExtGState') as PdfDict;
          const graphicsStateDict = graphicsStateRessources.get(
            graphicsStateName.valueOf()
          ) as PdfExtGState;
          graphicsStateStack[graphicsStateStack.length - 1].loadExtGState(graphicsStateDict);
          break;
        }
        // Path Construction Operators
        case 'm': {
          if (!currentPathSvg) {
            currentPathSvg = new SvgPathElement();
            elementToSetEndIndex = currentPathSvg;
            if (currentMarkedId === null) {
              const newId = '' + nextPrintwebID++;
              this.operations.splice(
                index,
                0,
                new OperatorWithOperands(new PdfContentStreamOperator('BMC'), [
                  new PdfName('PrintWebId_' + newId)
                ])
              );
              currentMarkedId = newId;
              currentMarkedIdStartIndex = index;
              addEmcAtEnd = true;
              index++;
            }
            currentPathSvg.printwebId = currentMarkedId;
            currentPathSvg.startIndex = currentMarkedIdStartIndex;
          }
          const [x, y] = transformCoordinatesCTM(
            args[0].valueOf() as number,
            args[1].valueOf() as number
          );
          currentPathSvg.path += `M ${x} ${y} `;
          currentPathPoint = { x: args[0].valueOf() as number, y: args[1].valueOf() as number };
          currentPathSvg.anchor = { x, y };
          break;
        }
        case 'l': {
          if (!currentPathSvg) throw new Error('No current path');
          const [x, y] = transformCoordinatesCTM(
            args[0].valueOf() as number,
            args[1].valueOf() as number
          );
          currentPathSvg.path += `L ${x} ${y} `;
          currentPathPoint = { x, y };
          break;
        }
        case 'c': {
          if (!currentPathSvg) throw new Error('No current path');
          const [x1, y1] = transformCoordinatesCTM(
            args[0].valueOf() as number,
            args[1].valueOf() as number
          );
          const [x2, y2] = transformCoordinatesCTM(
            args[2].valueOf() as number,
            args[3].valueOf() as number
          );
          const [x3, y3] = transformCoordinatesCTM(
            args[4].valueOf() as number,
            args[5].valueOf() as number
          );
          currentPathSvg.path += `C ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} `;
          currentPathPoint = { x: x3, y: y3 };
          break;
        }
        case 'v': {
          if (!currentPathSvg) throw new Error('No current path');
          const [x2, y2] = transformCoordinatesCTM(
            args[0].valueOf() as number,
            args[1].valueOf() as number
          );
          const [x3, y3] = transformCoordinatesCTM(
            args[2].valueOf() as number,
            args[3].valueOf() as number
          );
          currentPathSvg.path += `C ${currentPathPoint.x} ${currentPathPoint.y} ${x2} ${y2} ${x3} ${y3} `;
          currentPathPoint = { x: x3, y: y3 };
          break;
        }
        case 'y': {
          if (!currentPathSvg) throw new Error('No current path');
          const [x1, y1] = transformCoordinatesCTM(
            args[0].valueOf() as number,
            args[1].valueOf() as number
          );
          const [x3, y3] = transformCoordinatesCTM(
            args[2].valueOf() as number,
            args[3].valueOf() as number
          );
          currentPathSvg.path += `C ${x1} ${y1} ${x3} ${y3} ${x3} ${y3} `;
          currentPathPoint = { x: x3, y: y3 };
          break;
        }
        case 'h': {
          if (!currentPathSvg) throw new Error('No current path');
          closeOpenPath();
          break;
        }
        case 're': {
          if (!currentPathSvg) {
            currentPathSvg = new SvgPathElement();
            elementToSetEndIndex = currentPathSvg;
            if (currentMarkedId === null) {
              const newId = '' + nextPrintwebID++;
              this.operations.splice(
                index,
                0,
                new OperatorWithOperands(new PdfContentStreamOperator('BMC'), [
                  new PdfName('PrintWebId_' + newId)
                ])
              );
              currentMarkedId = newId;
              currentMarkedIdStartIndex = index;
              currentPathSvg.printwebId = currentMarkedId;
              addEmcAtEnd = true;
              index++;
            }
            currentPathSvg.startIndex = currentMarkedIdStartIndex;
            currentPathSvg.printwebId = currentMarkedId;
          }
          const x = args[0].valueOf() as number;
          const y = args[1].valueOf() as number;
          const width = args[2].valueOf() as number;
          const height = args[3].valueOf() as number;
          const [x1, y1] = transformCoordinatesCTM(x, y);
          const [x2, y2] = transformCoordinatesCTM(x + width, y + height);
          currentPathSvg.path += `M ${x1} ${y1} `;
          currentPathSvg.path += `L ${x2} ${y1} `;
          currentPathSvg.path += `L ${x2} ${y2} `;
          currentPathSvg.path += `L ${x1} ${y2} `;
          currentPathSvg.path += `L ${x1} ${y1} `;
          currentPathSvg.anchor = { x: x1, y: y1 };
          closeOpenPath();
          break;
        }
        // Path-Painting Operators
        case 'S': {
          if (!currentPathSvg) throw new Error('No current path');
          strokePath(currentPathSvg);
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 's': {
          if (!currentPathSvg) throw new Error('No current path');
          closeOpenPath();
          strokePath(currentPathSvg);
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'f':
        case 'F': {
          if (!currentPathSvg) throw new Error('No current path');
          closeOpenPath();
          fillPath(currentPathSvg, 'nonzero');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'f*': {
          if (!currentPathSvg) throw new Error('No current path');
          fillPath(currentPathSvg, 'evenodd');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'B': {
          if (!currentPathSvg) throw new Error('No current path');
          strokeAndFillPath(currentPathSvg, 'nonzero');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'B*': {
          if (!currentPathSvg) throw new Error('No current path');
          strokeAndFillPath(currentPathSvg, 'evenodd');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'b': {
          if (!currentPathSvg) throw new Error('No current path');
          if (!elementToSetEndIndex) throw new Error('No element to set end index');
          closeOpenPath();
          strokeAndFillPath(currentPathSvg, 'nonzero');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'b*': {
          if (!currentPathSvg) throw new Error('No current path');
          if (!elementToSetEndIndex) throw new Error('No element to set end index');
          closeOpenPath();
          strokeAndFillPath(currentPathSvg, 'evenodd');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        case 'n': {
          if (!currentPathSvg) throw new Error('No current path');
          if (!elementToSetEndIndex) throw new Error('No element to set end index');
          currentPathSvg = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        // Clipping Path Operators
        case 'W':
        case 'W*': {
          break;
        }
        // Text Object Operators
        case 'BT': {
          if (currentMarkedId === null) {
            const newId = '' + nextPrintwebID++;
            this.operations.splice(
              index,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('BMC'), [
                new PdfName('PrintWebId_' + newId)
              ])
            );
            currentMarkedIdStartIndex = index;
            currentMarkedId = newId;
            addEmcAtEnd = true;
            index++;
          }
          currentTextElement = new TextElement();
          currentTextElement.startIndex = currentMarkedIdStartIndex;
          currentTextElement.printwebId = currentMarkedId;
          elementToSetEndIndex = currentTextElement;
          break;
        }
        case 'ET': {
          this.elements.push(currentTextElement!);
          currentTextElement = null;
          if (addEmcAtEnd) {
            this.operations.splice(
              index + 1,
              0,
              new OperatorWithOperands(new PdfContentStreamOperator('EMC'), [])
            );
            addEmcAtEnd = false;
          }
          break;
        }
        // Text State Operators
        case 'Tc': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.characterSpacing =
            args[0] as PdfNumber;
          break;
        }
        case 'Tw': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.wordSpacing =
            args[0] as PdfNumber;
          break;
        }
        case 'Tz': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.horizontalScaling =
            args[0] as PdfNumber;
          break;
        }
        case 'TL': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.leading =
            args[0] as PdfNumber;
          break;
        }
        case 'Tf': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.font = args[0] as PdfName;
          graphicsStateStack[graphicsStateStack.length - 1].textState.fontSize =
            args[1] as PdfNumber;
          break;
        }
        case 'Tr': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.renderingMode =
            args[0] as PdfNumber;
          break;
        }
        case 'Ts': {
          graphicsStateStack[graphicsStateStack.length - 1].textState.rise = args[0] as PdfNumber;
          break;
        }
        // Text Positioning Operators
        case 'Td': {
          const [tx, ty] = args as PdfNumber[];
          const newLineMatrix = multiplyMatrices(
            graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix,
            [1, 0, 0, 1, tx.valueOf(), ty.valueOf()]
          );
          graphicsStateStack[graphicsStateStack.length - 1].textState.textMatrix = newLineMatrix;
          graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix =
            newLineMatrix;
          if (currentTextElement && ty.valueOf() !== 0) currentTextElement.addLineBreak();
          break;
        }
        case 'TD': {
          const [tx, ty] = args as PdfNumber[];
          const newLineMatrix = multiplyMatrices(
            graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix,
            [1, 0, 0, 1, tx.valueOf(), ty.valueOf()]
          );
          graphicsStateStack[graphicsStateStack.length - 1].textState.textMatrix = newLineMatrix;
          graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix =
            newLineMatrix;
          graphicsStateStack[graphicsStateStack.length - 1].textState.leading = -ty;
          if (currentTextElement && ty.valueOf() !== 0) currentTextElement.addLineBreak();
          break;
        }
        case 'Tm': {
          const [a, b, c, d, e, f] = args as PdfNumber[];
          const newMatrix = [
            a.valueOf(),
            b.valueOf(),
            c.valueOf(),
            d.valueOf(),
            e.valueOf(),
            f.valueOf()
          ];
          graphicsStateStack[graphicsStateStack.length - 1].textState.textMatrix = newMatrix;
          graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix = newMatrix;
          break;
        }
        case 'T*': {
          const newLineMatrix = multiplyMatrices(
            graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix,
            [1, 0, 0, 1, 0, -graphicsStateStack[graphicsStateStack.length - 1].textState.leading]
          );
          graphicsStateStack[graphicsStateStack.length - 1].textState.textMatrix = newLineMatrix;
          graphicsStateStack[graphicsStateStack.length - 1].textState.textLineMatrix =
            newLineMatrix;
          if (currentTextElement) currentTextElement.addLineBreak();
          break;
        }
        // Text Showing Operators
        case 'Tj': {
          if (!currentTextElement) throw new Error('No current text element');
          let text = args[0] as PdfString | PdfHexString;
          const font = getCurrentFont();
          const bytes = font.turnTextIntoBytes(text);
          const characters = font.splitIntoCharacters(bytes);

          if (characters.length > 1) {
            const newTjs = characters.map((char) => {
              const tj = new PdfContentStreamOperator('Tj');
              const operand = [
                new PdfString(char.reduce((acc, cur) => acc + String.fromCharCode(cur), ''))
              ];
              return new OperatorWithOperands(tj, operand);
            });
            this.operations.splice(index, 1, ...newTjs);
            text = newTjs[0].operands[0] as PdfString;
          }

          currentTextElement.addTj(
            text,
            graphicsStateStack[graphicsStateStack.length - 1].textState.renderingMode.valueOf(),
            font,
            index,
            graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke,
            graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill
          );

          if (!currentTextElement.anchor) {
            const [x, y] = transformCoordinatesTextMatrix(0, 0);
            const [x2, y2] = transformCoordinatesCTM(x, y);
            currentTextElement.anchor = { x: x2, y: y2 };
          }
          break;
        }
        case "'": {
          if (!currentTextElement) throw new Error('No current text element');

          // replace with T* and Tj
          const newOperations = [
            new OperatorWithOperands(new PdfContentStreamOperator('T*'), []),
            new OperatorWithOperands(new PdfContentStreamOperator('Tj'), [args[0]])
          ];
          this.operations.splice(index, 1, ...newOperations);
          index--; // So that the first new command will be executed in the next iteration
          break;
        }
        case '"': {
          if (!currentTextElement) throw new Error('No current text element');

          // replace with Tw, Tc, T*, and Tj
          const newOperations = [
            new OperatorWithOperands(new PdfContentStreamOperator('Tw'), [args[0]]),
            new OperatorWithOperands(new PdfContentStreamOperator('Tc'), [args[1]]),
            new OperatorWithOperands(new PdfContentStreamOperator('T*'), []),
            new OperatorWithOperands(new PdfContentStreamOperator('Tj'), [args[2]])
          ];
          this.operations.splice(index, 1, ...newOperations);
          index--; // So that the first new command will be executed in the next iteration
          break;
        }
        case 'TJ': {
          if (!currentTextElement) throw new Error('No current text element');
          const textArray = args[0] as PdfArray<PdfPrimitive>;
          const font = getCurrentFont();
          currentTextElement.addTj(
            textArray,
            graphicsStateStack[graphicsStateStack.length - 1].textState.renderingMode.valueOf(),
            font,
            index,
            graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke,
            graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill
          );
          if (!currentTextElement.anchor) {
            const [x, y] = transformCoordinatesTextMatrix(0, 0);
            const [x2, y2] = transformCoordinatesCTM(x, y);
            currentTextElement.anchor = { x: x2, y: y2 };
          }
          break;
        }
        // Type 3 Font Operators
        case 'd0':
        case 'd1': {
          break;
        }
        // Color Operators
        case 'CS': {
          const cs = this.getColorSpaceFromName(args[0] as PdfName);
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke = cs;
          graphicsStateStack[graphicsStateStack.length - 1].colorStroke = cs.getInitialColor();
          break;
        }
        case 'cs': {
          const cs = this.getColorSpaceFromName(args[0] as PdfName);
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill = cs;
          graphicsStateStack[graphicsStateStack.length - 1].colorFill = cs.getInitialColor();
          break;
        }
        case 'SC': {
          graphicsStateStack[graphicsStateStack.length - 1].colorStroke = args;
          break;
        }
        case 'SCN': {
          graphicsStateStack[graphicsStateStack.length - 1].colorStroke = args;
          break;
        }
        case 'sc': {
          graphicsStateStack[graphicsStateStack.length - 1].colorFill = args;
          break;
        }
        case 'scn': {
          graphicsStateStack[graphicsStateStack.length - 1].colorFill = args;
          break;
        }
        case 'G': {
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke = new ColorSpace(
            'DeviceGray'
          );
          graphicsStateStack[graphicsStateStack.length - 1].colorStroke = [
            args[0].valueOf() as number
          ];
          break;
        }
        case 'g': {
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill = new ColorSpace(
            'DeviceGray'
          );
          graphicsStateStack[graphicsStateStack.length - 1].colorFill = [
            args[0].valueOf() as number
          ];
          break;
        }
        case 'RG': {
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke = new ColorSpace(
            'DeviceRGB'
          );
          graphicsStateStack[graphicsStateStack.length - 1].colorStroke = [
            args[0].valueOf(),
            args[1].valueOf(),
            args[2].valueOf()
          ] as number[];
          break;
        }
        case 'rg': {
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill = new ColorSpace(
            'DeviceRGB'
          );
          graphicsStateStack[graphicsStateStack.length - 1].colorFill = [
            args[0].valueOf(),
            args[1].valueOf(),
            args[2].valueOf()
          ] as number[];
          break;
        }
        case 'K': {
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceStroke = new ColorSpace(
            'DeviceCMYK'
          );
          graphicsStateStack[graphicsStateStack.length - 1].colorStroke = [
            args[0].valueOf(),
            args[1].valueOf(),
            args[2].valueOf(),
            args[3].valueOf()
          ] as number[];
          break;
        }
        case 'k': {
          graphicsStateStack[graphicsStateStack.length - 1].colorSpaceFill = new ColorSpace(
            'DeviceCMYK'
          );
          graphicsStateStack[graphicsStateStack.length - 1].colorFill = [
            args[0].valueOf(),
            args[1].valueOf(),
            args[2].valueOf(),
            args[3].valueOf()
          ] as number[];
          break;
        }
        // Shading Pattern Operators
        case 'sh': {
          break;
        }
        // Inline Image Operators
        case 'BI':
        case 'ID':
        case 'EI': {
          if (operation.operator === 'BI') console.warn('inline image encountered');
          break;
        }
        // XObject Operators
        case 'Do': {
          const xobjectName = args[0] as PdfName;
          const xobjectRessources = this.ressources.get('XObject') as PdfDict;
          const xobjectStream = xobjectRessources.get(xobjectName.valueOf()) as PdfStream;
          if (xobjectStream.dict instanceof PdfImage) {
            const imageElement = new ImageElement(xobjectStream);
            imageElement.paintIndex = index;
            // this.elements.push(imageElement);
          }
          break;
        }
        // Marked Content Operators
        case 'MP': {
          const markedContentName = args[0] as PdfName;
          console.log(markedContentName.valueOf(), elementToSetEndIndex)
          if (markedContentName.valueOf().startsWith('PrintWebOldOperation_') || markedContentName.valueOf().startsWith('PrintWebInsertedOperation')) {
            if(elementToSetEndIndex !== null) {
              console.log(elementToSetEndIndex.constructor.name)
              elementToSetEndIndex.hasUndoeableOperations = true;
            }
          }
          break;
        }
        case 'DP': {
          break;
        }
        case 'BDC': {
          numberOfEmcToIgnoreUntilMarkerClosed++;
          break;
        }
        case 'BMC': {
          const markedContentName = args[0] as PdfName;
          if (markedContentName.valueOf().startsWith('PrintWebId_')) {
            currentMarkedId = markedContentName.valueOf().substring(11);
            numberOfEmcToIgnoreUntilMarkerClosed = 0;
            currentMarkedIdStartIndex = index;
          } else {
            numberOfEmcToIgnoreUntilMarkerClosed++;
          }
          break;
        }
        case 'EMC': {
          if (numberOfEmcToIgnoreUntilMarkerClosed > 0) {
            numberOfEmcToIgnoreUntilMarkerClosed--;
          } else {
            currentMarkedId = null;
            if (elementToSetEndIndex) {
              elementToSetEndIndex.endIndex = index;
              elementToSetEndIndex = null;
            }
          }
          break;
        }
        // Compatibility Operators
        case 'BX':
        case 'EX': {
          break;
        }
        default:
          throw new Error(`Unknown operator "${operation.operator}"`);
      }
    }
    console.log(this.operations.reduce((acc, cur) => acc + cur.operands.reduce((accOp, curOp) => accOp + curOp.toString() + ' ', '') + cur.operator + '\n', ''));
  }

  splitTextElement(textElement: TextElement, preserveWords: boolean = true) {
    const startIndex = textElement.startIndex;
    const endIndex = textElement.endIndex;

    const oldOperations: OperatorWithOperands[] = this.operations.splice(
      startIndex,
      endIndex - startIndex + 1
    );

    // remove ID markers
    const bmcOperator = oldOperations.shift();
    oldOperations.pop();

    const id = bmcOperator?.operands[0].valueOf() as string;
    const metaOperatorStack: OperatorWithOperands[] = [];
    const newTextElements: OperatorWithOperands[][] = [];
    let currentTextElement: OperatorWithOperands[] = [];

    let tjIndex = 0;
    for (let i = 0; i < oldOperations.length; i++) {
      const operation = oldOperations[i];
      const operator = operation.operator.valueOf();

      if (operator === 'TJ') {
        // TJ is a unsplittable group, that we will treat as a word -> will always be split into a new text element
        currentTextElement.push(operation);
        newTextElements.push(currentTextElement);
        currentTextElement = [...metaOperatorStack];
        tjIndex++;
      } else if (
        (operator === 'Td' && operation.operands[1].valueOf() !== 0) ||
        (operator === 'TD' && operation.operands[1].valueOf() !== 0) ||
        operator === 'T*' // always a new line
      ) {
        // These operators indicate a new line, so we will always split into a new text element
        metaOperatorStack.push(operation);
        currentTextElement.push(operation);
        newTextElements.forEach((element) => element.push(operation));

        newTextElements.push(currentTextElement);
        currentTextElement = [...metaOperatorStack];
      } else if (operator === 'Tj') {
        // Tj is a single character, so we will split into a new text element if preserveWords is false
        if (!preserveWords) {
          currentTextElement.push(operation);
          newTextElements.push(currentTextElement);
          currentTextElement = [...metaOperatorStack];
        } else {
          const isWhitespaceCharacter = ContentStream.WHITESPACE_CHARS.includes(
            textElement.getIthTj(tjIndex)!.text
          );
          // if the character is a whitespace character, we will create a new text element for the existing text, if it is not empty, and then create a new text element for the whitespace character
          if (isWhitespaceCharacter) {
            if (currentTextElement.length !== metaOperatorStack.length) {
              newTextElements.push(currentTextElement);
              currentTextElement = [...metaOperatorStack];
            }
            currentTextElement.push(operation);
            newTextElements.push(currentTextElement);
            currentTextElement = [...metaOperatorStack];
          } else {
            currentTextElement.push(operation);
          }
        }
        tjIndex++;
      } else {
        // all other operators are treated as meta operators, which will be included in every text element
        metaOperatorStack.push(operation);
        currentTextElement.push(operation);
        newTextElements.forEach((element) => element.push(operation));
      }
    }
    // push the last text element
    newTextElements.push(currentTextElement);

    newTextElements.forEach((element, index) => {
      // bracket with q and Q
      element.unshift(new OperatorWithOperands(new PdfContentStreamOperator('q'), []));
      element.push(new OperatorWithOperands(new PdfContentStreamOperator('Q'), []));
      // bracket with BMC and EMC
      element.unshift(
        new OperatorWithOperands(new PdfContentStreamOperator('BMC'), [
          new PdfName(id + '_split_' + index)
        ])
      );
      element.push(new OperatorWithOperands(new PdfContentStreamOperator('EMC'), []));
    });

    this.injectOperationsAt(startIndex, newTextElements.flat());
  }

  injectOperationsAt(index: number, operations: OperatorWithOperands[]) {
    this.operations.splice(index, 0, ...operations);
    this.execute();
  }

  removeColoringOperationsBetween(startIndex: number, endIndex: number): number {
    let removedOperations = 0;
    removedOperations += this.removeStrokeColoringOperationsBetween(startIndex, endIndex);
    removedOperations += this.removeFillColoringOperationsBetween(startIndex, endIndex);
    return removedOperations;
  }

  removeStrokeColoringOperationsBetween(startIndex: number, endIndex: number): number {
    let removedOperations = 0;
    const coloringOperations = ['CS', 'SC', 'SCN', 'G', 'RG', 'K'];
    for (let i = startIndex; i < endIndex; i++) {
      const operation = this.operations[i].operator.valueOf();
      if (coloringOperations.includes(operation)) {
        this.operations.splice(i, 1);
        i--;
        removedOperations++;
      }
    }
    return removedOperations;
  }

  removeFillColoringOperationsBetween(startIndex: number, endIndex: number): number {
    let removedOperations = 0;
    const coloringOperations = ['cs', 'sc', 'scn', 'g', 'rg', 'k'];
    for (let i = startIndex; i < endIndex; i++) {
      const operation = this.operations[i].operator.valueOf();
      if (coloringOperations.includes(operation)) {
        this.operations.splice(i, 1);
        i--;
        removedOperations++;
      }
    }
    return removedOperations;
  }

  setStrokeColorAtIndex(
    startIndex: number,
    stopIndex: number,
    colorspaceName: string,
    tint: number
  ) {
    const ressourceIdentifier = this.getAllColorSpaces().find((cs) => cs.name === colorspaceName)
      ?.ressourceIdentifier;
    if (!ressourceIdentifier) {
      throw new Error('Colorspace not found');
    }
    // q, CS, SCN
    const setColorOperations = [
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
        new PdfName(ressourceIdentifier)
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('SCN'), [new PdfNumber(tint)])
    ];

    this.injectOperationsAt(startIndex, setColorOperations);

    const numRemovedOperations = this.removeStrokeColoringOperationsBetween(
      startIndex + setColorOperations.length,
      stopIndex
    );

    const resetOperations = [new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])];

    this.injectOperationsAt(
      stopIndex + setColorOperations.length - numRemovedOperations + 1,
      resetOperations
    );
    return setColorOperations.length + resetOperations.length - numRemovedOperations;
  }

  setFillColorAtIndex(startIndex: number, stopIndex: number, colorspaceName: string, tint: number) {
    const ressourceIdentifier = this.getAllColorSpaces().find((cs) => cs.name === colorspaceName)
      ?.ressourceIdentifier;
    if (!ressourceIdentifier) {
      throw new Error('Colorspace not found');
    }
    // q, cs, scn
    const setColorOperations = [
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('cs'), [
        new PdfName(ressourceIdentifier)
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('scn'), [new PdfNumber(tint)])
    ];

    this.injectOperationsAt(startIndex, setColorOperations);

    const numRemovedOperations = this.removeFillColoringOperationsBetween(
      startIndex + setColorOperations.length,
      stopIndex
    );

    const resetOperations = [new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])];

    this.injectOperationsAt(
      stopIndex + setColorOperations.length - numRemovedOperations + 1,
      resetOperations
    );

    return setColorOperations.length + resetOperations.length - numRemovedOperations;
  }

  removeColoringOperations(operationsToProcess: OperatorWithOperands[]): OperatorWithOperands[] {
    const coloringOperations = [
      'CS',
      'SC',
      'SCN',
      'G',
      'RG',
      'K',
      'cs',
      'sc',
      'scn',
      'g',
      'rg',
      'k'
    ];
    return operationsToProcess.filter(
      (op) => !coloringOperations.some((o) => o === op.operator.valueOf())
    );
  }

  setFillAndStrokeColorAtIndex(args: {
    startIndexPaint: number;
    endIndexPaint: number;
    colorspaceName: string;
    tint: number;
    duplicate: boolean;
    multiply: boolean;
    overprint: boolean;
    startIndexDuplicate: number;
    endIndexDuplicate: number;
  }) {
    const {
      startIndexPaint,
      endIndexPaint,
      colorspaceName,
      tint,
      duplicate,
      multiply,
      overprint,
      startIndexDuplicate,
      endIndexDuplicate
    } = args;

    this.addExtGStatesForOverprintAndMultiply();
    const colorSpaceIdentifier = this.getAllColorSpaces().find((cs) => cs.name === colorspaceName)
      ?.ressourceIdentifier;
    if (!colorSpaceIdentifier) {
      throw new Error('Colorspace not found');
    }

    const originalOperationsForDrawing = this.operations.splice(
      startIndexPaint,
      endIndexPaint - startIndexPaint + 1
    );

    const header = [
      // save graphics state
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      // set overprint and multiply by loading extGStates
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(overprint ? 'OverprintStrokingOn' : 'OverprintStrokingOff')
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(overprint ? 'OverprintFillOn' : 'OverprintFillOff')
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(multiply ? 'BlendModeMultiply' : 'BlendModeNormal')
      ]),
      // set color
      new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
        new PdfName(colorSpaceIdentifier)
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('cs'), [
        new PdfName(colorSpaceIdentifier)
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('SCN'), [new PdfNumber(tint)]),
      new OperatorWithOperands(new PdfContentStreamOperator('scn'), [new PdfNumber(tint)])
    ];

    const footer = [
      // restore graphics state
      new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])
    ];

    const allOperations = [
      ...(duplicate ? originalOperationsForDrawing : []),
      ...header,
      ...this.removeColoringOperations(originalOperationsForDrawing),
      ...footer
    ];

    this.injectOperationsAt(startIndexPaint, allOperations);
  }

  duplicateOperationsBetween(startIndex: number, endIndex: number): number {
    // add preserving q Q to original operations
    this.injectOperationsAt(startIndex, [
      new OperatorWithOperands(new PdfContentStreamOperator('q'), [])
    ]);
    endIndex++;
    this.injectOperationsAt(endIndex + 1, [
      new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])
    ]);
    endIndex++;

    const operationsToDuplicate = this.operations.slice(startIndex, endIndex + 1);
    this.injectOperationsAt(endIndex + 1, operationsToDuplicate);
    return operationsToDuplicate.length + 2;
  }

  addSpotColorToRessources(spotColor: ColorSpace, ressourceIdentifier: string) {
    let colorSpaceRessources = this.ressources.get('ColorSpace') as PdfDict;
    if (colorSpaceRessources === undefined) {
      colorSpaceRessources = new PdfDict();
      this.ressources.set('ColorSpace', colorSpaceRessources);
    }

    if (this.getAllColorSpaces().some((cs) => cs.name === spotColor.name)) {
      return;
    }
    const newRessource = spotColor.toColorSpaceRessource();
    let finalName = ressourceIdentifier;
    let counter = 1;
    while (colorSpaceRessources.has(ressourceIdentifier)) {
      finalName = ressourceIdentifier + counter;
      counter++;
    }
    colorSpaceRessources.set(finalName, newRessource);
  }

  getAllColorSpaces(): ColorSpace[] {
    const colorSpaceRessources = this.ressources.get('ColorSpace') as PdfDict;
    if (colorSpaceRessources === undefined) {
      return [];
    }
    const colorSpaces: ColorSpace[] = [];
    for (const [key, value] of colorSpaceRessources.entries()) {
      colorSpaces.push(new ColorSpace(value as any, key));
    }
    return colorSpaces;
  }

  getAllUsedColorSpaces(): ColorSpace[] {
    // iterate over all operations and collect all colorspaces
    const colorSpaces: ColorSpace[] = [];
    for (const operation of this.operations) {
      const operator = operation.operator.valueOf();
      if (operator === 'CS' || operator === 'cs') {
        const colorSpaceName = operation.operands[0] as PdfName;
        const colorSpace = this.getColorSpaceFromName(colorSpaceName);
        if (!colorSpaces.some((cs) => cs.name === colorSpace.name)) {
          colorSpaces.push(colorSpace);
        }
      }
    }
    return colorSpaces;
  }

  getAllExtGStateNames(): string[] {
    const extGStateRessources = this.ressources.get('ExtGState') as PdfDict;
    if (extGStateRessources === undefined) {
      return [];
    }
    return Array.from(extGStateRessources.keys());
  }

  addExtGStateToRessources(extGState: PdfExtGState, ressourceIdentifier: string) {
    let extGStateRessources = this.ressources.get('ExtGState') as PdfDict;
    if (extGStateRessources === undefined) {
      extGStateRessources = new PdfDict();
      this.ressources.set('ExtGState', extGStateRessources);
    }

    if (this.getAllExtGStateNames().some((name) => name === ressourceIdentifier)) {
      return;
    }
    extGStateRessources.set(ressourceIdentifier, extGState);
  }

  addExtGStatesForOverprintAndMultiply() {
    const overprintStrokingOn = new PdfExtGState();
    overprintStrokingOn.set('OP', new PdfBoolean(true));
    overprintStrokingOn.set('OPM', new PdfNumber(1));
    this.addExtGStateToRessources(overprintStrokingOn, 'OverprintStrokingOn');

    const overprintFillOn = new PdfExtGState();
    overprintFillOn.set('op', new PdfBoolean(true));
    overprintFillOn.set('OPM', new PdfNumber(1));
    this.addExtGStateToRessources(overprintFillOn, 'OverprintFillOn');

    const overprintStrokingOff = new PdfExtGState();
    overprintStrokingOff.set('OP', new PdfBoolean(false));
    this.addExtGStateToRessources(overprintStrokingOff, 'OverprintStrokingOff');

    const overprintFillOff = new PdfExtGState();
    overprintFillOff.set('op', new PdfBoolean(false));
    this.addExtGStateToRessources(overprintFillOff, 'OverprintFillOff');

    const blendModeMultiply = new PdfExtGState();
    blendModeMultiply.set('BM', new PdfName('Multiply'));
    this.addExtGStateToRessources(blendModeMultiply, 'BlendModeMultiply');

    const blendModeNormal = new PdfExtGState();
    blendModeNormal.set('BM', new PdfName('Normal'));
    this.addExtGStateToRessources(blendModeNormal, 'BlendModeNormal');

    const overprintMode0 = new PdfExtGState();
    overprintMode0.set('OPM', new PdfNumber(0));
    this.addExtGStateToRessources(overprintMode0, 'OverprintMode0');

    const overprintMode1 = new PdfExtGState();
    overprintMode1.set('OPM', new PdfNumber(1));
    this.addExtGStateToRessources(overprintMode1, 'OverprintMode1');
  }

  toPdfStream(): PdfStream {
    return ContentStream.toPdfStream(this.operations);
  }

  static toPdfStream(operations: OperatorWithOperands[]): PdfStream {
    const compiler = new Compiler();
    const stream: Uint8Array[] = [];
    for (const operation of operations) {
      for (const operand of operation.operands) {
        stream.push(compiler.compilePrimitive(operand));
        stream.push(Compiler.fromString(' '));
      }
      stream.push(compiler.compilePrimitive(operation.operator));
      stream.push(Compiler.fromString('\n'));
    }

    const streamBytes = new Uint8Array(stream.reduce((acc, cur) => acc + cur.length, 0));
    let pointer = 0;
    for (const streamElement of stream) {
      streamBytes.set(streamElement, pointer);
      pointer += streamElement.length;
    }
    const streamDict = new PdfDict();
    streamDict.set('Length', new PdfNumber(streamBytes.length));
    const pdfStream = new PdfStream(streamDict, streamBytes);
    const streamText = streamBytes.reduce((acc, cur) => acc + String.fromCharCode(cur), '');
    return pdfStream;
  }

  getWithoutSpotColors(names: string[]): PdfStream {
    const newOperations = [...this.operations];
    let strokeInSeperationSpaceStack = [false];
    let fillInSeperationSpaceStack = [false];
    for (let i = 0; i < newOperations.length; i++) {
      const operation = newOperations[i].operator.valueOf();
      if (operation === 'CS') {
        const colorSpaceName = newOperations[i].operands[0] as PdfName;
        const colorSpace = this.getColorSpaceFromName(colorSpaceName);
        strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1] =
          colorSpace.type === 'Separation' && names.includes(colorSpace.name);
        if (strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1]) {
          const colorSpaceOperation = new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
            new PdfName('DeviceGray')
          ]);
          const colorOperation = new OperatorWithOperands(new PdfContentStreamOperator('SC'), [
            new PdfNumber(1)
          ]);
          newOperations.splice(i, 1, colorSpaceOperation, colorOperation);
          i++;
        }
      } else if (operation === 'G' || operation === 'RG' || operation === 'K') {
        strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1] = false;
      } else if (operation === 'SCN' || operation === 'SC') {
        if (strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1]) {
          newOperations.splice(i, 1);
          i--;
        }
      } else if (operation === 'cs') {
        const colorSpaceName = newOperations[i].operands[0] as PdfName;
        const colorSpace = this.getColorSpaceFromName(colorSpaceName);
        fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1] = colorSpace.type === 'Separation' && names.includes(colorSpace.name);
        if (fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1]) {
          const colorSpaceOperation = new OperatorWithOperands(new PdfContentStreamOperator('cs'), [
            new PdfName('DeviceGray')
          ]);
          const colorOperation = new OperatorWithOperands(new PdfContentStreamOperator('sc'), [
            new PdfNumber(1)
          ]);
          newOperations.splice(i, 1, colorSpaceOperation, colorOperation);
          i++;
        }
      } else if (operation === 'g' || operation === 'rg' || operation === 'k') {
        fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1] = false;
      } else if (operation === 'scn' || operation === 'sc') {
        if (fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1]) {
          newOperations.splice(i, 1);
          i--;
        }
      } else if (operation === 'Do') {
        const xobjectName = newOperations[i].operands[0] as PdfName;
        const xobjectRessources = this.ressources.get('XObject') as PdfDict;
        const xobjectStream = xobjectRessources.get(xobjectName.valueOf()) as PdfStream;
        if (xobjectStream.dict instanceof PdfImage) {
          const colorSpacePrimitive = xobjectStream.dict.get('ColorSpace') as
            | PdfName
            | PdfArray<PdfPrimitive>;
          let isInColorSpace = false;
          if (colorSpacePrimitive instanceof PdfName) {
            const colorSpace = this.getColorSpaceFromName(colorSpacePrimitive);
            isInColorSpace = colorSpace.type === 'Separation' && names.includes(colorSpace.name);
          } else if (colorSpacePrimitive instanceof PdfArray) {
            const colorSpaceType = colorSpacePrimitive[0].valueOf() as string;
            const colorSpaceName = colorSpacePrimitive[1].valueOf() as string;
            isInColorSpace = colorSpaceType === 'Separation' && names.includes(colorSpaceName);
          }
          if (isInColorSpace) {
            // remove from operations
            newOperations.splice(i, 1);
            i--;
          }
        }
      } else if (operation === 'q') {
        strokeInSeperationSpaceStack.push(strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1]);
        fillInSeperationSpaceStack.push(fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1]);
      } else if (operation === 'Q') {
        strokeInSeperationSpaceStack.pop();
        fillInSeperationSpaceStack.pop();
      }
    }
    // console.log("withoutSpots", newOperations.reduce((acc, cur) => acc + cur.operands.reduce((accOp, curOp) => accOp + curOp.toString() + ' ', '') + cur.operator + '\n', ''))
    return ContentStream.toPdfStream(newOperations);
  }

  getWithOnlySpotColor(spotColorName: string): PdfStream {
    const newOperations = [
      // set initial color to white to be sure we don't return a background color
      new OperatorWithOperands(new PdfContentStreamOperator('CS'), [new PdfName('DeviceGray')]),
      new OperatorWithOperands(new PdfContentStreamOperator('SC'), [new PdfNumber(1)]),
      new OperatorWithOperands(new PdfContentStreamOperator('cs'), [new PdfName('DeviceGray')]),
      new OperatorWithOperands(new PdfContentStreamOperator('sc'), [new PdfNumber(1)]),
      ...this.operations
    ];

    let strokeInSeperationSpaceStack = [false];
    let fillInSeperationSpaceStack = [false];
    for (let i = 4; i < newOperations.length; i++) {
      const operation = newOperations[i].operator.valueOf();
      if (operation === 'CS') {
        const colorSpaceName = newOperations[i].operands[0] as PdfName;
        const colorSpace = this.getColorSpaceFromName(colorSpaceName);
        strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1] = colorSpace.name === spotColorName;
        if (!strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1]) {
          const colorSpaceOperation = new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
            new PdfName('DeviceGray')
          ]);
          const colorOperation = new OperatorWithOperands(new PdfContentStreamOperator('SC'), [
            new PdfNumber(1)
          ]);
          newOperations.splice(i, 1, colorSpaceOperation, colorOperation);
          i++;
        }
      } else if (operation === 'G' || operation === 'RG' || operation === 'K') {
        strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1] = false;
        const colorSpaceOperation = new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
          new PdfName('DeviceGray')
        ]);
        const colorOperation = new OperatorWithOperands(new PdfContentStreamOperator('SC'), [
          new PdfNumber(1)
        ]);
        newOperations.splice(i, 1, colorSpaceOperation, colorOperation);
        i++;
      } else if (operation === 'cs') {
        const colorSpaceName = newOperations[i].operands[0] as PdfName;
        const colorSpace = this.getColorSpaceFromName(colorSpaceName);
        fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1] = colorSpace.name === spotColorName;
        if (!fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1]) {
          const colorSpaceOperation = new OperatorWithOperands(new PdfContentStreamOperator('cs'), [
            new PdfName('DeviceGray')
          ]);
          const colorOperation = new OperatorWithOperands(new PdfContentStreamOperator('sc'), [
            new PdfNumber(1)
          ]);
          newOperations.splice(i, 2, colorSpaceOperation, colorOperation);
          i++;
        }
      } else if (operation === 'g' || operation === 'rg' || operation === 'k') {
        fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1] = false;
        const colorSpaceOperation = new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
          new PdfName('DeviceGray')
        ]);
        const colorOperation = new OperatorWithOperands(new PdfContentStreamOperator('SC'), [
          new PdfNumber(1)
        ]);
        newOperations.splice(i, 1, colorSpaceOperation, colorOperation);
        i++;
      } else if (operation === 'SCN' || operation === 'SC') {
        if (!strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1]) {
          newOperations.splice(i, 1);
          i--;
        }
      } else if (operation === 'scn' || operation === 'sc') {
        if (!fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1]) {
          newOperations.splice(i, 1);
          i--;
        }
      } else if (operation === 'Do') {
        const xobjectName = newOperations[i].operands[0] as PdfName;
        const xobjectRessources = this.ressources.get('XObject') as PdfDict;
        const xobjectStream = xobjectRessources.get(xobjectName.valueOf()) as PdfStream;
        if (xobjectStream.dict instanceof PdfImage) {
          const colorSpace = xobjectStream.dict.get('ColorSpace') as
            | PdfName
            | PdfArray<PdfPrimitive>;
          let isInColorSpace = false;
          if (colorSpace instanceof PdfName) {
            const colorSpaceName = this.getColorSpaceFromName(colorSpace).name;
            isInColorSpace = colorSpaceName === spotColorName;
          } else if (colorSpace instanceof PdfArray) {
            const colorSpaceName = colorSpace[1];
            isInColorSpace = colorSpaceName === spotColorName;
          }
          if (!isInColorSpace) {
            // remove from operations
            newOperations.splice(i, 1);
            i--;
          }
        } else {
          // remove from operations
          newOperations.splice(i, 1);
          i--;
        }
      } else if (operation === 'sh') {
        newOperations.splice(i, 1);
        i--;
      } else if (operation === 'q') {
        strokeInSeperationSpaceStack.push(strokeInSeperationSpaceStack[strokeInSeperationSpaceStack.length - 1]);
        fillInSeperationSpaceStack.push(fillInSeperationSpaceStack[fillInSeperationSpaceStack.length - 1]);
      } else if (operation === 'Q') {
        strokeInSeperationSpaceStack.pop();
        fillInSeperationSpaceStack.pop();
      }
    }
    return ContentStream.toPdfStream(newOperations);
  }

  findIndicesForMarkedId(id: string): { startIndex: number; endIndex: number }[] {
    const needle = 'PrintWebId_' + id;
    console.log(needle)
    let indices = [];
    let latestStartIndex = -1;
    let latestEndIndex = -1;
    let emcToSkip = 0;
    for (let i = 0; i < this.operations.length; i++) {
      const operation = this.operations[i];
      const operator = operation.operator.valueOf();
      if (operator === 'BMC') {
        const markedContentName = operation.operands[0].valueOf() as string;
        if (latestStartIndex !== -1) {
          emcToSkip++;
        } else if (markedContentName === needle) {
          latestStartIndex = i;
        }
      } else if (operator === 'BDC') {
        if (latestStartIndex !== -1) {
          emcToSkip++;
        }
      } else if (operator === 'EMC') {
        if (latestStartIndex == -1) continue;
        if (emcToSkip > 0) {
          emcToSkip--;
        } else {
          latestEndIndex = i;
        }
      }
      if(latestStartIndex !== -1 && latestEndIndex !== -1) {
        indices.push({
          startIndex: latestStartIndex,
          endIndex: latestEndIndex
        })
        latestStartIndex = -1;
        latestEndIndex = -1;
      }
    }
    if(indices.length === 0) {
      throw new Error("ID not found");
    }
    return indices;
  }

  replaceFillColoringOperationsWithMp(startIndex: number, endIndex: number) {
    const coloringOperations = ['cs', 'sc', 'scn', 'g', 'rg', 'k'];
    this.operations = this.operations.map((op, index) => {
      if (index < startIndex || index > endIndex) return op;
      if (!coloringOperations.includes(op.operator.valueOf())) return op;
      const operation = op.operator.valueOf();
      const operands = op.operands;
      const tagName =
        'PrintWebOldOperation_' +
        operation.toUpperCase() +
        operands.map((op) => op.toString()).join('_');
      const mp = new OperatorWithOperands(new PdfContentStreamOperator('MP'), [
        new PdfName(tagName)
      ]);
      return mp;
    });
  }

  replaceStrokeColoringOperationsWithMp(startIndex: number, endIndex: number) {
    const coloringOperations = ['CS', 'SC', 'SCN', 'G', 'RG', 'K'];
    this.operations = this.operations.map((op, index) => {
      if (index < startIndex || index > endIndex) return op;
      if (!coloringOperations.includes(op.operator.valueOf())) return op;
      const operation = op.operator.valueOf();
      const operands = op.operands;
      const tagName =
        'PrintWebOldOperation_' +
        operation.toUpperCase() + '_' +
        operands.map((op) => op.toString()).join('_');
      const mp = new OperatorWithOperands(new PdfContentStreamOperator('MP'), [
        new PdfName(tagName)
      ]);
      return mp;
    });
  }

  replaceMpsWithOperations(startIndex: number, endIndex: number) {
    this.operations = this.operations.map((op, index) => {
      if (index < startIndex || index > endIndex) return op;
      if (op.operator.valueOf() !== 'MP') return op;
      const tagName = op.operands[0].valueOf() as string;
      if (tagName.substring(0, 18) !== 'PrintWebOldOperation_') return op;
      const parts = tagName.substring(18).split('_');
      const operation = parts[0].toLowerCase();
      const operands = parts.slice(1);
      const operator = new PdfContentStreamOperator(operation);
      const operandsParsed = operands.map((op) => {
        // try to parse as number
        const number = parseFloat(op);
        if (!isNaN(number)) {
          return new PdfNumber(number);
        }
        // try to parse as name
        const name = new PdfName(op);
        return name;
      });
      return new OperatorWithOperands(operator, operandsParsed);
    });
    // console.log(this.operations.reduce((acc, cur) => acc + cur.operands.reduce((accOp, curOp) => accOp + curOp.toString() + ' ', '') + cur.operator + '\n', ''));
    
  }

  removeMpTaggedOperations(startIndex: number, endIndex: number) {
    // remove all operations between start and end index that directly follow a PrintWebInsertedOperation
    let numberOfRemovedOperations = 0;
    for (let i = startIndex; i < endIndex; i++) {
      const operation = this.operations[i];
      if (operation.operator.valueOf() === 'MP') {
        const tagName = operation.operands[0].valueOf() as string;
        if (tagName.startsWith('PrintWebInsertedOperation')) {
          this.operations.splice(i, 2);
          i--;
          numberOfRemovedOperations += 2;
        }
      }
    }
  }

  tagOperationsWithMp(operations: OperatorWithOperands[]) {
    const mp = new OperatorWithOperands(new PdfContentStreamOperator('MP'), [
      new PdfName('PrintWebInsertedOperation')
    ]);
    return operations.flatMap((op) => {
      return [mp, op];
    });
  }

  /**
   * Removes all duplicates of the element and
   * removes all effects that were applied to this element
   */
  resetElement(targetId: string) {
    const indices = this.findIndicesForMarkedId(targetId);
    if(indices.length > 1) {
      for (let i = 1; i < indices.length; i++) {
        const startIndex = indices[i].startIndex;
        const endIndex = indices[i].endIndex;
        const numberOfElements = endIndex - startIndex + 1;
        this.operations.splice(startIndex, numberOfElements);
      }
    }
    const startIndex = indices[0].startIndex;
    const endIndex = indices[0].endIndex;
    this.replaceMpsWithOperations(startIndex, endIndex);
    this.removeMpTaggedOperations(startIndex, endIndex);
  }

  /**
   * Duplicates the first element with the ID, and puts it behind the last occurence of that ID.
   */
  duplicateElement(targetId: string) {
    const indices = this.findIndicesForMarkedId(targetId);
    const firstOccurence = indices[0];
    const lastOccurence = indices[indices.length - 1];
    
    const newOperations = this.operations.slice(firstOccurence.startIndex, firstOccurence.endIndex + 1);
    this.operations.splice(lastOccurence.endIndex + 1, 0, ...newOperations);
  }

  /**
   * Set's the linewidth of the last element with the given ID
   */
  setElementLineWidth(targetID: string, lineWidth: number) {
    const indices = this.findIndicesForMarkedId(targetID);
    const { startIndex, endIndex } = indices[indices.length - 1];
    const setLineWidthOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('w'), [new PdfNumber(lineWidth)]),
    ]);
    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(startIndex, 0, ...setLineWidthOperations);
    this.operations.splice(endIndex + setLineWidthOperations.length, 0, ...resetOperations);
  }

  /**
   * Set's the fill color of the last element with the given ID
   */
  setElementFillColor(targetId: string, color: SpotColorFragment, intensityOutOf1: number) {
    const indices = this.findIndicesForMarkedId(targetId);
    const { startIndex, endIndex } = indices[indices.length - 1];

    this.replaceFillColoringOperationsWithMp(startIndex, endIndex);

    const ressourceIdentifier = color ? this.getAllColorSpaces().find(
      (cs) => cs.name === color.technical_name
    )?.ressourceIdentifier : null;
    if (!ressourceIdentifier) {
      throw new Error('Colorspace not found');
    }
    const setColorOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('cs'), [
        new PdfName(ressourceIdentifier)
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('scn'), [new PdfNumber(intensityOutOf1)])
    ]);
    this.operations.splice(startIndex + 1, 0, ...setColorOperations);

    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(endIndex + setColorOperations.length, 0, ...resetOperations);
  }

  /**
   * Set's the stroke color of the element with the given ID and index
   */
  setElementStrokeColor(targetId: string, color: SpotColorFragment, intensityOutOf1: number) {
    const indices = this.findIndicesForMarkedId(targetId);
    const { startIndex, endIndex } = indices[indices.length - 1];

    this.replaceStrokeColoringOperationsWithMp(startIndex, endIndex);

    const ressourceIdentifier = color ? this.getAllColorSpaces().find(
      (cs) => cs.name === color.technical_name
    )?.ressourceIdentifier : null;
    if (!ressourceIdentifier) {
      throw new Error('Colorspace not found');
    }
    const setColorOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('CS'), [
        new PdfName(ressourceIdentifier)
      ]),
      new OperatorWithOperands(new PdfContentStreamOperator('SCN'), [new PdfNumber(intensityOutOf1)])
    ]);
    this.operations.splice(startIndex + 1, 0, ...setColorOperations);

    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(endIndex + setColorOperations.length, 0, ...resetOperations);
  }

  /**
   * Set's the blend mode to multiply or not for the last element with the given ID
   */
  setElementMultiply(targetId: string, multiply: boolean) {
    const indices = this.findIndicesForMarkedId(targetId);
    const { startIndex, endIndex } = indices[indices.length - 1];

    const setMultiplyOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(multiply ? 'BlendModeMultiply' : 'BlendModeNormal')
      ])
    ]);
    this.operations.splice(startIndex + 1, 0, ...setMultiplyOperations);

    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(endIndex + setMultiplyOperations.length, 0, ...resetOperations);
  }

  /**
   * Set's the fill overprint to true or false for the last element with the given ID
   */
  setElementFillOverprint(targetId: string, overprint: boolean) {
    const indices = this.findIndicesForMarkedId(targetId);
    const { startIndex, endIndex } = indices[indices.length - 1];

    const setOverprintOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(overprint ? 'OverprintFillOn' : 'OverprintFillOff')
      ])
    ]);
    this.operations.splice(startIndex + 1, 0, ...setOverprintOperations);

    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(endIndex + setOverprintOperations.length, 0, ...resetOperations);
  }

  /**
   * Set's the stroke overprint to true or false for the last element with the given ID
   */
  setElementStrokeOverprint(targetId: string, overprint: boolean) {
    const indices = this.findIndicesForMarkedId(targetId);
    const { startIndex, endIndex } = indices[indices.length - 1];

    const setOverprintOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(overprint ? 'OverprintStrokingOn' : 'OverprintStrokingOff')
      ])
    ]);
    this.operations.splice(startIndex + 1, 0, ...setOverprintOperations);

    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(endIndex + setOverprintOperations.length, 0, ...resetOperations);
  }

  setElementOverprintMode(targetId: string, overprintMode: boolean) {
    const indices = this.findIndicesForMarkedId(targetId);
    const { startIndex, endIndex } = indices[indices.length - 1];

    const setOverprintOperations = this.tagOperationsWithMp([
      new OperatorWithOperands(new PdfContentStreamOperator('q'), []),
      new OperatorWithOperands(new PdfContentStreamOperator('gs'), [
        new PdfName(overprintMode ? 'OverprintMode1' : 'OverprintMode0')
      ])
    ]);
    this.operations.splice(startIndex + 1, 0, ...setOverprintOperations);

    const resetOperations = this.tagOperationsWithMp([new OperatorWithOperands(new PdfContentStreamOperator('Q'), [])]);
    this.operations.splice(endIndex + setOverprintOperations.length, 0, ...resetOperations);
  }
}

// [a b 0]   [a b 0]   [aa+cb ab+db 0]
// [c d 0] * [c d 0] = [ac+ce bd+df 0]
// [e f 1]   [e f 1]   [ae+cf be+df 1]
const multiplyMatrices = (A: number[], B: number[]): number[] => {
  return [
    A[0] * B[0] + A[2] * B[1],
    A[1] * B[0] + A[3] * B[1],
    A[0] * B[2] + A[2] * B[3],
    A[1] * B[2] + A[3] * B[3],
    A[0] * B[4] + A[2] * B[5] + A[4],
    A[1] * B[4] + A[3] * B[5] + A[5]
  ];
};