export function formatDate(date: Date): string {
  return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale).format(date);
}

export function isValidUrl(url: string): boolean {
  return true;
  // TODO: Fix this regex to work with e.g. http://localhost:5173
  // try {
  //   const regex = new RegExp(
  //     '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})'
  //   );
  //   const match = !!url.match(regex);
  //   if (match) {
  //     return true;
  //   }
  //   return false;
  // } catch {
  //   return false;
  // }
}

export function getBase64Image(hexImage: string) {
  return `data:image;base64,${hexToBase64(hexImage)}`;
}

// Convert hex to base64 for displaying in <img> tag
export const hexToBase64 = (hexInput: string): string =>
  btoa(
    (hexInput.replace('\\x', '').match(/\w{2}/g) ?? [])
      .map((a: string) => String.fromCharCode(parseInt(a, 16)))
      .join('')
  );

// Convert File blob to hexstring before upload to postgres
export async function hexDump(file: Blob) {
  // gotten from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#Example
  function hex(buffer: ArrayBuffer) {
    return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, '0')).join('');
  }

  return new Promise<string>((res) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result !== 'string' && reader.result) {
        res(hex(reader.result));
      }
    };
    reader.readAsArrayBuffer(file);
  });
}
