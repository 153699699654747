import Compiler from './Compiler';
import PdfString from '../pdfPrimitives/PdfString';
import PdfContentStreamOperator from '../pdfPrimitives/PdfContentStreamOperator';

export default function compile(
  operator: PdfContentStreamOperator,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  return Compiler.fromString(operator.toString());
}
