import { graphql } from '@/gql_gen';

// INSTANCE MUTATIONS
export const DELETE_INSTANCE_MUTATION = graphql(`
  mutation DeleteInstance($id: uuid = "") {
    delete_printweb_instance_by_pk(id: $id) {
      id
    }
  }
`);

export const INSERT_INSTANCE_MUTATION = graphql(`
  mutation InsertInstance($objects: [printweb_instance_insert_input!] = []) {
    insert_printweb_instance(objects: $objects) {
      returning {
        id
      }
    }
  }
`);

export const UPDATE_INSTANCE_MUTATION = graphql(`
  mutation UpdateInstance(
    $id: uuid = ""
    $name: String = ""
    $header_logo: bytea = ""
    $allowed_url: String = ""
  ) {
    update_printweb_instance(
      where: { id: { _eq: $id } }
      _set: { allowed_url: $allowed_url, header_logo: $header_logo, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`);

export const DELETE_INSTANCE_MATERIALS = graphql(`
  mutation DeleteInstanceMaterials($instance_id: uuid = "") {
    delete_printweb_instance_background_material(where: { instance_id: { _eq: $instance_id } }) {
      returning {
        instance_id
        background_material_id
      }
    }
  }
`);

export const DELETE_INSTANCE_COLORS = graphql(`
  mutation DeleteInstanceColors($instance_id: uuid = "") {
    delete_printweb_instance_spot_color(where: { instance_id: { _eq: $instance_id } }) {
      returning {
        instance_id
        spot_color_id
      }
    }
  }
`);

// SPOT COLOR MUTATIONS
export const DELETE_SPOT_COLOR_MUTATION = graphql(`
  mutation DeleteSpotColor($id: uuid = "") {
    delete_printweb_spot_color_by_pk(id: $id) {
      id
    }
  }
`);

export const INSERT_SPOT_COLOR_MUTATION = graphql(`
  mutation InsertSpotColor($updateFields: printweb_spot_color_insert_input!) {
    insert_printweb_spot_color_one(object: $updateFields) {
      id
    }
  }
`);

export const UPDATE_SPOT_COLOR_MUTATION = graphql(`
  mutation UpdateSpotColor($id: uuid = "", $_set: printweb_spot_color_set_input = {}) {
    update_printweb_spot_color_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`);

// Material Background Mutations
export const DELETE_BACKGROUND_MATERIAL_MUTATION = graphql(`
  mutation DeleteBackgroundMaterial($id: uuid = "") {
    delete_printweb_background_material_by_pk(id: $id) {
      id
    }
  }
`);

export const INSERT_BACKGROUND_MATERIAL_MUTATION = graphql(`
  mutation InsertBACKGROUND_MATERIAL($updateFields: printweb_background_material_insert_input!) {
    insert_printweb_background_material_one(object: $updateFields) {
      id
    }
  }
`);

export const UPDATE_BACKGROUND_MATERIAL_MUTATION = graphql(`
  mutation UpdateMaterialBackground(
    $id: uuid = ""
    $_set: printweb_background_material_set_input = {}
  ) {
    update_printweb_background_material_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`);

export const INSERT_INSTANCE_BACKGROUND_MATERIAL_MUTATION = graphql(`
  mutation InsertInstanceMaterialBackground(
    $objects: [printweb_instance_background_material_insert_input!] = {}
  ) {
    insert_printweb_instance_background_material(objects: $objects) {
      returning {
        background_material_id
        instance_id
      }
      affected_rows
    }
  }
`);

export const INSERT_INSTANCE_SPOT_COLOR_MUTATION = graphql(`
  mutation InsertInstanceSpotColor($objects: [printweb_instance_spot_color_insert_input!] = {}) {
    insert_printweb_instance_spot_color(objects: $objects) {
      returning {
        instance_id
        spot_color_id
      }
      affected_rows
    }
  }
`);

export const INSERT_MATERIAL_LABEL = graphql(`
  mutation InsertMaterialLabel($updateFields: printweb_material_label_insert_input!) {
    insert_printweb_material_label_one(object: $updateFields) {
      id
    }
  }
`);

export const UPDATE_MATERIAL_LABEL_MUTATION = graphql(`
  mutation UpdateMaterialLabel($id: uuid = "", $_set: printweb_material_label_set_input = {}) {
    update_printweb_material_label_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`);

export const DELETE_MATERIAL_LABEL_MUTATION = graphql(`
  mutation DeleteMaterialLabel($id: uuid = "") {
    delete_printweb_material_label_by_pk(id: $id) {
      id
    }
  }
`);

export const INSERT_MATERIAL_GROUP = graphql(`
  mutation InsertMaterialGroup($updateFields: printweb_material_group_insert_input!) {
    insert_printweb_material_group_one(object: $updateFields) {
      id
    }
  }
`);

export const UPDATE_MATERIAL_GROUP_MUTATION = graphql(`
  mutation UpdateMaterialGroup($id: uuid = "", $_set: printweb_material_group_set_input = {}) {
    update_printweb_material_group_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`);

export const DELETE_MATERIAL_GROUP_MUTATION = graphql(`
  mutation DeleteMaterialGroup($id: uuid = "") {
    delete_printweb_material_group_by_pk(id: $id) {
      id
    }
  }
`);

export const DELETE_BACKGROUND_MATERIAL_GROUPS = graphql(`
  mutation DeleteBackgroundMaterialGroups($materialId: uuid = "") {
    delete_printweb_background_material_group(
      where: { background_material_id: { _eq: $materialId } }
    ) {
      returning {
        background_material_id
        material_group_id
      }
    }
  }
`);

export const INSERT_BACKGROUND_MATERIAL_GROUPS = graphql(`
  mutation InsertBackgroundMaterialGroups(
    $objects: [printweb_background_material_group_insert_input!] = {}
  ) {
    insert_printweb_background_material_group(objects: $objects) {
      returning {
        background_material_id
        material_group_id
      }
    }
  }
`);

export const DELETE_COMBINABLE_COLORS = graphql(`
  mutation DeleteCombinableColors($spotColorId: uuid = "") {
    delete_printweb_combinable_spot_colors(where: { spot_color_id: { _eq: $spotColorId } }) {
      returning {
        combinable_spot_color_id
        spot_color_id
      }
    }
  }
`);

export const INSERT_COMBINABLE_COLORS = graphql(`
  mutation InsertCombinableColors($objects: [printweb_combinable_spot_colors_insert_input!] = {}) {
    insert_printweb_combinable_spot_colors(objects: $objects) {
      returning {
        combinable_spot_color_id
        spot_color_id
      }
      affected_rows
    }
  }
`);
