<template>
  <div class="canvas3d-container" ref="container">
    <div class="floating-button-col">
      <Button class="icon-button" @click="resetCameraPosition">
        <template #icon>
          <span class="material-symbols-outlined mr-0">home</span>
        </template>
      </Button>
      <Button class="icon-button" style="margin-top: 5px" @click="zoomPlus">
        <template #icon>
          <span class="material-symbols-outlined mr-0">zoom_in</span>
        </template></Button>
      <Button class="icon-button" style="margin-top: 5px" @click="zoomMinus">
        <template #icon>
          <span class="material-symbols-outlined mr-0">zoom_out</span>
        </template>
      </Button>
    </div>
    <canvas ref="renderCanvas" class="three-d-render-canvas" />
  </div>
</template>
<script setup lang="ts">
import { createDefaultEngine, createScene, startRenderLoop } from '@/helpers/3DRenderer';
import { ArcRotateCamera, Color3, Mesh, MeshBuilder, StandardMaterial, Texture, Vector3, type Engine, type Scene } from '@babylonjs/core';
import Button from 'primevue/button';
import { ref, onMounted, watch } from 'vue';

const props = defineProps<{
  frontPage: HTMLCanvasElement;
  backPage: HTMLCanvasElement;
  duplex: boolean;
}>();

const renderCanvas = ref<HTMLCanvasElement>();
const container = ref<HTMLDivElement>();
const engineRef = ref<Engine>();
const scene = ref<Scene>();
const setUp3dEngine = () => {
  if (engineRef.value) {
    engineRef.value.dispose();
  }
  engineRef.value = createDefaultEngine(renderCanvas.value!);
  console.log('Babylon JS engine created');
  scene.value = createScene(
    engineRef.value,
    renderCanvas.value
  );
  console.log('Scene created');
  startRenderLoop(engineRef.value, scene.value);
  console.log('rendering loop created');
};

const load3dModel = () => {
  console.log('loading 3d model');
  if (!scene.value) return;
  const planeHeight = 5;
  const ratio = props.frontPage.width / props.frontPage.height;
  const planeWidth = planeHeight * ratio;

  for(let i = 0; i < scene.value.meshes.length; i++) {
    const mesh = scene.value.meshes[i];
    if(['frontBase', 'frontSpot', 'backBase', 'backSpot'].includes(mesh.name)) {
      mesh.dispose(false, true);
      i--;
    }
  }

  const frontBase = MeshBuilder.CreatePlane(
    'frontBase',
    {
      width: planeWidth,
      height: planeHeight,
      sideOrientation: Mesh.FRONTSIDE
    },
    scene.value
  );
  const frontMaterial = new StandardMaterial('frontMaterial', scene.value);
  const frontBaseTexture = new Texture(props.frontPage.toDataURL(), scene.value);
  frontMaterial.diffuseTexture = frontBaseTexture;
  frontMaterial.specularColor = new Color3(0, 0, 0);
  frontBase.material = frontMaterial;

  const backBase = MeshBuilder.CreatePlane(
    'backBase',
    {
      width: planeWidth,
      height: planeHeight,
      sideOrientation: Mesh.BACKSIDE
    },
    scene.value
  );
  let backMaterial;

  if (props.duplex) {
    backMaterial = new StandardMaterial('backMaterial', scene.value);
    const backBaseTexture = new Texture(props.backPage.toDataURL(), scene.value);
    backBaseTexture.uScale = -1;
    backMaterial.diffuseTexture = backBaseTexture;
    backMaterial.specularColor = new Color3(0, 0, 0);
  } else {
    // plain white background
    backMaterial = new StandardMaterial('backMaterial', scene.value);
    backMaterial.diffuseColor = new Color3(1, 1, 1);
    backMaterial.specularColor = new Color3(0, 0, 0);
  }
  backBase.material = backMaterial;

  console.log(scene.value);
};

const zoomPlus = () => {
  if (!scene.value) return;
  (scene.value.activeCamera! as ArcRotateCamera).radius -= 1;
};

const zoomMinus = () => {
  if (!scene.value) return;
  (scene.value.activeCamera! as ArcRotateCamera).radius += 1;
};

const resetCameraPosition = () => {
  if(!scene.value) return;
  const camera = scene.value.activeCamera as ArcRotateCamera;
  camera.target = Vector3.Zero();
  camera.alpha = (3 * Math.PI) / 2;
  camera.beta = Math.PI / 2;
  camera.radius = 10;
};

const debounce = (func: () => void, delay: number) => {
  let lastTriggerAt = 0;
  let timeoutForFinalRun: ReturnType<typeof setTimeout>;
  return () => {
    const now = Date.now();
    if (now - lastTriggerAt > delay) {
      clearTimeout(timeoutForFinalRun);
      lastTriggerAt = now;
      func();
    } else {
      clearTimeout(timeoutForFinalRun);
      timeoutForFinalRun = setTimeout(() => {
        lastTriggerAt = now;
        func();
      }, delay);
    }
  };
};

const setCanvasSizeToParent = () => {
  if (renderCanvas.value) {
    renderCanvas.value.width = container.value!.clientWidth;
    renderCanvas.value.height = container.value!.clientHeight;
  }
};

watch(
  () => [props.frontPage, props.backPage, props.duplex],
  debounce(load3dModel, 100),
);

onMounted(() => {
  setUp3dEngine();
  load3dModel();
  new ResizeObserver(debounce(setCanvasSizeToParent, 100)).observe(renderCanvas.value!);
});
</script>
<style lang="scss" scoped>
.canvas3d-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  max-height: 100%;
  overflow: hidden;
}

.three-d-render-canvas {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  aspect-ratio: auto;
}
</style>