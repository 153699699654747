export default function decode(input: Uint8Array): Uint8Array {
  const encoded = String.fromCharCode(...input);

  let padding = encoded.endsWith('~>') ? 2 : 0;
  const sanitized = encoded.replace(/[^A-Za-z0-9!#$%&()*+-;<=>?@^_`{|}~z]/g, '').replace('~>', '');

  let chunks = Math.ceil(sanitized.length / 5);
  let decodedBytes = new Uint8Array(chunks * 4);

  for (let c = 0; c < chunks; c++) {
    let chunk = sanitized.substr(c * 5, 5);
    let decodedChunk = decodeChunk(chunk);

    for (let b = 0; b < 4; b++) {
      decodedBytes[c * 4 + 3 - b] = decodedChunk & 255;
      decodedChunk >>= 8;
    }
  }

  return decodedBytes.subarray(0, decodedBytes.length - padding);
}

const DECODING_TABLE: number[] = (() => {
  let table = new Array(256).fill(-1);
  let chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%&()*+-;<=>?@^_`{|}~';
  for (let i = 0; i < chars.length; i++) {
    table[chars.charCodeAt(i)] = i;
  }
  return table;
})();

function decodeChunk(chunk: string): number {
  if (chunk === 'z') return new Uint32Array([0])[0];

  let decoded = 0;
  for (let i = 0; i < chunk.length; i++) {
    let charCode = chunk.charCodeAt(i);
    if (charCode < 0 || charCode >= DECODING_TABLE.length || DECODING_TABLE[charCode] === -1) {
      throw new Error('Invalid Ascii85 character');
    }
    decoded = decoded * 85 + DECODING_TABLE[charCode];
  }
  return decoded;
}
