// Hint: Fragments like "...Instance" are defined in ./fragments.ts
import { graphql } from '../gql_gen';
export const FETCH_INSTANCES_SUBSCRIPTION = graphql(`
  subscription fetchInstances {
    printweb_instance(order_by: { created_at: asc }) {
      ...Instance
      background_materials {
        background_material {
          ...BackgroundMaterial
        }
      }
      spot_colors {
        spot_color {
          ...SpotColor
        }
      }
    }
  }
`);

export const FETCH_SPOT_COLORS_SUBSCRIPTION = graphql(`
  subscription fetchSpotColors {
    printweb_spot_color(order_by: { created_at: asc }) {
      ...SpotColor
      combinable_colors {
        spot_color {
          ...SpotColor
        }
      }
    }
  }
`);

export const FETCH_BACKGROUND_MATERIALS_SUBSCRIPTION = graphql(`
  subscription fetchBackgroundMaterials {
    printweb_background_material(
      order_by: [{ material_label: { name: asc } }, { created_at: asc }]
    ) {
      ...BackgroundMaterial
      material_label {
        id
        name
      }
      background_material_groups {
        material_group {
          id
          name
        }
      }
    }
  }
`);

export const FETCH_MATERIAL_LABELS_SUBSCRIPTION = graphql(`
  subscription subscribeMaterialLabels {
    printweb_material_label(order_by: { created_at: asc }) {
      ...MaterialLabel
      background_materials {
        ...BackgroundMaterial
      }
    }
  }
`);

export const FETCH_MATERIAL_GROUPS_SUBSCRIPTION = graphql(`
  subscription subscribeMaterialGroups {
    printweb_material_group(order_by: { created_at: asc }) {
      ...MaterialGroup
    }
  }
`);

export const FETCH_MATERIAL_GROUPS_AND_MATERIALS = graphql(`
  subscription subscribeMaterialGroupsAndMaterials {
    printweb_material_group(order_by: { created_at: asc }) {
      ...MaterialGroup
      material_group_background_materials {
        background_material {
          ...BackgroundMaterial
        }
      }
    }
  }
`);
