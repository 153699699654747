import { Compiler } from '../Compiler';
import { RawPdfDocument } from '../RawPdfDocument';
import PdfIndirectObjectReference from './PdfIndirectObjectReference';
import PdfNumber from './PdfNumber';
import { PdfPrimitive } from './PdfPrimitive';

export default class PdfIndirectObject {
  constructor(
    public id: PdfNumber,
    public generation: PdfNumber,
    public element: PdfPrimitive
  ) {}

  toReference(): PdfIndirectObjectReference {
    return new PdfIndirectObjectReference(this.id, this.generation);
  }

  toString(): string {
    return `${this.id} ${this.generation} obj`;
  }
}
