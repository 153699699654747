import Compiler from './Compiler';
import PdfStream from '../pdfPrimitives/PdfStream';

export default function compile(
  stream: PdfStream,
  compiler: Compiler,
  cameFromIndirectObject: boolean,
  ...compilationArgs: any[]
): Uint8Array {
  if (!cameFromIndirectObject) {
    const reference = compiler.addIndirectObject(stream, true, ...compilationArgs);
    return compiler.compilePrimitive(reference, ...compilationArgs);
  }
  const dictBytes = compiler.compilePrimitive(stream.dict);
  const streamBytes = stream.value;
  const bytes = new Uint8Array(dictBytes.length + streamBytes.length + 18);
  let pointer = 0;
  bytes.set(dictBytes, pointer);
  pointer += dictBytes.length;
  bytes.set(Compiler.fromString('\nstream\n'), pointer);
  pointer += 8;
  bytes.set(streamBytes, pointer);
  pointer += streamBytes.length;
  bytes.set(Compiler.fromString('\nendstream'), pointer);
  return bytes;
}
