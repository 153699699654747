import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfDict, {
  PdfCatalog,
  PdfEncoding,
  PdfExtGState,
  PdfFont,
  PdfForm,
  PdfFunction,
  PdfImage,
  PdfPage,
  PdfPages,
  PdfStructElem,
  PdfXObject
} from '../pdfPrimitives/PdfDict';
import { type PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import NameParser from './NameParser';
import type Parser from './Parser';

export default function parseDict(
  document: ReadablePdfStream,
  parser: Parser,
  resolveIndirect: boolean,
  calledByStreamConstructor: boolean = false
): PdfDict {
  const pointerBefore = document.pointer;
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(2);
  if (nextString !== '<<') {
    throw new NotTheRightPdfElementError(`Expected '<<' but got '${nextString}'`);
  }

  document.read(charactersRead); // <<
  const dict: Map<string, PdfPrimitive> = new Map();
  while (document.peekStringIgnoringLeadingWhitespace(2)[0] !== '>>') {
    const key = NameParser(document).valueOf();
    let value: PdfPrimitive;
    if (key === 'Parent' || key === 'P') {
      value = parser.parse(document, false);
    } else {
      value = parser.parse(document, resolveIndirect);
    }
    dict.set(key, value);
  }
  document.readStringIgnoringLeadingWhitespace(2); // >>
  if (document.peekStringIgnoringLeadingWhitespace(6)[0] === 'stream') {
    if (!calledByStreamConstructor) {
      document.pointer = pointerBefore;
      throw new NotTheRightPdfElementError('Dict is part of stream');
    }
  }
  if (dict.has('Type')) {
    if (dict.has('Subtype')) {
      const specialSubclass = PDF_SPECIAL_SUBTYPES[dict.get('Subtype')!.valueOf() as string];
      if (specialSubclass) {
        return new specialSubclass(dict);
      }
    }
    const specialClass = PDF_SPECIAL_TYPES[dict.get('Type')!.valueOf() as string];
    if (specialClass) {
      return new specialClass(dict);
    }
  }
  if (dict.has('FunctionType')) {
    return new PdfFunction(dict);
  }
  return new PdfDict(dict);
}

const PDF_SPECIAL_TYPES: any = {
  Catalog: PdfCatalog,
  Page: PdfPage,
  Pages: PdfPages,
  XObject: PdfXObject,
  Font: PdfFont,
  Encoding: PdfEncoding,
  StructElem: PdfStructElem,
  ExtGState: PdfExtGState
};

const PDF_SPECIAL_SUBTYPES: any = {
  Image: PdfImage,
  Form: PdfForm
};
