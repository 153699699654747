// stores/counter.js
import { defineStore } from 'pinia';
import { useSubscription } from '@vue/apollo-composable';

import { watch } from 'vue';
import { apollo } from '@/graphql/apollo';
import type {
  MaterialGroupFragment,
  Printweb_Material_Label_Insert_Input,
  SubscribeMaterialGroupsSubscription
} from '@/gql_gen/graphql';
import {
  FETCH_MATERIAL_GROUPS_AND_MATERIALS,
  FETCH_MATERIAL_GROUPS_SUBSCRIPTION
} from '@/graphql/subscriptions';
import {
  DELETE_MATERIAL_GROUP_MUTATION,
  INSERT_MATERIAL_GROUP,
  UPDATE_MATERIAL_GROUP_MUTATION
} from '@/graphql/mutations';

type MaterialGroupState = {
  groups: MaterialGroupFragment[];
  groupsAndMaterials: SubscribeMaterialGroupsSubscription['printweb_material_group'];
  isLoading: boolean;
  totalCount: number;
};
export const useMaterialGroupStore = defineStore('materialGroupAdmin', {
  state: (): MaterialGroupState => {
    return {
      groups: [],
      groupsAndMaterials: [],
      isLoading: true, // -- initially set to true until subscription completes the first time
      totalCount: 0
    };
  },
  getters: {
    getGroupById: (state) => {
      return (groupId: string) => state.groups.find((group) => group.id === groupId);
    }
  },
  actions: {
    async subscribeMaterialGroups() {
      const { result, loading, error } = useSubscription(FETCH_MATERIAL_GROUPS_SUBSCRIPTION);
      watch(result, (data) => {
        this.groups = data?.printweb_material_group ?? [];
      });

      watch(loading, (loading) => {
        this.isLoading = loading;
        console.log('labels Loading', loading);
      });

      watch(error, (error) => {
        console.error('error', error);
      });
    },
    async subscribeMaterialGroupsAndMaterials() {
      const { result, loading, error } = useSubscription(FETCH_MATERIAL_GROUPS_AND_MATERIALS);
      watch(result, (data) => {
        this.groupsAndMaterials = data?.printweb_material_group ?? [];
      });

      watch(loading, (loading) => {
        this.isLoading = loading;
        console.log('labels Loading', loading);
      });

      watch(error, (error) => {
        console.error('error', error);
      });
    },
    async createMaterialGroup(data: Printweb_Material_Label_Insert_Input) {
      await apollo.query({
        query: INSERT_MATERIAL_GROUP,
        variables: {
          updateFields: data
        }
      });
    },
    async updateMaterialGroup(data: MaterialGroupFragment) {
      const { id, ...dataWithoutId } = data;
      await apollo.query({
        query: UPDATE_MATERIAL_GROUP_MUTATION,
        variables: {
          id: data.id,
          _set: dataWithoutId
        }
      });
    },
    async deleteMaterialGroup(id: string) {
      await apollo.query({
        query: DELETE_MATERIAL_GROUP_MUTATION,
        variables: {
          id: id
        }
      });
    }
  }
});
