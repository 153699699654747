import { PdfArray, PdfName, PdfNumber, PdfPrimitive, PdfStream, SvgPathElement } from '../..';
import ColorSpace from './ColorSpace';

export default class ImageElement {
  width: number;
  height: number;
  url: string;
  colorSpace: string;
  paintIndex: number = 0;

  constructor(pdfStream: PdfStream) {
    this.width = (pdfStream.dict.get('Width') as PdfNumber).valueOf() ?? 0;
    this.height = (pdfStream.dict.get('Height') as PdfNumber).valueOf() ?? 0;
    this.colorSpace = new ColorSpace(
      pdfStream.dict.get('ColorSpace') as PdfName | PdfArray<PdfPrimitive>
    ).name;
    const blob = new Blob([pdfStream.value], { type: 'image/jpeg' });
    this.url = URL.createObjectURL(blob);
  }
}
