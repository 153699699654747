import {
  ArcRotateCamera,
  BackgroundMaterial,
  Color3,
  CubeTexture,
  DynamicTexture,
  Engine,
  HemisphericLight,
  Mesh,
  MeshBuilder,
  PointLight,
  Scene,
  StandardMaterial,
  Texture,
  Vector3,
  Vector4
} from '@babylonjs/core';
import { type Ref } from 'vue';
import leftImage from '../assets/textures/space/textures_skybox_left.png';
import upImage from '../assets/textures/space/textures_skybox_up.png';
import backImage from '../assets/textures/space/textures_skybox_back.png';
import downImage from '../assets/textures/space/textures_skybox_down.png';
import rightImage from '../assets/textures/space/textures_skybox_right.png';
import frontImage from '../assets/textures/space/textures_skybox_front.png';

export const createDefaultEngine = (renderCanvas: HTMLCanvasElement): Engine => {
  return new Engine(renderCanvas, true, {
    preserveDrawingBuffer: true,
    stencil: true,
    disableWebGL2Support: false
  });
};

export const startRenderLoop = function (engine: Engine, sceneToRender: Scene): void {
  engine.runRenderLoop(function () {
    if (sceneToRender && sceneToRender.activeCamera) {
      engine.resize();
      sceneToRender.render();
    }
  });
};

export const update3dPdfFromCanvas = (
  canvasFront: HTMLCanvasElement,
  spotCanvasFront: HTMLCanvasElement,
  canvasBack: HTMLCanvasElement | null,
  spotCanvasBack: HTMLCanvasElement | null,
  backgroundTexture: ImageData | null,
  scene: Scene
): void => {
  const planeHeight = 5;
  const ration = canvasFront.width / canvasFront.height;
  const planeWidth = planeHeight * ration;
  if(canvasBack && (canvasFront.width !== canvasBack.width || canvasFront.height !== canvasBack.height)) {
    throw new Error('Canvas front and back have different sizes');
  }
  console.log("Seeting up 3D PDF")

  // remove the old meshes
  scene.meshes.forEach((mesh) => {
    if (mesh.name === 'frontBase' || mesh.name === 'frontSpot' || mesh.name === 'backBase' || mesh.name === 'backSpot') {
      mesh.dispose();
    }
  });
  // remove the old materials
  scene.materials?.forEach((material) => {
    if (material.name === 'frontMaterial' || material.name === 'frontSpotMaterial' || material.name === 'backMaterial' || material.name === 'backSpotMaterial') {
      material.dispose();
    }
  });

  const frontBase = MeshBuilder.CreatePlane(
    'frontBase',
    {
      width: planeWidth,
      height: planeHeight,
      sideOrientation: Mesh.FRONTSIDE
    },
    scene
  );
  const frontMaterial = new StandardMaterial('frontMaterial', scene);
  const frontBaseTexture = new Texture(canvasFront.toDataURL(), scene);
  frontMaterial.diffuseTexture = frontBaseTexture;
  frontMaterial.specularColor = new Color3(0, 0, 0);
  frontBase.material = frontMaterial;
  
  if(spotCanvasFront) {
    const frontSpot = MeshBuilder.CreatePlane(
      'frontSpot',
      {
        width: planeWidth,
        height: planeHeight,
        sideOrientation: Mesh.FRONTSIDE
      },
      scene
    );
    const frontSpotMaterial = new StandardMaterial('frontSpotMaterial', scene);
    const frontSpotTexture = new Texture(spotCanvasFront.toDataURL(), scene);
    frontSpotMaterial.diffuseTexture = frontSpotTexture;
    frontSpotMaterial.diffuseTexture.hasAlpha = true;
    frontSpotMaterial.useAlphaFromDiffuseTexture = true;
    frontSpotMaterial.specularColor = new Color3(0.5, 0.5, 0.5);
    frontSpot.material = frontSpotMaterial;
  }


  if(canvasBack) {
    const backBase = MeshBuilder.CreatePlane(
      'backBase',
      {
        width: planeWidth,
        height: planeHeight,
        sideOrientation: Mesh.BACKSIDE
      },
      scene
    );
    const backMaterial = new StandardMaterial('backMaterial', scene);
    const backBaseTexture = new DynamicTexture('backTexture', { width: canvasBack.width, height: canvasBack.height }, scene);
    backBaseTexture.uScale = -1;
    backBaseTexture.getContext().drawImage(canvasBack, 0, 0);
    backBaseTexture.update(false);
    backMaterial.diffuseTexture = backBaseTexture;
    backMaterial.specularColor = new Color3(0, 0, 0);
    backBase.material = backMaterial;

    if(spotCanvasBack) {
      const backSpot = MeshBuilder.CreatePlane(
        'backSpot',
        {
          width: planeWidth,
          height: planeHeight,
          sideOrientation: Mesh.BACKSIDE
        },
        scene
      );
      const backSpotMaterial = new StandardMaterial('backSpotMaterial', scene);
      const backSpotTexture = new DynamicTexture('backSpotTexture', { width: spotCanvasBack.width, height: spotCanvasBack.height }, scene);
      backSpotTexture.uScale = -1;
      backSpotTexture.getContext().drawImage(spotCanvasBack, 0, 0);
      backSpotTexture.update(false);
      backSpotMaterial.diffuseTexture = backSpotTexture;
      backSpotMaterial.diffuseTexture.hasAlpha = true;
      backSpotMaterial.useAlphaFromDiffuseTexture = true;
      backSpotMaterial.specularColor = new Color3(0.5, 0.5, 0.5);
      backSpot.material = backSpotMaterial;
      backSpot.position.z -= 0.01;
    }
  } else {
    const backBase = MeshBuilder.CreatePlane(
      'backBase',
      {
        width: planeWidth,
        height: planeHeight,
        sideOrientation: Mesh.BACKSIDE
      },
      scene
    );
    // plain white background
    const backMaterial = new StandardMaterial('backMaterial', scene);
    backMaterial.diffuseColor = new Color3(1, 1, 1);
    backMaterial.specularColor = new Color3(0, 0, 0);
    backBase.material = backMaterial;
  }

  console.log(scene)

}

export const resetCameraPosition = (scene: Scene): void => {
  const camera = scene.activeCamera as ArcRotateCamera;
  camera.alpha = (3 * Math.PI) / 2;
  camera.beta = Math.PI / 2;
  camera.radius = 10;
  camera.target = Vector3.Zero();
}

export const createScene = (
  engine: Engine | null,
  canvas: HTMLCanvasElement | null,
): Scene => {
  if (!engine) throw new Error('param engine passed is null');
  if (!canvas) throw new Error('param canvas passed is null');
  // This creates a basic Babylon Scene object (non-mesh)
  const scene = new Scene(engine);

  // This creates and positions a free camera (non-mesh)
  const camera = new ArcRotateCamera(
    'ArcRotateCamera',
    (3 * Math.PI) / 2,
    Math.PI / 2,
    10,
    Vector3.Zero(),
    scene,
    true
  );
  // Mouse wheel speed
  camera.wheelPrecision = 80;

  // highest dist you can look from MinZoom
  camera.upperRadiusLimit = 40;

  // lowest dist you can look from
  camera.lowerRadiusLimit = 1.5;

  // This attaches the camera to the canvas
  camera.attachControl(null, true);

  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  const ambientLight = new HemisphericLight('light', new Vector3(0, 1, 0), scene);

  // Default intensity is 1.
  ambientLight.intensity = 1;

  new PointLight("pointLight1", new Vector3(-10, 1, -10), scene);
  new PointLight("pointLight2", new Vector3(10, 1, 10), scene);
  new PointLight("pointLight3", new Vector3(-10, 1, 10), scene);
  new PointLight("pointLight4", new Vector3(10, 1, -10), scene);

  const skybox = MeshBuilder.CreateBox(
    'BackgroundSkybox',
    { size: 500, sideOrientation: Mesh.BACKSIDE },
    scene
  );
  const skyboxMaterial = new StandardMaterial('skyBox', scene);

  const frontScene = new Image();
  const leftScene = new Image();
  const upScene = new Image();
  const downScene = new Image();
  const backScene = new Image();
  const rightScene = new Image();
  rightScene.src = rightImage;
  leftScene.src = leftImage;
  frontScene.src = frontImage;
  upScene.src = upImage;
  downScene.src = downImage;
  backScene.src = backImage;

  const files = [
    leftScene.src,
    upScene.src,
    frontScene.src,
    rightScene.src,
    downScene.src,
    backScene.src
  ];
  skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = CubeTexture.CreateFromImages(files, scene);
  skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
  skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
  skyboxMaterial.specularColor = new Color3(0, 0, 0);
  skyboxMaterial.disableLighting = true;
  skybox.material = skyboxMaterial;
  return scene;
};
