import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { SpotColorFragment } from '@/gql_gen/graphql';
import { getBase64Image } from '@/helpers/formHelper';
import { apollo } from '@/graphql/apollo';
import { COLOR_IMAGE_BY_ID } from '@/graphql/queries';

export type CombineableSpotColorFragment = SpotColorFragment & {
  combinable_colors: SpotColorFragment[];
  src: string;
};

type rawCombineableSpotColorFragment = SpotColorFragment & {
  combinable_colors: { spot_color: SpotColorFragment }[];
};

export const useSpotColorStore = defineStore('spotColor', () => {
  const spotColors = ref<CombineableSpotColorFragment[]>([]);

  const getSpotColorByTechnicalName = (technicalName: string) => {
    const index = spotColors.value.findIndex((color) => color.technical_name === technicalName);
    return spotColors.value[index];
  };

  const loadTextureFromDb = async (id: string) => {
    const result = await apollo.query({
      query: COLOR_IMAGE_BY_ID,
      variables: {
        ids: [id]
      }
    });
    return result.data.printweb_spot_color[0].image;
  };

  const init = async (colorFragments: rawCombineableSpotColorFragment[]) => {
    spotColors.value = await Promise.all(
      colorFragments.map(async (raw) => ({
        ...raw,
        combinable_colors: raw.combinable_colors.map((cc) => cc.spot_color),
        src: getBase64Image(await loadTextureFromDb(raw.id))
      }))
    );
  };

  return { spotColors, init, getSpotColorByTechnicalName };
});
