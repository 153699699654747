import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import type Parser from './Parser';
import NumberParser from './NumberParser';
import ReadablePdfStream from '../ReadablePdfStream';
import { PdfPrimitive } from '../pdfPrimitives/PdfPrimitive';
import PdfIndirectObjectReference from '../pdfPrimitives/PdfIndirectObjectReference';

export default function parseReference(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfPrimitive {
  const pointerBefore = document.pointer;
  const objectNumber = NumberParser(document, parser, resolveIndirect);
  const generation = NumberParser(document, parser, resolveIndirect);

  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(1);
  if (nextString !== 'R') {
    document.pointer = pointerBefore;
    throw new NotTheRightPdfElementError(`Expected 'R' but got '${nextString}'`);
  }
  document.read(charactersRead);

  const pointerAfterRead = document.pointer;
  const indirectObject = new PdfIndirectObjectReference(objectNumber, generation);
  return indirectObject;
}
