import { PdfArray, PdfHexString, PdfString, type PdfPrimitive } from '../..';
import ReadablePdfStream from '../ReadablePdfStream';
import { PdfFont } from '../pdfPrimitives/PdfDict';
import type ColorSpace from './ColorSpace';

export class LineBreakPlaceholder {
  constructor() {}
}

export class Tj {
  text: string;
  renderingMode: number;
  font: PdfFont;
  tjIndex: number;
  colorSpaceStroke?: ColorSpace;
  colorSpaceFill?: ColorSpace;

  constructor(text: string, renderingMode: number, font: PdfFont, tjIndex: number) {
    this.text = text;
    this.renderingMode = renderingMode;
    this.font = font;
    this.tjIndex = tjIndex;
  }
}

export default class TextElement {
  startIndex: number = 0;
  endIndex: number = 0;
  printwebId: string = '';
  tjs: (Tj | LineBreakPlaceholder)[] = [];
  anchor?: { x: number; y: number };
  hasUndoeableOperations: boolean = false;

  addLineBreak() {
    this.tjs.push(new LineBreakPlaceholder());
  }

  addTj(
    arg: PdfString | PdfHexString | PdfArray<PdfPrimitive>,
    renderingMode: number,
    font: PdfFont,
    index: number,
    colorSpaceStroke: ColorSpace,
    colorSpaceFill: ColorSpace
  ) {
    let text = '';
    if (arg instanceof PdfString || arg instanceof PdfHexString) {
      text = font.translatePdfText(arg);
    } else {
      for (const item of arg) {
        if (item instanceof PdfString || item instanceof PdfHexString) {
          text += font.translatePdfText(item);
        }
      }
    }

    const newTj = new Tj(text, renderingMode, font, index);
    if (this.doesRenderWithFill(renderingMode)) {
      newTj.colorSpaceFill = colorSpaceFill;
    }
    if (this.doesRenderWithStroke(renderingMode)) {
      newTj.colorSpaceStroke = colorSpaceStroke;
    }

    this.tjs.push(newTj);
  }

  doesRenderWithStroke(renderMode: number) {
    return [1, 2, 5, 6].includes(renderMode);
  }

  doesRenderWithFill(renderMode: number) {
    return [0, 2, 4, 6].includes(renderMode);
  }

  getColorSpacesFill() {
    const colorSpaces = new Set<string>();
    for (const tj of this.tjs) {
      if (tj instanceof Tj && this.doesRenderWithFill(tj.renderingMode)) {
        colorSpaces.add(tj.colorSpaceFill!.name);
      }
    }
    return Array.from(colorSpaces);
  }

  getColorSpacesStroke() {
    const colorSpaces = new Set<string>();
    for (const tj of this.tjs) {
      if (tj instanceof Tj && this.doesRenderWithStroke(tj.renderingMode)) {
        colorSpaces.add(tj.colorSpaceStroke!.name);
      }
    }
    return Array.from(colorSpaces);
  }

  getTrimmedTjs(): (Tj | LineBreakPlaceholder)[] {
    // return a version of tjs without leading and trailing LineBreakPlaceholders
    let i = 0;
    while (i < this.tjs.length && this.tjs[i] instanceof LineBreakPlaceholder) {
      i++;
    }
    let j = this.tjs.length - 1;
    while (j >= 0 && this.tjs[j] instanceof LineBreakPlaceholder) {
      j--;
    }
    return this.tjs.slice(i, j + 1);
  }

  getIthTj(i: number) {
    // find ith not-line-break tj
    let j = 0;
    for (const tj of this.tjs) {
      if (tj instanceof LineBreakPlaceholder) {
        continue;
      }
      if (j === i) {
        return tj;
      }
      j++;
    }
  }

  get text(): string {
    return this.tjs
      .reduce((acc, tjs) => acc + (tjs instanceof LineBreakPlaceholder ? '\n' : tjs.text), '')
      .trim();
  }

  get hasStroke(): boolean {
    return this.tjs.some((tj) => tj instanceof Tj && this.doesRenderWithStroke(tj.renderingMode));
  }

  get hasFill(): boolean {
    return this.tjs.some((tj) => tj instanceof Tj && this.doesRenderWithFill(tj.renderingMode));
  }

  get canBeSplitIntoWords(): boolean {
    const trimmedTjs = this.getTrimmedTjs();
    return (
      trimmedTjs.length > 1 &&
      trimmedTjs.some(
        (tj) =>
          tj instanceof LineBreakPlaceholder ||
          (tj.text.length === 1 && ReadablePdfStream.WHITESPACE_CHARS.includes(tj.text))
      )
    );
  }

  get canBeSplitIntoLetters(): boolean {
    const trimmedTjs = this.getTrimmedTjs();
    return trimmedTjs.length > 1;
  }

  get isUnsplittableWithMultipleWords(): boolean {
    const trimmedTjs = this.getTrimmedTjs();
    return trimmedTjs.length === 1 && (trimmedTjs[0] as TextElement).text.length > 1;
  }

  get fillColorSpaceNames(): string[] {
    return this.getColorSpacesFill();
  }

  get strokeColorSpaceNames(): string[] {
    return this.getColorSpacesStroke();
  }
}
