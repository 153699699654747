<template>
  <img :src="withPrefix" :alt="props.alt" />
</template>

<script setup lang="ts">
import { hexToBase64 } from '@/helpers/formHelper';
import { computed } from 'vue';

const props = defineProps<{
  src: string;
  alt: string;
}>();

const sourceWithoutX = computed(() => {
  if (props.src.startsWith('\\x')) {
    return hexToBase64(props.src);
  }
  return props.src;
});

//src contains base64 of either an iamge or a svg, without any data:image/...;base64, prefix
const isSVG = computed(() => atob(sourceWithoutX.value).startsWith('<'));

const withPrefix = computed(() => {
  if (isSVG.value) {
    return `data:image/svg+xml;base64,${sourceWithoutX.value}`;
  } else {
    return `data:image/png;base64,${sourceWithoutX.value}`;
  }
});
</script>
