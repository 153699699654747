<template>
  <div class="modal-grid justify-content-center">
    <form @submit="onSubmit" class="flex flex-column gap-2">
      <div class="property-grid">
        <template
          class="property-grid"
          v-for="property in entityConfig?.properties"
          v-bind:key="property.field"
        >
          <div class="property-left" v-show="!property.isHiddenOnForm">
            <label>{{ property.label }}</label>
          </div>
          <div class="property-right" v-show="!property.isHiddenOnForm">
            <template v-if="property.type == 'text'">
              <InputText
                style="width: 100%"
                v-model="formFields[property.field].value.value"
                label="Test"
                type="text"
                v-bind:placeholder="property.label"
              />
            </template>
            <template v-if="property.type === 'image'">
              <FileUpload
                mode="basic"
                accept="image/*"
                v-model="formFields[property.field].value.value"
                @select="onFileSelect"
                customUploader
                @uploader="() => {}"
              >
                <template #empty>
                  <span class="p-button-icon p-button-icon-only pi pi-plus"></span>
                </template>
              </FileUpload>
            </template>
            <template v-if="property.type === 'boolean'">
              <Checkbox v-model="formFields[property.field].value.value" :binary="true" ent />
            </template>
            <template v-if="property.type === 'number'">
              <InputNumber
                type="number"
                v-model="formFields[property.field].value.value"
                :placeholder="property.label"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="property.min ?? 0"
                :max="property.max ?? 1"
              />
            </template>
            <template v-if="property.type === 'background_material'">
              <MultiSelect
                class="pw-multiselect"
                v-model="formFields[property.field].value.value"
                :options="materialLabels"
                option-group-label="name"
                option-group-children="background_materials"
                option-value="id"
                optionLabel="display_name"
                :loading="materialsLoading"
                :placeholder="property.label"
                display="chip"
                filter
              />
            </template>
            <template v-if="property.type === 'spot_color'">
              <MultiSelect
                class="pw-multiselect"
                v-model="formFields[property.field].value.value"
                :options="colors"
                optionLabel="display_name"
                optionValue="id"
                :loading="colorsLoading"
                :placeholder="property.label"
                filter
                display="chip"
              />
            </template>
            <template v-if="property.type === 'material_group'">
              <MultiSelect
                class="pw-multiselect"
                v-model="formFields[property.field].value.value"
                :options="materialGroups"
                optionLabel="name"
                optionValue="id"
                :loading="materialGroupsLoading"
                :placeholder="property.label"
                filter
                display="chip"
              />
            </template>
            <template v-if="property.type === 'material_label'">
              <Dropdown
                class="pw-multiselect"
                v-model="formFields[property.field].value.value"
                :options="materialLabels"
                optionLabel="name"
                optionValue="id"
                :loading="materialLabelsLoading"
                :placeholder="property.label"
                filter
              />
            </template>
            <small class="p-error" id="text-error">{{
              formFields[property.field].errorMessage.value || '&nbsp;'
            }}</small>
          </div>
        </template>
      </div>
      <Button type="submit" label="Save" :loading="isSaving"></Button>
    </form>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';

import { onMounted, ref } from 'vue';
import type { Ref } from 'vue';
import { inject } from 'vue';
import type { PrintwebEntity } from '@/types/EntityProperties';
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import { useBackgroundMaterialStore } from '@/stores/admin/materialBackground';
import { storeToRefs } from 'pinia';
import { useSpotColorStore } from '@/stores/admin/spotColor';
import { useField, useForm } from 'vee-validate';
import type { FieldContext } from 'vee-validate';
import { hexDump } from '@/helpers/formHelper';
import { useMaterialGroupStore } from '@/stores/admin/materialGroup';
import { useMaterialLabelStore } from '@/stores/admin/materialLabel';

// Initialize Stores and fetch Data
const materialStore = useBackgroundMaterialStore();
const colorsStore = useSpotColorStore();
const materialGroupStore = useMaterialGroupStore();
const materialLabelStore = useMaterialLabelStore();

const { materials, isLoading: materialsLoading } = storeToRefs(materialStore);
materialStore.subscribeBackgroundMaterials();

const { colors, isLoading: colorsLoading } = storeToRefs(colorsStore);
const { subscribeSpotColors } = colorsStore;

const {
  groupsAndMaterials,
  groups: materialGroups,
  isLoading: materialGroupsLoading
} = storeToRefs(materialGroupStore);
materialGroupStore.subscribeMaterialGroups();
materialGroupStore.subscribeMaterialGroupsAndMaterials();

const { labels: materialLabels, isLoading: materialLabelsLoading } =
  storeToRefs(materialLabelStore);
materialLabelStore.subscribeMaterialLabels();

// data
// Reference to Dialog Modal (e.g. to close modal)
const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef'); // injected from dynamic dialog using "data" field
// List of Properties according to Entity Definition
const entityConfig = ref<PrintwebEntity>();
// Current Values in Form initialized in onMounted()
const formFields: Record<string, FieldContext> = {};
// loading indicator on save button
const isSaving = ref<boolean>(false);
let onSaveFunc: Function | undefined = undefined;

// Form Validation
const { handleSubmit, resetForm } = useForm();

console.log('Type', typeof dialogRef);

const getInitialValueForField = (property: any, payload: any) => {
  // initial value
  const defaultValue = property.default ? property.default() : undefined;
  const initialValue = payload[property.field] ?? defaultValue ?? undefined;
  if (property.type === 'material_group') {
    return payload[property.field]?.map((bmg: any) => bmg.material_group.id);
  } else if (property.type === 'background_material') {
    return payload[property.field]?.map((bmg: any) => bmg.background_material.id);
  } else if (property.type === 'spot_color') {
    return payload[property.field]?.map((bmg: any) => bmg.spot_color.id);
  } else {
    return initialValue;
  }
};

// Hooks
onMounted(() => {
  subscribeSpotColors();
  console.log('Passed data to modal', dialogRef?.value.data);
  if (dialogRef?.value.data) {
    let payload = dialogRef?.value.data.payload;
    // Initialize form using entity definition
    entityConfig.value = dialogRef?.value.data.entityConfig;
    onSaveFunc = dialogRef?.value.data.onSave;
    if (!payload.id) {
      payload = {}; // create mode set payload to empty object
    }

    for (const property of entityConfig.value?.properties ?? []) {
      formFields[property.field] = useField(property.field, property.validate, {
        initialValue: getInitialValueForField(property, payload)
      });
    }
  }
});

const applyDefaultValues = (payload: Record<string, any>) => {
  entityConfig.value?.properties.forEach((p) => {
    if (p.default && !payload[p.field]) {
      payload[p.field] = p.default();
    }
  });
};

const closeDialog = () => {
  dialogRef?.value.close();
};

const onSubmit = handleSubmit(async (values) => {
  console.log('[EditEntityModal] onSubmit()', values);
  isSaving.value = true;
  if (values && onSaveFunc) {
    console.log('Form Submitted');
    try {
      await onSaveFunc(values);
      // toast.add({ severity: 'info', summary: 'Form Submitted', detail: values.value, life: 3000 });
    } catch (e) {
      console.error(e);
    } finally {
      isSaving.value = false;
    }
    resetForm();
    closeDialog();
  }
});

// Currently this only works for one image per entity
function onFileSelect(event: any) {
  const file = event.files[0];
  if (file) {
    hexDump(file).then((res) => {
      const imageField =
        formFields[entityConfig.value?.properties.find((p) => p.type === 'image')?.field!];
      imageField.value.value = `\\x${res}`;
    });
  }
}
</script>

<style scoped>
.modal-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.property-grid {
  display: grid;
  grid-template-columns: 20vw 40vw;
  grid-gap: 1rem;
  margin-bottom: 10px;
  align-items: center;
}

.property-left {
  margin-bottom: 10px;
}
.property-right {
  width: 100%;
}

#text-error {
  display: block;
  margin-top: 5px;
}

.pw-multiselect {
  width: 100%;
}
:deep(.p-multiselect-label) {
  white-space: normal;
}

:deep(.p-multiselect-token) {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
</style>
