import EofError from './errors/EofError';

export default class ReadablePdfStream {
  static readonly EOL_CHARS = ['\n', '\r'];
  static readonly WHITESPACE_CHARS = [' ', '\t', '\0', '\f', ...ReadablePdfStream.EOL_CHARS];
  static readonly OTHER_DELIMITER_CHARS = ['(', ')', '<', '>', '[', ']', '{', '}', '/', '%'];

  bytes: Uint8Array;
  pointer: number; // a pointer of 0 means, 0 bytes have been read, and points to the next byte to be read

  constructor(bytes: Uint8Array) {
    // copy to make sure the data is kept in memory
    // Uint8Arrays are prone to loosing their content, when the original refrence is lost
    this.bytes = Uint8Array.from(bytes);
    this.pointer = 0;
  }

  peek(length: number): Uint8Array {
    if (this.pointer + length > this.bytes.length) {
      // return the rest of the document
      return this.bytes.slice(this.pointer);
    }
    return this.bytes.slice(this.pointer, this.pointer + length);
  }

  peekWithOffset(length: number, offset: number): Uint8Array {
    if (this.pointer + length + offset > this.bytes.length) {
      //throw new EofError('Cannot peek beyond end of document');
      // return the rest of the document
      return this.bytes.slice(this.pointer);
    }
    return this.bytes.slice(this.pointer + offset, this.pointer + length + offset);
  }

  peekString(length: number): string {
    return String.fromCharCode(...this.peek(length));
  }

  peekStringWithOffset(length: number, offset: number): string {
    return String.fromCharCode(...this.peekWithOffset(length, offset));
  }

  peekStringIgnoringLeadingWhitespace(length: number): [string, number] {
    return this.peekStringIgnoringLeadingWhitespaceWithOffset(length, 0);
  }

  peekStringIgnoringLeadingWhitespaceWithOffset(length: number, offset: number): [string, number] {
    // this method should return the next n non-whitespace characters
    let addedOffset = 0;
    while (ReadablePdfStream.WHITESPACE_CHARS.includes(this.peekStringWithOffset(1, offset))) {
      offset++;
      addedOffset++;
    }
    return [this.peekStringWithOffset(length, offset), length + addedOffset];
  }

  peekStringUntilWhitespaceOrOtherDelimiter(): [string, number] {
    return this.peekStringUntilWhitespaceOrOtherDelimiterWithOffset(0);
  }

  peekStringUntilWhitespaceOrOtherDelimiterWithOffset(offset: number): [string, number] {
    let addedOffset = 0;
    while (ReadablePdfStream.WHITESPACE_CHARS.includes(this.peekStringWithOffset(1, offset))) {
      offset++;
      addedOffset++;
    }
    let length = 0;
    while (
      !ReadablePdfStream.WHITESPACE_CHARS.includes(this.peekStringWithOffset(1, offset + length)) &&
      !ReadablePdfStream.OTHER_DELIMITER_CHARS.includes(
        this.peekStringWithOffset(1, offset + length)
      )
    ) {
      length++;
    }
    return [this.peekStringWithOffset(length, offset), length + addedOffset];
  }

  peekStringWhatFollowsTheNextOccurenceOfNeedleAndWhitespace(
    needle: string,
    length: number
  ): string {
    let offset = 0;
    while (this.peekStringWithOffset(needle.length, offset) !== needle) {
      offset++;
    }
    return this.peekStringIgnoringLeadingWhitespaceWithOffset(length, offset + needle.length)[0];
  }

  peekForErrorMessage(length = 10): { peek: string; pointer: number } {
    // like peekStringIgnoringLeadingWhitespace(10), but aware of EOF
    let peek = '';
    let pointer = 0;
    let startPointer = this.pointer;
    while (pointer < length) {
      if (this.pointer + pointer >= this.bytes.length) {
        peek += 'EOF';
        break;
      }
      const char = String.fromCharCode(this.bytes[this.pointer + pointer]);
      peek += char;
      pointer++;
    }
    return { peek, pointer: startPointer };
  }

  peekOnlyWhitespaceLeftUntilEOF(): boolean {
    let pointer = 0;
    while (this.pointer + pointer < this.bytes.length) {
      if (
        !ReadablePdfStream.WHITESPACE_CHARS.includes(
          String.fromCharCode(this.bytes[this.pointer + pointer])
        )
      ) {
        return false;
      }
      pointer++;
    }
    return true;
  }

  read(length: number): Uint8Array {
    const data = this.peek(length);
    this.pointer += length;
    return data;
  }

  readString(length: number): string {
    return String.fromCharCode(...this.read(length));
  }

  readStringIgnoringLeadingWhitespace(length: number): [string, number] {
    const [string, charactersRead] = this.peekStringIgnoringLeadingWhitespace(length);
    this.read(charactersRead);
    return [string, charactersRead];
  }

  readStringUntilWhitespaceOrOtherDelimiter(): string {
    const [string, charactersRead] = this.peekStringUntilWhitespaceOrOtherDelimiter();
    this.read(charactersRead);
    return string;
  }

  readStringUntilPdfStringEnd(): string {
    let readBuffer = '';
    while (this.peekString(1) !== ')' || this.peekStringWithOffset(1, -1) === '\\') {
      readBuffer += this.readString(1);
    }
    return readBuffer;
  }

  readStringUntilPdfHexStringEnd(): string {
    let readBuffer = '';
    while (this.peekString(1) !== '>') {
      readBuffer += this.readString(1);
    }
    return readBuffer;
  }

  readBytesUntilBeforeNeedle(delimiter: string) {
    let bytesToRead = 0;
    while (this.peekStringWithOffset(delimiter.length, bytesToRead) !== delimiter) {
      bytesToRead++;
    }
    return this.read(bytesToRead);
  }

  readNextNewline() {
    // try to read \n, if that fails, try to read \r\n
    const peekedString = this.peekString(1);
    if (peekedString === '\n') {
      return this.read(1);
    } else if (peekedString === '\r') {
      return this.read(2);
    } else {
      throw new Error(`Expected newline, but got '${peekedString}'`);
    }
  }
}
