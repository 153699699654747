import Compiler from './Compiler';
import PdfName from '../pdfPrimitives/PdfName';
import ReadablePdfStream from '../ReadablePdfStream';

export default function compile(
  name: PdfName,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  const unescaped = name.valueOf();
  // replace everything outside of the range 21h to 7eh with #xx
  let escaped = unescaped.replace(/[^!-~]/g, (match) => {
    const charCode = match.charCodeAt(0);
    const hex = charCode.toString(16).padStart(2, '0');
    return `#${hex}`;
  });

  // replace all delimiters with #xx
  for (const delimiter of ReadablePdfStream.OTHER_DELIMITER_CHARS) {
    const hex = delimiter.charCodeAt(0).toString(16).padStart(2, '0');
    escaped = escaped.replace(new RegExp(`\\${delimiter}`, 'g'), `#${hex}`);
  }
  return Compiler.fromString('/' + escaped);
}
