import type ColorSpace from "pdfLib/lib/contentStream/ColorSpace";
import { renderPdf } from "./pdfRenderer";
import type { BackgroundMaterialWithImageData } from "@/stores/instance/backgroundMaterial";

function createShader(gl: WebGLRenderingContext, type: number, source: string) {
  const shader = gl.createShader(type)!;
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.error(gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
  }
  return shader;
}

function createProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {
  const program = gl.createProgram()!;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error(gl.getProgramInfoLog(program));
      gl.deleteProgram(program);
      return null;
  }
  return program;
}

const vertexShaderSource = `
attribute vec4 a_position;
attribute vec2 a_texCoord;
varying vec2 v_texCoord;

void main() {
    gl_Position = a_position;  // Pass through the position
    v_texCoord = a_texCoord;   // Pass through the texture coordinates
}
`;
const fragmentShaderSource = `
precision mediump float;

#define MAX_IMAGES 7

uniform sampler2D u_materialTexture;
uniform float u_materialTextureScale;
uniform sampler2D u_nonSpotColorTexture;
uniform sampler2D u_spotColorPdfs[MAX_IMAGES];
uniform sampler2D u_spotColorTextures[MAX_IMAGES];
uniform int u_imageCount;
varying vec2 v_texCoord;

float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898,78.233))) * 43758.5453);
}

vec2 rotate(vec2 coord, float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return vec2(coord.x * c - coord.y * s, coord.x * s + coord.y * c);
}

void main() {
    vec2 scale = vec2(u_materialTextureScale, u_materialTextureScale);
    vec2 coord = fract(v_texCoord * scale);
    vec2 tilePos = floor(v_texCoord * scale);

    float randomValue = rand(tilePos);
    float angle = floor(randomValue * 4.0) * 3.14159 / 2.0;
    coord = rotate(coord - 0.5, angle) + 0.5;

    vec4 finalColor = texture2D(u_materialTexture, coord);

    // multiply the non-spot color texture with the final color
    finalColor *= texture2D(u_nonSpotColorTexture, v_texCoord);

    vec4 spotColor= vec4(1.0, 1.0, 1.0, 1.0);
    for (int i = 0; i < MAX_IMAGES; i++) {
        if (i >= u_imageCount) break;  // Only process active images

        vec4 spotColorPdf = texture2D(u_spotColorPdfs[i], v_texCoord );
        vec4 spotColorTexture = texture2D(u_spotColorTextures[i], v_texCoord * scale);
        float lightnessPdf = (spotColorPdf.r + spotColorPdf.g + spotColorPdf.b) / 3.0;

        vec4 spotColorApplied = (1.0 - lightnessPdf) * spotColorTexture + lightnessPdf * spotColorPdf;
        spotColor = spotColorApplied;
    }
    
    finalColor *= spotColor;
    gl_FragColor = finalColor;
}
`;

let program: WebGLProgram | null = null;

export const setupShader = (gl: WebGLRenderingContext) => {
  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource)!;
  const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource)!;

  program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);
}

export const draw = (gl: WebGLRenderingContext, materialTexture: WebGLTexture, nonSpotColorTexture: WebGLTexture, spotColorPdfs: WebGLTexture[], spotColorTextures: WebGLTexture[], textureScaleFactor: number,) => {
  if(!program)
    throw new Error('Shader not initialized');
  const positionLocation = gl.getAttribLocation(program, 'a_position');
  const texCoordLocation = gl.getAttribLocation(program, 'a_texCoord');
  const imageCountLocation = gl.getUniformLocation(program, 'u_imageCount');

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, materialTexture);
  gl.uniform1i(gl.getUniformLocation(program, 'u_materialTexture'), 0);

  gl.uniform1f(gl.getUniformLocation(program, 'u_materialTextureScale'), textureScaleFactor);

  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, nonSpotColorTexture);
  gl.uniform1i(gl.getUniformLocation(program, 'u_nonSpotColorTexture'), 1);

  for (let i = 0; i < spotColorPdfs.length; i++) {
    gl.activeTexture(gl.TEXTURE2 + i);
    gl.bindTexture(gl.TEXTURE_2D, spotColorPdfs[i]);
    gl.uniform1i(gl.getUniformLocation(program, `u_spotColorPdfs[${i}]`), 2 + i);
  }

  for (let i = 0; i < spotColorTextures.length; i++) {
    gl.activeTexture(gl.TEXTURE2 + spotColorPdfs.length + i);
    gl.bindTexture(gl.TEXTURE_2D, spotColorTextures[i]);
    gl.uniform1i(gl.getUniformLocation(program, `u_spotColorTextures[${i}]`), 2 + spotColorPdfs.length + i);
  }

  gl.uniform1i(imageCountLocation, spotColorPdfs.length);

  const positionBuffer = gl.createBuffer()!;
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
    -1.0, -1.0,
    1.0, -1.0,
    -1.0, 1.0,
    -1.0, 1.0,
    1.0, -1.0,
    1.0, 1.0,
  ]), gl.STATIC_DRAW);
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

  const texCoordBuffer = gl.createBuffer()!;
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
    0.0, 0.0,
    1.0, 0.0,
    0.0, 1.0,
    0.0, 1.0,
    1.0, 0.0,
    1.0, 1.0,
  ]), gl.STATIC_DRAW);
  gl.enableVertexAttribArray(texCoordLocation);
  gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

  gl.drawArrays(gl.TRIANGLES, 0, 6);
}


export const createTextureFromBase64 = async (gl: WebGLRenderingContext, base64: string): Promise<WebGLTexture> => {
  const image = new Image();
  image.src = base64;
  return await new Promise((res) => {
    image.onload = () => {
      const texture = gl.createTexture()!;
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
      gl.bindTexture(gl.TEXTURE_2D, null);
      res(texture);
    }
  });
};

export const createTextureFromCanvas = (gl: WebGLRenderingContext, canvas: HTMLCanvasElement): WebGLTexture => {
  const texture = gl.createTexture()!;
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
  gl.bindTexture(gl.TEXTURE_2D, null);
  return texture;
};

export const renderRawPdfPage = async (rawPdf: any, pageNumber: number) => {
  const canvas = document.createElement('canvas');
  const defaultDPI = 72; // Default DPI from PDF.js
  const targetDPI = 500;
  const scale = targetDPI / defaultDPI;
  const page = await rawPdf.getPage(pageNumber + 1)
  const viewport = page.getViewport({ scale: scale });
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  await page.render({
    canvasContext: canvas.getContext('2d'),
    viewport: viewport
  }).promise;
  return canvas;
}

export const renderFullPdf = async (pdfdocument: any, spotColorStore: any, backgroundTexture: BackgroundMaterialWithImageData | null) => {
  const canvasPages: HTMLCanvasElement[] = [];

  const allSpotColorsUsed = [...new Set<string>(pdfdocument.parsedContentStreamPerPage.flatMap((page) => page.getAllUsedColorSpaces().flatMap((color: ColorSpace) => color.name)))].filter((color) => !!spotColorStore.getSpotColorByTechnicalName(color));
  const allSpotColorPdfs = allSpotColorsUsed.map((color) => pdfdocument.getPdfWithOnlySpotColor(color));
  const allSpotColorPdfsRendered = await Promise.all(allSpotColorPdfs.map((pdf) => renderPdf(pdf)));

  const rawPdf = await pdfjsLib.getDocument(pdfdocument.getPdfWithoutSpotColors(allSpotColorsUsed)).promise;
  const pages = rawPdf.numPages;
  for (let pageIndex = 0; pageIndex < pages; pageIndex++) {
    const renderedCanvas = await renderRawPdfPage(rawPdf, pageIndex);
    const pageWidth = renderedCanvas.width;
    const pageHeight = renderedCanvas.height;

    const pageCanvas = document.createElement('canvas');
    canvasPages.push(pageCanvas);
    pageCanvas.classList.add('pdf-viewer-canvas');
    pageCanvas.width = pageWidth;
    pageCanvas.height = pageHeight;
    const pageWidthInInches = pageWidth / 500;
    const gl = pageCanvas.getContext('webgl2', {preserveDrawingBuffer: true})!;

    let materialTexture: WebGLTexture;
    if (!backgroundTexture) {
      // create a white texture 1000x1000
      const canvas = document.createElement('canvas');
      canvas.width = 1000;
      canvas.height = 1000;
      const ctx = canvas.getContext('2d')!;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      materialTexture = createTextureFromCanvas(gl, canvas);
    } else {
      const base64Source = backgroundTexture.src;
      materialTexture = await createTextureFromBase64(gl, base64Source);
    }

    const nonSpotColorTexture = createTextureFromCanvas(gl, renderedCanvas);

    const spotColorPdfPageTextures: WebGLTexture[] = [];
    const allSpotColorsInPage = pdfdocument.parsedContentStreamPerPage[pageIndex].getAllUsedColorSpaces().map((color) => color.name).filter((color) => !!allSpotColorsUsed.includes(color));
    for (const color of allSpotColorsInPage) {
      const index = allSpotColorsUsed.indexOf(color);
      if (index === -1) continue;
      const texture = createTextureFromCanvas(gl, allSpotColorPdfsRendered[index][pageIndex]);
      spotColorPdfPageTextures.push(texture);
    };

    const spotColorTextures: WebGLTexture[] = [];
    for (const color of allSpotColorsInPage) {
      const src = spotColorStore.getSpotColorByTechnicalName(color).src;
      const texture = await createTextureFromBase64(gl, src);
      spotColorTextures.push(texture);
    };

    setupShader(gl);
    draw(gl, materialTexture, nonSpotColorTexture, spotColorPdfPageTextures, spotColorTextures, pageWidthInInches);
  }
  return canvasPages;
}