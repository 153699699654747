import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import type Parser from './Parser';
import ReadablePdfStream from '../ReadablePdfStream';
import PdfString from '../pdfPrimitives/PdfString';

export default function parseStream(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfString {
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(1);
  if (nextString !== '(') {
    throw new NotTheRightPdfElementError(`Expected '(' but got '${nextString}'`);
  }
  document.read(charactersRead); // (
  const string = document.readStringUntilPdfStringEnd();
  document.read(1); // )
  let processedString = string.replace(/(\r\n|\r|\n)/gm, '\n');
  processedString = processedString.replace(/\\\n/g, '');
  processedString = processedString.replace(
    /\\\\|\\n|\\r|\\t|\\b|\\f|\\\(|\\\)|\\[0-3][0-7]{2}/g,
    function (match) {
      switch (match) {
        case '\\\\':
          return '\\';
        case '\\n':
          return '\n';
        case '\\r':
          return '\r';
        case '\\t':
          return '\t';
        case '\\b':
          return '\b';
        case '\\f':
          return '\f';
        case '\\(':
          return '(';
        case '\\)':
          return ')';
        default:
          if (match.startsWith('\\')) {
            // Convert octal code to its character representation
            return String.fromCharCode(parseInt(match.slice(1), 8));
          }
          return match;
      }
    }
  );
  processedString = processedString.replace(/\\/g, '');
  return new PdfString(processedString);
}
