import { apollo } from '@/graphql/apollo';
import { INSTANCE_IMAGE_BY_ID } from '@/graphql/queries';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useInstanceStore = defineStore('instance', () => {
  const id = ref<string>('');
  const logo = ref<string>('');
  const name = ref<string>('');
  const allowedUrl = ref<string | null>('');

  const isReady = ref<boolean>(false);
  const isError = ref<boolean>(false);

  watch(id, (value) => {
    if (!value) return;
    apollo
      .query({
        query: INSTANCE_IMAGE_BY_ID,
        variables: {
          instanceIds: [value]
        }
      })
      .then((result) => {
        const imageSrc = result.data.printweb_instance[0].header_logo;
        logo.value = imageSrc;
      });
  });

  return { id, logo, name, allowedUrl, isReady, isError };
});
