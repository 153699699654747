import { createApp, h, provide } from 'vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import App from './App.vue';
import './style.css';
import 'primevue/resources/themes/lara-light-cyan/theme.css';
import router from '@/router';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import { useToastStore } from '@/stores/toast';
import Tooltip from 'primevue/tooltip';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apollo } from './graphql/apollo';

import * as Sentry from "@sentry/vue";

const app = createApp({
  render: () => h(App),
  setup() {
    provide(DefaultApolloClient, apollo);
  }
});

Sentry.init({
  app,
  dsn: "https://59fb26cf5a71eec9cdb9940951a3818c@o4508239855353856.ingest.de.sentry.io/4508421762973776",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.replayIntegration(),

    Sentry.captureConsoleIntegration(),
    Sentry.rewriteFramesIntegration(),
    Sentry.extraErrorDataIntegration()
  ],
  environment: import.meta.env.MODE,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/pdftech\.f0rc3\.de/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(ToastService);
app.use(DialogService);
app.use(createPinia());
app.use(PrimeVue);
app.use(router);

app.directive('tooltip', Tooltip);

// error handling
const toastStore = useToastStore();

app.config.errorHandler = (error: any, vm, info) => {
  console.error(error);
  toastStore.errorWithSummary(error.toString());
};

app.config.warnHandler = (warn) => {
  console.warn(warn);
  toastStore.errorWithSummary(warn.toString());
};

window.pdfjsLib.GlobalWorkerOptions.workerSrc =
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js';


app.mount('#app');
