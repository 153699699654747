import Compiler from './Compiler';
import PdfIndirectObject from '../pdfPrimitives/PdfIndirectObject';
import { PdfStream } from '../..';

export default function compile(
  object: PdfIndirectObject,
  compiler: Compiler,
  ...compilationArgs: any[]
): Uint8Array {
  let compiledElement: Uint8Array;
  if (object.element instanceof PdfStream) {
    compiledElement = compiler.compilePrimitive(object.element, true, ...compilationArgs);
  } else {
    compiledElement = compiler.compilePrimitive(object.element, ...compilationArgs);
  }
  // id and generation can have multiple digits
  const res = new Uint8Array(
    object.id.toString().length +
      1 +
      object.generation.toString().length +
      1 +
      3 +
      1 +
      compiledElement.length +
      1 +
      6
  );
  let pointer = 0;
  res.set(Compiler.fromString(`${object.id} ${object.generation} obj\n`), pointer);
  pointer += object.id.toString().length + 1 + object.generation.toString().length + 1 + 3 + 1;
  res.set(compiledElement, pointer);
  pointer += compiledElement.length;
  res.set(Compiler.fromString('\nendobj'), pointer);
  return res;
}
