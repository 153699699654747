<template>
  <Splitter id="editor-container" :gutter-size="8">
    <SplitterPanel id="preview-container" class="scroll" :size="99">
      <PdfViewer />
    </SplitterPanel>
    <SplitterPanel id="edit-menu-container" :size="1">
      <EditSidebar />
    </SplitterPanel>
  </Splitter>
</template>

<script setup lang="ts">
import PdfViewer from '@/components/PdfViewer.vue';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';

import { usePdfStore } from '@/stores/pdf';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import EditSidebar from '@/components/EditSidebar.vue';

const pdfStore = usePdfStore();
const router = useRouter();

onMounted(() => {
  if (!pdfStore.pdfDocument) router.push('/upload');
});
</script>

<style scoped>
#editor-container {
  height: 100%;
  width: 100%;
  position: relative;
}

#preview-container {
  /*padding: 1rem;*/
  display: grid;
  place-items: center;
}

#edit-menu-container {
  min-width: 400px;
}
</style>
