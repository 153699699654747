import { graphql } from '@/gql_gen';

export const QUERY_INSTANCE_BY_ID = graphql(`
  query InstanceById($id: uuid = "") {
    printweb_instance(where: { id: { _eq: $id } }, limit: 1) {
      ...Instance
      background_materials {
        background_material {
          ...BackgroundMaterial
        }
      }
      spot_colors {
        spot_color {
          ...SpotColor
          combinable_colors {
            spot_color {
              ...SpotColor
            }
          }
        }
      }
    }
  }
`);

export const INSTANCE_IMAGE_BY_ID = graphql(`
  query InstanceImageById($instanceIds: [uuid!] = "") {
    printweb_instance(where: { id: { _in: $instanceIds } }) {
      id
      header_logo
    }
  }
`);

export const COLOR_IMAGE_BY_ID = graphql(`
  query ColorImageById($ids: [uuid!] = "") {
    printweb_spot_color(where: { id: { _in: $ids } }) {
      id
      image
    }
  }
`);

export const MATERIAL_IMAGE_BY_ID = graphql(`
  query MaterialImageById($ids: [uuid!] = "") {
    printweb_background_material(where: { id: { _in: $ids } }) {
      id
      image
    }
  }
`);
