import { PdfArray, PdfName, PdfNumber, type PdfPrimitive } from '../..';
import { PdfFunction } from '../pdfPrimitives/PdfDict';

export default class ColorSpace {
  type: string;
  name: string;
  alternateSpace: ColorSpace | undefined;
  tintFunction: PdfFunction | undefined;
  ressourceIdentifier: string;

  constructor(
    colorSpace: string | PdfName | PdfArray<PdfPrimitive>,
    ressourceIdentifier: string = ''
  ) {
    if (typeof colorSpace === 'string') {
      this.type = colorSpace;
      this.name = colorSpace;
    } else if (colorSpace instanceof PdfName) {
      this.type = colorSpace.valueOf();
      this.name = this.type;
    } else if ((colorSpace[0] as PdfName).valueOf() === 'Separation') {
      this.type = 'Separation';
      this.name = (colorSpace[1] as PdfName).valueOf();
      this.alternateSpace = new ColorSpace(colorSpace[2] as PdfName | PdfArray<PdfPrimitive>);
      this.tintFunction = colorSpace[3] as PdfFunction;
    } else {
      this.type = (colorSpace[0] as PdfName).valueOf();
      this.name = this.type;
    }
    this.ressourceIdentifier = ressourceIdentifier;
  }

  static fromIndividialComponents(
    type: string,
    name: string,
    alternateSpace?: ColorSpace,
    tintFunction?: PdfFunction
  ) {
    const colorSpace = new ColorSpace(type);
    colorSpace.name = name;
    colorSpace.alternateSpace = alternateSpace;
    colorSpace.tintFunction = tintFunction;
    return colorSpace;
  }

  toRgb(color: PdfArray<PdfNumber> | number[]): { r: number; g: number; b: number } {
    let colorNumbers: number[];
    if (color instanceof PdfArray) {
      colorNumbers = color.map((c) => c.valueOf());
    } else {
      colorNumbers = color;
    }
    if (this.name === 'DeviceRGB') {
      return { r: colorNumbers[0], g: colorNumbers[1], b: colorNumbers[2] };
    } else if (this.name === 'DeviceGray') {
      return { r: colorNumbers[0], g: colorNumbers[0], b: colorNumbers[0] };
    } else if (this.name === 'DeviceCMYK') {
      const [c, m, y, k] = colorNumbers;
      const r = (1 - c) * (1 - k);
      const g = (1 - m) * (1 - k);
      const b = (1 - y) * (1 - k);
      return { r, g, b };
    } else if (this.type === 'Separation') {
      const transformedColor = this.tintFunction!.calculate(colorNumbers[0]);
      const rgb = this.alternateSpace!.toRgb(transformedColor);
      // tint is a number between 0 and 1
      const tint = colorNumbers[0];
      const finalColor = {
        r: (1 - tint) * rgb.r,
        g: (1 - tint) * rgb.g,
        b: (1 - tint) * rgb.b
      };
      return finalColor;
    } else {
      console.warn(`Unsupported color space type: ${this.type}`);
      return { r: 0, g: 0, b: 0 };
    }
  }

  getInitialColor() {
    switch (this.name) {
      case 'DeviceRGB':
        return [new PdfNumber(0), new PdfNumber(0), new PdfNumber(0)];
      case 'DeviceCMYK':
        return [new PdfNumber(0), new PdfNumber(0), new PdfNumber(0), new PdfNumber(1)];
      case 'DeviceGray':
        return [new PdfNumber(0)];
      default:
        if (this.type === 'Separation') {
          return [1];
        }
        console.warn(`Unsupported color space type: ${this.type}`);
        return [new PdfNumber(0), new PdfNumber(0), new PdfNumber(0)];
    }
  }

  toColorSpaceRessource(): PdfArray<PdfPrimitive> | PdfName {
    if (this.type === 'Separation') {
      return new PdfArray([
        new PdfName(this.type),
        new PdfName(this.name),
        new PdfName(this.alternateSpace!.name),
        this.tintFunction!
      ]);
    } else {
      return new PdfName(this.type);
    }
  }
}
