<template>
  <div class="floating-button-row">
    <Button v-show="!render3D" @click="render3D = true">3D</Button>
    <Button v-show="render3D" @click="render3D = false">2D</Button>
    <TreeSelect :options="groupedTextureOptions" v-model="treeSelectValue" placeholder="Material" />
    <ToggleButton v-show="render3D" v-model="duplex" onLabel="Duplex On" offLabel="Duplex Off" />
    <Dropdown v-show="duplex && render3D" v-model="pageNumber" :options="duplexPage1Options" option-label="label"
      option-value="value" option-disabled="disabled" style="width: 4rem" placeholder="first page" />
    <Dropdown v-show="duplex && render3D" v-model="duplexPage" :options="duplexPage2Options" option-label="label"
      option-value="value" option-disabled="disabled" style="width: 4rem" placeholder="second page" />
  </div>
  <div v-show="!render3D" id="pdf-viewer" ref="pdfViewer">
    <PdfViewerPage v-for="canvas,index in canvasPages" :key="index+Math.random()" :canvas="canvas" :page-index="index"/>
  </div>
  <div v-if="render3D" class="three-d-render">
    <Pdf3dViewer :frontPage="canvasPages[pageNumber]" :backPage="canvasPages[duplexPage]" :duplex="duplex"/>
    <Paginator v-if="canvasPages.length > 1 && !duplex" v-model:first="pageNumber" :rows="1"
      :totalRecords="canvasPages.length" template="PrevPageLink CurrentPageReport NextPageLink" />
  </div>
</template>

<script setup lang="ts">
import { usePdfStore } from '@/stores/pdf';
import { PdfDocument } from 'pdfLib';
import { computed, ref, type Ref, watch, inject, onMounted } from 'vue';
import { type IsLoading } from '../App.vue';
import { useSpotColorStore } from '@/stores/instance/spotColor';
import { useBackgroundMaterialStore, type BackgroundMaterialWithImageData } from '@/stores/instance/backgroundMaterial';
import TreeSelect from 'primevue/treeselect';
import { renderFullPdf } from '@/helpers/2dRenderer';
import PdfViewerPage from './PdfViewerPage.vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Paginator from 'primevue/paginator';
import Pdf3dViewer from './Pdf3dViewer.vue';
import ToggleButton from 'primevue/togglebutton';

const pdfStore = usePdfStore();
const colorStore = useSpotColorStore();
const materialStore = useBackgroundMaterialStore();
const canvasPages: Ref<HTMLCanvasElement[]> = ref([]);
const isLoading = inject('isLoading') as IsLoading;
const pdfViewer = ref<HTMLElement | null>();

// 3D options
const render3D = ref(false);
const duplex = ref(false);
const pageNumber = ref(0);
const duplexPage = ref(0);
const duplexPage1Options = computed(() => {
  return [...Array(canvasPages.value.length).keys()].map((val) => ({
    label: val + 1 + '',
    value: val,
    disabled: canvasPages.value.every(
      (page, index) => index == val || (
        page.width !== canvasPages.value[val].width ||
        page.height !== canvasPages.value[val].height
      )
    )
  }));
});
const duplexPage2Options = computed(() => {
  return [...Array(canvasPages.value.length).keys()].map((val) => ({
    label: val + 1 + '',
    value: val,
    disabled: canvasPages.value[pageNumber.value].width !== canvasPages.value[val].width ||
              canvasPages.value[pageNumber.value].height !== canvasPages.value[val].height
  }));
});
watch(pageNumber, () => {
  const firstMatchingPageOtherThanItself = duplexPage2Options.value.findIndex((option) => !option.disabled && option.value !== pageNumber.value);
  if (firstMatchingPageOtherThanItself !== -1) {
    duplexPage.value = firstMatchingPageOtherThanItself;
  }
});

const chosenTexture: Ref<BackgroundMaterialWithImageData | null> = computed(() => {
  if (!pdfStore.pdfDocument) return null;
  const key = Object.keys(treeSelectValue.value).find((key) => treeSelectValue.value[key])!
  if (key === 'none')
    return null;
  return materialStore.getMaterialById(key)
});

const treeSelectValue = ref({ 'none': true });
const groupedTextureOptions = computed(() => {
  const groups: {
    key: string;
    label: string;
    children?: {
      key: string;
      label: string;
    }[];
  }[] = [
      {
        key: 'none',
        label: 'No Background'
      },
      {
        label: 'Other',
        key: 'other',
        children: []
      }
    ];
  const groupIndices: any = {
    other: 1
  };
  materialStore.materials.forEach((material) => {
    if (material.background_material_groups.length === 0) {
      groups[1].children!.push({
        key: material.id,
        label: material.display_name,
        data: material
      });
    } else {
      material.background_material_groups.forEach((group) => {
        const groupName = group.material_group.name;
        let index = groupIndices[groupName];
        if (!index) {
          index = groups.length;
          groupIndices[groupName] = index;
          groups.push({
            key: groupName,
            label: groupName,
            children: []
          });
        }
        groups[index].children!.push({
          key: material.id,
          label: material.display_name
        });
      });
    }
  });
  return groups;
});

watch(
  () => treeSelectValue.value,
  () => handlePdfUpdate(pdfStore.pdfDocument as PdfDocument)
);

const handlePdfUpdate = async (pdfdocument: PdfDocument) => {
  if (!pdfdocument) return;
  isLoading.set(true);

  canvasPages.value = await renderFullPdf(pdfdocument, colorStore, chosenTexture.value);

  pdfStore.generatePreviews(canvasPages.value);

  isLoading.set(false);
};

watch(
  () => pdfStore.pdfDocument as PdfDocument,
  handlePdfUpdate
);

onMounted(() => {
  handlePdfUpdate(pdfStore.pdfDocument as PdfDocument);
})
</script>
<style lang="scss">
/* Don't delete this class because we use it canvas elements*/
.pdf-viewer-canvas {
  background-color: transparent;
  max-width: 100vh;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

#pdf-viewer {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin: 1em;
  gap: 1em;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.floating-button-row {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 1;
  display: flex;
  gap: 5px;
}

.floating-button-col {
  position: absolute;
  top: 5em;
  left: 1em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.three-d-render {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr max-content;
  justify-items: center;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.canvas3d-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.three-d-render-canvas {
  max-width: 100%;
  max-height: 100%;
}

.icon-button {
  padding: 0;
  width: 3em;
  height: 3em;

  span {
    font-size: 30px !important;
  }
}

.circleRiple {
  animation: circleRiple 0.4s ease-out forwards;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  background-color: #0006;
  border: 1px solid white;
  pointer-events: none;
}

@keyframes circleRiple {
  0% {
    width: 0;
    height: 0;
    translate: 0;
  }

  80% {
    width: 60px;
    height: 60px;
    translate: -30px -30px;
    opacity: 1;
  }

  100% {
    width: 60px;
    height: 60px;
    translate: -30px -30px;
    opacity: 0;
  }
}
</style>
