import ColorSpace from './ColorSpace';

export default class SvgPathElement {
  path: string = '';
  fillSpace: ColorSpace = new ColorSpace('DeviceRGB');
  fillColor: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 };
  fillMode: 'nonzero' | 'evenodd' = 'nonzero';
  strokeSpace: ColorSpace = new ColorSpace('DeviceRGB');
  strokeColor: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 };
  hasStroke: boolean = false;
  hasFill: boolean = false;
  strokeWidth: number = 1;
  printwebId: string = '';
  anchor: { x: number; y: number } = { x: 0, y: 0 };
  hasUndoeableOperations: boolean = false;
  startIndex: number = 0;
  endIndex: number = 0;

  clone(): SvgPathElement {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setStrokeColor(color: any, colorSpace: ColorSpace = this.strokeSpace) {
    this.strokeSpace = colorSpace;
    this.strokeColor = colorSpace.toRgb(color);
  }

  setFillColor(color: any, colorSpace: ColorSpace = this.fillSpace) {
    this.fillColor = colorSpace.toRgb(color);
    this.fillSpace = colorSpace;
  }
}
