import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfContentStreamOperator from '../pdfPrimitives/PdfContentStreamOperator';
import PdfName from '../pdfPrimitives/PdfName';
import type Parser from './Parser';

export default function parseCsOp(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfContentStreamOperator {
  for (let charsToRead = 3; charsToRead > 0; charsToRead--) {
    let nextString: string | null = null;
    let charactersRead = 0;
    try {
      [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(charsToRead);
    } catch (e) {}
    if (nextString && CONTENT_STREAM_OPERATORS.includes(nextString)) {
      document.read(charactersRead);
      return new PdfContentStreamOperator(nextString);
    }
  }
  throw new NotTheRightPdfElementError('Expected a content stream operator but got EOF');
}

const CONTENT_STREAM_OPERATORS = [
  // Graphics State Operators
  'q',
  'Q',
  'cm',
  'w',
  'J',
  'j',
  'M',
  'd',
  'ri',
  'i',
  'gs',

  // Path Construction Operators
  'm',
  'l',
  'c',
  'v',
  'y',
  'h',
  're',

  // Path-Painting Operators
  'S',
  's',
  'f',
  'F',
  'f*',
  'B',
  'B*',
  'b',
  'b*',
  'n',

  // Clipping Path Operators
  'W',
  'W*',

  // Text Object Operators
  'BT',
  'ET',

  // Text State Operators
  'Tc',
  'Tw',
  'Tz',
  'TL',
  'Tf',
  'Tr',
  'Ts',

  // Text Positioning Operators
  'Td',
  'TD',
  'Tm',
  'T*',

  // Text Showing Operators
  'Tj',
  "'",
  '"',
  'TJ',

  // Type 3 Font Operators
  'd0',
  'd1',

  // Color Operators
  'CS',
  'cs',
  'SC',
  'SCN',
  'sc',
  'scn',
  'G',
  'g',
  'RG',
  'rg',
  'K',
  'k',

  // Shading Pattern Operators
  'sh',

  // Inline Images Operators
  'BI',
  'ID',
  'EI',

  // XObject Operators
  'Do',

  // Marked Content Operators
  'MP',
  'DP',
  'BMC',
  'BDC',
  'EMC',

  // Compatibility Operators
  'BX',
  'EX'
];
