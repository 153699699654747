import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfName from '../pdfPrimitives/PdfName';
import type Parser from './Parser';

export default function parseName(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfName {
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(1);
  if (nextString !== '/') {
    throw new NotTheRightPdfElementError(`Expected '/' but got '${nextString}'`);
  }
  document.read(charactersRead);
  const name = document.readStringUntilWhitespaceOrOtherDelimiter();
  const escapesHandled = name.replace(/#([0-9a-fA-F]{2})/g, (_, hex) =>
    String.fromCharCode(parseInt(hex, 16))
  );

  return new PdfName(escapesHandled);
}
