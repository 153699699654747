import ReadablePdfStream from '../ReadablePdfStream';
import NotTheRightPdfElementError from '../errors/NotTheRightPdfElementError';
import PdfBoolean from '../pdfPrimitives/PdfBoolean';
import type Parser from './Parser';

export default function parseBoolean(
  document: ReadablePdfStream,
  parser?: Parser,
  resolveIndirect?: boolean
): PdfBoolean {
  const [nextString, charactersRead] = document.peekStringIgnoringLeadingWhitespace(4);
  if (nextString === 'true') {
    document.read(charactersRead);
    return new PdfBoolean(true);
  }
  const [nextString2, charactersRead2] = document.peekStringIgnoringLeadingWhitespace(5);
  if (nextString2 === 'false') {
    document.read(charactersRead2);
    return new PdfBoolean(false);
  }
  throw new NotTheRightPdfElementError(`Expected 'true' or 'false' but got '${nextString2}'`);
}
