import Login from '@/screens/LoginScreen.vue';
import EditScreen from '@/screens/EditScreen.vue';
import UploadScreen from '@/screens/UploadScreen.vue';
import BackgroundMaterialScreen from '@/screens/BackgroundMaterialScreen.vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { useUserStore } from '@/stores/admin/user';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { path: '/upload' }
  },
  {
    path: '/upload',
    name: 'upload',
    component: UploadScreen,
    meta: {
      requiresValidUUID: true
    }
  },
  {
    path: '/edit',
    name: 'edit',
    component: EditScreen,
    meta: {
      requiresValidUUID: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/admin/',
    name: 'admin',
    redirect: { path: '/admin/instance' }
  },
  {
    path: '/admin/instance',
    name: 'instance',
    component: () => import('@/screens/InstanceScreen.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/material',
    name: 'material',
    component: BackgroundMaterialScreen,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/color',
    name: 'color',
    component: () => import('@/screens/SpotColorScreen.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/label',
    name: 'label',
    component: () => import('@/screens/MaterialLabelScreen.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/group',
    name: 'group',
    component: () => import('@/screens/MaterialGroupScreen.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    // fallback screen
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('@/screens/NotFoundScreen.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from, next): Promise<void> => {
  const user = useUserStore();
  const isLoggedIn = user.isLoggedIn;
  console.log('Current user', user); // user is defined

  if (to.meta.requiresAuth && !isLoggedIn) {
    user.logout();
    console.info('Going to login screen');
    next({ name: 'login' });
  }
  console.info('Going to next screen');
  next();
}); // t

export default router;
