import { defineStore } from 'pinia';
import { api } from '@/api/axios';
import type LoginResponse from '@/api/Response/LoginResponse';
import User from '@/entities/User';
import router from '@/router';
import { jwtDecode } from 'jwt-decode';

interface UserHasuraResponse<T> {
  printweb_user: T;
}

export const useUserStore = defineStore('user', {
  state: () => ({
    authToken: JSON.parse(localStorage.getItem('auth_token')!),
    user: new User() as User,
    usersList: null as User[] | null
  }),
  getters: {
    isLoggedIn: (state) => {
      if (state.authToken !== null && state.authToken !== undefined) {
        const payload = jwtDecode(state.authToken);
        if (payload.exp! > Date.now() / 1000) {
          return true;
        }
        return false;
      }
      return false;
    }
  },
  actions: {
    async login(email: string, password: string) {
      let res;
      try {
        res = await api<LoginResponse>({
          method: 'post',
          url: 'rest/auth/login',
          data: {
            email,
            password
          }
        });
        this.authToken = res.data.authToken;
        window.localStorage.setItem('userId', JSON.stringify(res.data.userId));
        window.localStorage.setItem('auth_token', JSON.stringify(this.authToken));
        router.push({ name: 'admin' });
      } catch (e: any) {
        throw new Error(e?.response?.data?.message);
      }
    },
    logout() {
      this.user = new User();
      this.authToken = null;
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('auth_token');
      // navigate to login page
      router.push({ name: 'login' });
    }
  }
});
