import pako from 'pako';
import { PdfDict } from '../..';

export default function decode(input: Uint8Array, params?: PdfDict): Uint8Array {
  const deflated = pako.inflate(input);

  const predictor = (params?.get('Predictor')?.valueOf() as number) || 1;
  const colors = (params?.get('Colors')?.valueOf() as number) || 1;
  const bitsPerComponent = (params?.get('BitsPerComponent')?.valueOf() as number) || 8;
  const columns = (params?.get('Columns')?.valueOf() as number) || 1;

  if (predictor === 1) return deflated;
  if (predictor === 2) throw new Error('Unsupported predictor: 2 (TIFF)');
  if (predictor === 14) console.warn('Unverified predictor: 14 (PNG PAETH). Might be wrong.');
  if (predictor === 15) throw new Error('Unsupported predictor: 15 (PNG optimum)');

  const bitsPerPixel = colors * bitsPerComponent;
  const bytesPerRow = Math.ceil((bitsPerPixel * columns) / 8) + 1;
  const numberOfRows = deflated.length / bytesPerRow;

  // remove predictor byte from each row
  const deflatedWithoutPredictor = new Uint8Array(deflated.length - numberOfRows);
  const predicorBytePerRow: number[] = [];
  for (let i = 0; i < numberOfRows; i++) {
    predicorBytePerRow.push(deflated[i * bytesPerRow]);
    for (let j = 0; j < bytesPerRow - 1; j++) {
      deflatedWithoutPredictor[i * (bytesPerRow - 1) + j] = deflated[i * bytesPerRow + j + 1];
    }
  }

  // turn deflated array of bytes into array of bits
  const bits = new Uint8Array(deflatedWithoutPredictor.length * 8);
  for (let i = 0; i < deflatedWithoutPredictor.length; i++) {
    for (let j = 0; j < 8; j++) {
      bits[i * 8 + j] = (deflatedWithoutPredictor[i] >> (8 - j - 1)) & 1;
    }
  }

  // turn 1D array of bits into array of pixels, with each pixel being an array of colors
  const pixels: number[][] = [];
  const pixelLengthInBits = colors * bitsPerComponent;
  let bitPointer = 0;
  while (bitPointer < bits.length) {
    const pixel: number[] = [];
    for (let i = 0; i < colors; i++) {
      let value = 0;
      for (let j = 0; j < bitsPerComponent; j++) {
        value = (value << 1) + bits[bitPointer++];
      }
      pixel.push(value);
    }
    pixels.push(pixel);
  }

  // turn array of pixels into array of rows
  const rows: number[][][] = [];
  let pixelPointer = 0;
  while (pixelPointer < pixels.length) {
    const row: number[][] = [];
    for (let i = 0; i < columns; i++) {
      row.push(pixels[pixelPointer++]);
    }
    rows.push(row);
  }

  let formattedString = '';
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      for (let k = 0; k < rows[i][j].length; k++) {
        formattedString += rows[i][j][k].toString().padStart(4, ' ');
      }
      formattedString += ' | ';
    }
    formattedString += '\n';
  }

  // iterate over pixles to apply predictor
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const predictorForRow = predicorBytePerRow[i];
    for (let j = 0; j < rows[i].length; j++) {
      const pixel = rows[i][j];
      for (let k = 0; k < colors; k++) {
        let color: number = rows[i][j][k];
        // apply predictor
        switch (predictorForRow) {
          case 0: {
            // don't do anything
            break;
          }
          case 1: {
            // PNG prediction (SUB)
            if (j > 0) {
              color += row[j - 1][k];
            }
            break;
          }
          case 2: {
            // PNG prediction (UP)
            if (i > 0) {
              color += rows[i - 1][j][k];
            }
            break;
          }
          case 3: {
            // PNG prediction (AVERAGE)
            if (i > 0 && j > 0) {
              color += Math.floor((row[j - 1][k] + rows[i - 1][j][k]) / 2);
            } else if (i > 0) {
              color += rows[i - 1][j][k];
            } else if (j > 0) {
              color += row[j - 1][k];
            }
            break;
          }
          case 4: {
            // PNG prediction (PAETH)
            // generated by Copilot, not tested
            if (i > 0 && j > 0) {
              const a = row[j - 1][k];
              const b = rows[i - 1][j][k];
              const c = rows[i - 1][j - 1][k];
              const p = a + b - c;
              const pa = Math.abs(p - a);
              const pb = Math.abs(p - b);
              const pc = Math.abs(p - c);
              if (pa <= pb && pa <= pc) {
                color += a;
              } else if (pb <= pc) {
                color += b;
              } else {
                color += c;
              }
            } else if (i > 0) {
              color += rows[i - 1][j][k];
            } else if (j > 0) {
              color += row[j - 1][k];
            }
            break;
          }
          case 5: {
            break;
          }
        }

        pixel[k] = color % 2 ** bitsPerComponent;
      }
      row[j] = pixel;
    }
    rows[i] = row;
  }

  formattedString = '';
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      for (let k = 0; k < rows[i][j].length; k++) {
        formattedString += rows[i][j][k].toString().padStart(4, ' ');
      }
      formattedString += ' | ';
    }
    formattedString += '\n';
  }

  formattedString = '';
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      for (let k = 0; k < rows[i][j].length; k++) {
        formattedString += rows[i][j][k].toString(2).padStart(8, '0');
      }
      formattedString += ' | ';
    }
    formattedString += '\n';
  }

  // turn array of rows into array of pixels
  const newPixels: number[][] = [];
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      newPixels.push(rows[i][j]);
    }
  }

  const newValues = newPixels.flatMap((pixel) => pixel);

  // turn array of values into array of bits
  const newBits = new Uint8Array(newValues.length * bitsPerComponent);
  for (let i = 0; i < newValues.length; i++) {
    for (let j = 0; j < bitsPerComponent; j++) {
      newBits[i * bitsPerComponent + j] = (newValues[i] >> (bitsPerComponent - j - 1)) & 1;
    }
  }

  // turn array of bits into array of bytes
  const newBytes = new Uint8Array(newBits.length / 8);
  for (let i = 0; i < newBytes.length; i++) {
    for (let j = 0; j < 8; j++) {
      newBytes[i] = (newBytes[i] << 1) + newBits[i * 8 + j];
    }
  }

  return newBytes;
}
