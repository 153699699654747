// stores/counter.js
import { defineStore } from 'pinia';
import { useSubscription } from '@vue/apollo-composable';

import { watch } from 'vue';
import { apollo } from '@/graphql/apollo';
import type {
  FetchSpotColorsSubscription,
  Printweb_Combinable_Spot_Colors_Insert_Input,
  Printweb_Spot_Color_Insert_Input
} from '@/gql_gen/graphql';
import { FETCH_SPOT_COLORS_SUBSCRIPTION } from '@/graphql/subscriptions';
import {
  DELETE_COMBINABLE_COLORS,
  DELETE_SPOT_COLOR_MUTATION,
  INSERT_COMBINABLE_COLORS,
  INSERT_SPOT_COLOR_MUTATION,
  UPDATE_SPOT_COLOR_MUTATION
} from '@/graphql/mutations';
import { COLOR_IMAGE_BY_ID, MATERIAL_IMAGE_BY_ID } from '@/graphql/queries';

export type StoreSpotColor = FetchSpotColorsSubscription['printweb_spot_color'][0] & {
  image?: string; // extend fragment manually for postLoadImages
};

type SpotColorState = {
  colors: StoreSpotColor[];
  isLoading: boolean;
  totalCount: number;
};
export const useSpotColorStore = defineStore('spotColorAdmin', {
  state: (): SpotColorState => {
    return {
      colors: [],
      isLoading: true, // -- initially set to true until subscription completes the first time
      totalCount: 0
    };
  },
  getters: {
    getColorById: (state) => {
      return (colorId: string) => state.colors.find((color) => color.id === colorId);
    }
  },
  actions: {
    async subscribeSpotColors() {
      const { result, loading, error } = useSubscription(FETCH_SPOT_COLORS_SUBSCRIPTION);
      watch(result, (data) => {
        this.colors = data?.printweb_spot_color ?? [];
        console.log('data', data);
        this.postLoadImages(this.colors);
      });

      watch(loading, (loading) => {
        this.isLoading = loading;
        console.log('colors Loading', loading);
      });

      watch(error, (error) => {
        console.error('error', error);
      });
    },
    async postLoadImages(colors: StoreSpotColor[]) {
      for (const color of colors) {
        // fetch images asynchronously and separately to avoid long loading times
        await apollo
          .query({
            query: COLOR_IMAGE_BY_ID,
            variables: {
              ids: [color.id]
            }
          })
          .then((result) => {
            color.image = result.data.printweb_spot_color[0].image;
          });
      }
    },
    async createColor(data: Printweb_Spot_Color_Insert_Input) {
      await apollo.query({
        query: INSERT_SPOT_COLOR_MUTATION,
        variables: {
          updateFields: data
        }
      });
    },
    async updateColor(data: Printweb_Spot_Color_Insert_Input, combinableColorIds: string[] = []) {
      if (!data.id) {
        throw new Error('id must be defined');
      }
      await apollo.query({
        query: UPDATE_SPOT_COLOR_MUTATION,
        variables: {
          _set: data,
          id: data.id
        }
      });
      await this.updateCombinableColors(data.id, combinableColorIds);
    },
    async updateCombinableColors(colorId: string, combinableColorIds: string[] = []) {
      await apollo.query({
        query: DELETE_COMBINABLE_COLORS,
        variables: {
          spotColorId: colorId
        }
      });

      if (combinableColorIds.length === 0) {
        return;
      }
      const combinableColors: Printweb_Combinable_Spot_Colors_Insert_Input[] =
        combinableColorIds.map((combinableId) => {
          return {
            combinable_spot_color_id: combinableId,
            spot_color_id: colorId
          };
        });
      await apollo.query({
        query: INSERT_COMBINABLE_COLORS,
        variables: {
          objects: combinableColors
        }
      });
    },
    async deleteSpotColor(id: string) {
      console.log('deleteSpotColor', id);
      await apollo.query({
        query: DELETE_SPOT_COLOR_MUTATION,
        variables: {
          id: id
        }
      });
    }
  }
});
