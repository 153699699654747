<template>
  <div class="svg-container" ref="svgContainer" :class="backgroundColor"></div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { SVG } from '@svgdotjs/svg.js';
import type { SvgPathElement } from 'pdfLib';

const props = defineProps<{
  svgElement: SvgPathElement;
  viewport: { x1: number; y1: number; x2: number; y2: number };
}>();

const backgroundColor = computed(() => {
  const fillColorBackground = getContrastingBackground(props.svgElement.fillColor);
  const strokeColorBackground = getContrastingBackground(props.svgElement.strokeColor);
  const doFill = props.svgElement.hasFill;
  const doStroke = props.svgElement.hasStroke;
  if (doFill && !doStroke) {
    return fillColorBackground;
  } else if (!doFill && doStroke) {
    return strokeColorBackground;
  } else if (fillColorBackground === strokeColorBackground) {
    return strokeColorBackground;
  } else {
    return '#ffffff';
  }
});

const getContrastingBackground = ({ r, g, b }: { r: number; g: number; b: number }) => {
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 0.5 ? 'black' : 'white';
};

const svgContainer = ref();

onMounted(() => {
  // Create the SVG canvas
  const draw = SVG().addTo(svgContainer.value).size('100%', '100%');

  // Add your SVG content (you can adjust this based on your needs)
  const path = draw.svg(
    `<path d="${props.svgElement.path}" stroke-width="${Math.max(
      props.svgElement.strokeWidth / 2,
      1
    )}"/>`
  );
  path.fill('none');
  path.stroke('none');
  if (props.svgElement.hasStroke) {
    path.stroke(rgbToHex(props.svgElement.strokeColor));
  }
  if (props.svgElement.hasFill) {
    path.fill({ color: rgbToHex(props.svgElement.fillColor), rule: props.svgElement.fillMode });
  }

  // set the bounding box as the viewbox of the svg
  //  draw.viewbox(path.bbox());

  // set the viewbox to the size of the PDF
  const width = props.viewport.x2 - props.viewport.x1;
  const height = props.viewport.y2 - props.viewport.y1;
  draw.viewbox(props.viewport.x1, props.viewport.y1, width, height);

  // // Resize the SVG canvas to fit the content - scaled so the width of the svgContainer stays the same
  // draw.size(svgContainer.value.offsetWidth, svgContainer.value.offsetWidth * path.bbox().height / path.bbox().width);
  // console.log(draw.size());
});

const rgbToHex = ({ r, g, b }: { r: number; g: number; b: number }): string => {
  // r, g, b are between 0 and 1
  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + toHex(r) + toHex(g) + toHex(b);
};
</script>
<style scoped lang="scss">
.svg-container {
  height: 100%;
  max-height: 10em;
  padding: 3px;
}

.white:deep(svg) {
  background-color: #fff;
}
.black:deep(svg) {
  background-color: #000;
}

.svg-container:deep(svg) {
  max-width: 100%;
  transform: scaleY(-1);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
</style>
