import {
  PdfArray,
  PdfBoolean,
  PdfDict,
  PdfName,
  PdfNumber,
  PdfStream,
  type PdfPrimitive,
  SvgPathElement
} from '../..';
import { PdfExtGState, type PdfPage } from '../pdfPrimitives/PdfDict';
import ColorSpace from './ColorSpace';
import TextState from './TextState';

export default class GraphicsState {
  ctm: number[];
  clippingPath: string[];
  colorSpaceStroke: ColorSpace;
  colorSpaceFill: ColorSpace;
  colorStroke: any;
  colorFill: any;
  textState: TextState;
  lineWidth: PdfNumber;
  lineCap: PdfNumber;
  lineJoin: PdfNumber;
  miterLimit: PdfNumber;
  dashPatternArray: PdfArray<PdfNumber>;
  dashPatternNumber: PdfNumber;
  renderingIntent: PdfName;
  strokeAdjustment: PdfBoolean;
  blendMode: PdfName | PdfArray<PdfPrimitive>;
  softMask: PdfDict | PdfName;
  alphaConstantStroke: PdfNumber;
  alphaConstantFill: PdfNumber;
  alphaSource: PdfBoolean;
  blackPointCompensation: PdfName;
  overprintStroke: PdfBoolean;
  overprintFill: PdfBoolean;
  overprintMode: PdfNumber;
  blackGeneration: any;
  undercolorRemoval: any;
  transfer: any;
  halftone: PdfDict | PdfStream | PdfName;
  flatness: PdfNumber;
  smoothness: PdfNumber;

  constructor() {
    this.ctm = [1, 0, 0, 1, 0, 0];
    this.clippingPath = [];
    this.colorSpaceStroke = new ColorSpace('DeviceGray');
    this.colorSpaceFill = new ColorSpace('DeviceGray');
    this.colorStroke = [new PdfNumber(0)];
    this.colorFill = [new PdfNumber(0)];
    this.textState = new TextState();
    this.lineWidth = new PdfNumber(1);
    this.lineCap = new PdfNumber(0);
    this.lineJoin = new PdfNumber(0);
    this.miterLimit = new PdfNumber(10);
    this.dashPatternArray = new PdfArray<PdfNumber>([]);
    this.dashPatternNumber = new PdfNumber(0);
    this.renderingIntent = new PdfName('RelativeColorimetric');
    this.strokeAdjustment = new PdfBoolean(false);
    this.blendMode = new PdfName('Normal');
    this.softMask = new PdfName('None');
    this.alphaConstantStroke = new PdfNumber(1);
    this.alphaConstantFill = new PdfNumber(1);
    this.alphaSource = new PdfBoolean(false);
    this.blackPointCompensation = new PdfName('Default');
    this.overprintStroke = new PdfBoolean(false);
    this.overprintFill = new PdfBoolean(false);
    this.overprintMode = new PdfNumber(0);
    this.blackGeneration = new PdfName('Default');
    this.undercolorRemoval = new PdfName('Default');
    this.transfer = new PdfName('Default');
    this.halftone = new PdfName('Default');
    this.flatness = new PdfNumber(1);
    this.smoothness = new PdfNumber(0.5);
  }

  clone(): GraphicsState {
    const clone = new GraphicsState();
    clone.ctm = [...this.ctm];
    clone.clippingPath = this.clippingPath;
    clone.colorSpaceStroke = this.colorSpaceStroke;
    clone.colorSpaceFill = this.colorSpaceFill;
    clone.colorStroke = this.colorStroke;
    clone.colorFill = this.colorFill;
    clone.textState = new TextState();
    clone.textState.characterSpacing = this.textState.characterSpacing;
    clone.textState.wordSpacing = this.textState.wordSpacing;
    clone.textState.horizontalScaling = this.textState.horizontalScaling;
    clone.textState.leading = this.textState.leading;
    clone.textState.font = this.textState.font;
    clone.textState.fontSize = this.textState.fontSize;
    clone.textState.renderingMode = this.textState.renderingMode;
    clone.textState.rise = this.textState.rise;
    clone.textState.knockout = this.textState.knockout;
    clone.textState.textMatrix = [...this.textState.textMatrix];
    clone.textState.textLineMatrix = [...this.textState.textLineMatrix];
    clone.lineWidth = this.lineWidth;
    clone.lineCap = this.lineCap;
    clone.lineJoin = this.lineJoin;
    clone.miterLimit = this.miterLimit;
    clone.dashPatternArray = this.dashPatternArray;
    clone.dashPatternNumber = this.dashPatternNumber;
    clone.renderingIntent = this.renderingIntent;
    clone.strokeAdjustment = this.strokeAdjustment;
    clone.blendMode = this.blendMode;
    clone.softMask = this.softMask;
    clone.alphaConstantStroke = this.alphaConstantStroke;
    clone.alphaConstantFill = this.alphaConstantFill;
    clone.alphaSource = this.alphaSource;
    clone.blackPointCompensation = this.blackPointCompensation;
    clone.overprintStroke = this.overprintStroke;
    clone.overprintFill = this.overprintFill;
    clone.overprintMode = this.overprintMode;
    clone.blackGeneration = this.blackGeneration;
    clone.undercolorRemoval = this.undercolorRemoval;
    clone.transfer = this.transfer;
    clone.halftone = this.halftone;
    clone.flatness = this.flatness;
    clone.smoothness = this.smoothness;
    return clone;
  }

  loadExtGState(extGState: PdfExtGState): void {
    if (extGState.has('LW')) this.lineWidth = extGState.get('LW') as PdfNumber;
    if (extGState.has('LC')) this.lineCap = extGState.get('LC') as PdfNumber;
    if (extGState.has('LJ')) this.lineJoin = extGState.get('LJ') as PdfNumber;
    if (extGState.has('ML')) this.miterLimit = extGState.get('ML') as PdfNumber;
    if (extGState.has('D')) {
      const dash = extGState.get('D') as PdfArray<PdfPrimitive>;
      this.dashPatternArray = dash[0] as PdfArray<PdfNumber>;
      this.dashPatternNumber = dash[1] as PdfNumber;
    }
    if (extGState.has('RI')) this.renderingIntent = extGState.get('RI') as PdfName;
    if (extGState.has('OP')) {
      this.overprintStroke = extGState.get('OP') as PdfBoolean;
      this.overprintFill = extGState.get('OP') as PdfBoolean;
    }
    if (extGState.has('op')) this.overprintFill = extGState.get('op') as PdfBoolean;
    if (extGState.has('OPM')) this.overprintMode = extGState.get('OPM') as PdfNumber;
    if (extGState.has('Font')) {
      throw new Error('Font not implemented');
    }
    if (extGState.has('BG')) this.blackGeneration = extGState.get('BG');
    if (extGState.has('BG2')) this.blackGeneration = extGState.get('BG2');
    if (extGState.has('UCR')) this.undercolorRemoval = extGState.get('UCR');
    if (extGState.has('UCR2')) this.undercolorRemoval = extGState.get('UCR2');
    if (extGState.has('TR')) this.transfer = extGState.get('TR');
    if (extGState.has('TR2')) this.transfer = extGState.get('TR2');
    if (extGState.has('HT')) this.halftone = extGState.get('HT') as PdfDict | PdfStream | PdfName;
    if (extGState.has('FL')) this.flatness = extGState.get('FL') as PdfNumber;
    if (extGState.has('SM')) this.smoothness = extGState.get('SM') as PdfNumber;
    if (extGState.has('SA')) this.strokeAdjustment = extGState.get('SA') as PdfBoolean;
    if (extGState.has('BM')) this.blendMode = extGState.get('BM') as PdfName;
    if (extGState.has('SMask')) this.softMask = extGState.get('SMask') as PdfDict | PdfName;
    if (extGState.has('CA')) this.alphaConstantStroke = extGState.get('CA') as PdfNumber;
    if (extGState.has('ca')) this.alphaConstantFill = extGState.get('ca') as PdfNumber;
    if (extGState.has('AIS')) this.alphaSource = extGState.get('AIS') as PdfBoolean;
    if (extGState.has('TK')) this.textState.knockout = extGState.get('TK') as PdfBoolean;
    if (extGState.has('UseBlackPtComp'))
      this.blackPointCompensation = extGState.get('UseBlackPtComp') as PdfName;
    if (extGState.has('HTO')) throw new Error('HTO not implemented');
  }

  toExtGState(): PdfExtGState {
    const extGState = new PdfExtGState();
    extGState.set('LW', this.lineWidth);
    extGState.set('LC', this.lineCap);
    extGState.set('LJ', this.lineJoin);
    extGState.set('ML', this.miterLimit);
    extGState.set('D', new PdfArray([this.dashPatternArray, this.dashPatternNumber]));
    extGState.set('RI', this.renderingIntent);
    extGState.set('OP', this.overprintStroke);
    extGState.set('op', this.overprintFill);
    extGState.set('OPM', this.overprintMode);
    extGState.set('BG2', this.blackGeneration);
    extGState.set('UCR2', this.undercolorRemoval);
    extGState.set('TR2', this.transfer);
    extGState.set('HT', this.halftone);
    extGState.set('FL', this.flatness);
    extGState.set('SM', this.smoothness);
    extGState.set('SA', this.strokeAdjustment);
    extGState.set('BM', this.blendMode);
    extGState.set('SMask', this.softMask);
    extGState.set('CA', this.alphaConstantStroke);
    extGState.set('ca', this.alphaConstantFill);
    extGState.set('AIS', this.alphaSource);
    extGState.set('TK', this.textState.knockout);
    extGState.set('UseBlackPtComp', this.blackPointCompensation);
    return extGState;
  }
}
