
const renderPdf = async (
  bytes: Uint8Array,
): Promise<HTMLCanvasElement[]> => {
  const localCanvasArray: HTMLCanvasElement[] = [];
  const pdf = await pdfjsLib.getDocument(Uint8Array.from(bytes)).promise;

  for (let page = 1; page <= pdf.numPages; page++) {
    const canvas = document.createElement('canvas');
    canvas.className = 'pdf-viewer-canvas';
    canvas.width = 4000;
    canvas.height = 6000;
    await renderPage(pdf, page, canvas);
    localCanvasArray.push(canvas);
  }

  return localCanvasArray as HTMLCanvasElement[];
};

const renderPage = async (pdf: any, pageNumber: number, canvas: any) => {
  const page = await pdf.getPage(pageNumber);
  const defaultDPI = 72;
  const targetDPI = 500;
  const scale = targetDPI / defaultDPI;

  // const scale = window.innerWidth / page.getViewport({ scale: 1 }).width;
  const viewport = page.getViewport({ scale: scale });
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  await page.render({
    canvasContext: canvas.getContext('2d'),
    viewport: viewport
  }).promise;
  console.log('finished rendering');
};

export { renderPage, renderPdf };
