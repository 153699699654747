<template>
  <Button
    type="button"
    :label="props.text"
    icon="pi pi-arrow-circle-down"
    :loading="props.isLoading"
    @click.prevent="click()"
    class="button"
    severity="primary"
    :disabled="props.disabled"
  />
</template>

<script setup lang="ts">
import Button from 'primevue/button';

const props = defineProps({
  text: String,
  isLoading: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false }
});
const emit = defineEmits(['click']);

const click = () => {
  if (props.isLoading) {
    return;
  }
  emit('click');
};
</script>
<style scoped>
/* .button {
  background-color: #939393 !important;
} */
</style>
