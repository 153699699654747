import { PdfBoolean, PdfName, PdfNumber } from '../..';

export default class TextState {
  characterSpacing: PdfNumber = new PdfNumber(0);
  wordSpacing: PdfNumber = new PdfNumber(0);
  horizontalScaling: PdfNumber = new PdfNumber(100);
  leading: PdfNumber = new PdfNumber(0);
  font: PdfName | null = null;
  fontSize: PdfNumber = new PdfNumber(0);
  renderingMode: PdfNumber = new PdfNumber(0);
  rise: PdfNumber = new PdfNumber(0);
  knockout: PdfBoolean = new PdfBoolean(false);
  textMatrix: number[] = [1, 0, 0, 1, 0, 0];
  textLineMatrix: number[] = [1, 0, 0, 1, 0, 0];
}
