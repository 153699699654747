<template>
  <div class="card full-screen-table-wrapper">
    <DataTable
      :value="materials"
      scrollable
      :loading="isLoading"
      group-rows-by="material_label.name"
      row-group-mode="subheader"
      expandable-row-groups
      v-model:expanded-row-groups="expandedRowGroups"
    >
      <template #header>
        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
          <TableNavigation></TableNavigation>
          <Button
            label="Add Material"
            icon="pi pi-plus-circle"
            rounded
            raised
            @click="openCreateOrUpdateModal(undefined)"
          />
        </div>
      </template>
      <template #empty>
        <p>No materials found.</p>
      </template>
      <template #groupheader="slotProps">
        <span>{{ slotProps.data?.material_label?.name ?? 'None' }}</span>
      </template>
      <Column header="Material Name">
        <template #body="slotProps">
          <p>{{ slotProps.data.display_name }}</p>
          <p id="internalName">{{ slotProps.data.internal_name }}</p>
        </template>
      </Column>
      <Column header="Logo">
        <template #body="slotProps">
          <img
            v-if="slotProps.data.image"
            :src="getBase64Image(slotProps.data.image)"
            :alt="slotProps.data.display_name"
            class="w-6rem shadow-2 border-round"
          />
          <ProgressSpinner style="width: 50px; height: 50px" v-else />
        </template>
      </Column>
      <Column header="Groups">
        <template #body="slotProps">
          <p v-for="g in slotProps.data.background_material_groups" :key="g.material_group.id">
            {{ g.material_group.name }}
          </p>
        </template>
      </Column>
      <Column header="Created">
        <template #body="slotProps">
          <p>{{ formatDate(new Date(slotProps.data.created_at)) }}</p>
        </template>
      </Column>
      <Column header="Actions">
        <template #body="slotProps">
          <!-- use local time format -->
          <Button
            icon="pi pi-pencil"
            class="lineActionButton"
            rounded
            @click="openCreateOrUpdateModal(slotProps.data.id)"
          />
          <Button
            icon="pi pi-trash"
            class="lineActionButton"
            rounded
            @click="deleteMaterial(slotProps.data.id)"
            :loading="deleteInProgress[slotProps.data.id]"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import { storeToRefs } from 'pinia';
import EditEntityModal from '@/components/EditEntityModal.vue';
import { useDialog } from 'primevue/usedialog';
import type { PrintwebEntity } from '@/types/EntityProperties';
import { formatDate, getBase64Image } from '@/helpers/formHelper';
import { useBackgroundMaterialStore } from '@/stores/admin/materialBackground';
import TableNavigation from '@/components/TableNavigation.vue';
import { ref } from 'vue';

// Entity Definition
const entity: PrintwebEntity = {
  typeName: 'Material',
  properties: [
    {
      label: 'ID',
      field: 'id',
      type: 'text',
      isHiddenOnForm: true
    },
    {
      label: 'Display Name',
      field: 'display_name',
      type: 'text',
      validate: (value: string) => (!value ? 'Display name is missing' : true)
    },
    {
      label: 'Internal Name',
      field: 'internal_name',
      type: 'text',
      validate: (value: string) => (!value ? 'Internal name is missing' : true)
    },
    {
      label: 'Image',
      field: 'image',
      type: 'image',
      validate(value) {
        return value?.length > 0 || 'Please select an image';
      }
    },
    {
      label: 'Label',
      field: 'label_id',
      type: 'material_label',
      validate(value) {
        return !!value || 'Please select a label';
      }
    },
    {
      label: 'Groups',
      field: 'background_material_groups',
      type: 'material_group',
      validate(value) {
        return value?.length > 0 || 'Please select at least one group';
      }
    }
  ]
};

const expandedRowGroups = ref();
const deleteInProgress = ref<Record<string, boolean>>({});

// Fetch data for table
const materialStore = useBackgroundMaterialStore(); // initialize instance store
const { materials, isLoading } = storeToRefs(materialStore); // get reactive refs from store for instances list
materialStore.subscribeBackgroundMaterials(); // run subscription

// Create / Edit Dialog
const dialog = useDialog();

const onSaveAction = async (data: any) => {
  console.log('[Instance Screen] onSaveAction', data);

  if (data.id) {
    // update instance
    const { background_material_groups: groups, ...rest } = data;
    await materialStore.updateBackgroundMaterialWithGroup(rest, groups);
  } else {
    data.background_material_groups = {
      data: data.background_material_groups.map((id: string) => {
        return { material_group_id: id };
      })
    };
    await materialStore.createBackgroundMaterial(data);
  }
};

function openCreateOrUpdateModal(id: string | undefined) {
  dialog.open(EditEntityModal, {
    data: {
      entityConfig: entity,
      payload: id ? materialStore.getMaterialById(id) : {},
      onSave: onSaveAction
    },
    props: {
      header: id ? 'Edit Material' : 'Create Material',
      closable: true,
      baseZIndex: 10000,
      draggable: false,
      modal: true
    }
  });
}

// Row Action handlers
async function deleteMaterial(id: string) {
  deleteInProgress.value[id] = true;
  await materialStore.deleteBackgroundMaterial(id);
  await new Promise((resolve) => setTimeout(resolve, 1000));
  deleteInProgress.value[id] = false;
}
</script>

<style scoped>
#internalName {
  font-size: 0.8em;
  font-style: italic;
  color: #999;
}

.lineActionButton {
  margin: 5px;
}
</style>
