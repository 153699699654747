<template>
  <div id="container">
    <Splitter id="edit-container" :gutter-size="8" layout="vertical">
      <SplitterPanel :size="60">
        <div class="element-selection">
          <TabView v-model:activeIndex="activeElementPanelIndex">
            <TabPanel>
              <template #header>
                <div class="p-tabview-title title-with-badge" data-pc-section="headertitle">
                  <span>Text</span>
                  <Badge
                    v-show="pdfStore.selectedTextElements.length"
                    :value="pdfStore.selectedTextElements.length"
                  />
                </div>
              </template>
              <div class="text-element-tab-container">
                <div
                  class="text-elements-filter-note"
                  v-show="pdfStore.textElementSelectionFilter !== null"
                >
                  <span>Here is a list of all elements close to your click.</span><br />
                  <span
                    @click="() => (pdfStore.textElementSelectionFilter = null)"
                    class="clear-filter-button"
                    >Clear filter</span
                  >
                </div>
                <DataTable
                  :value="textElementsToShow"
                  group-rows-by="page"
                  scrollable
                  row-group-mode="subheader"
                  showGridlines
                  scroll-height="flex"
                  size="small"
                  v-model:selection="pdfStore.selectedTextElements"
                  v-model:expandedRowGroups="expandedPagesText"
                  expandableRowGroups
                  class="width-100 text-element-table"
                  :globalFilterFields="['text']"
                  filterDisplay="row"
                  v-model:filters="textFilters"
                  dataKey="idForSelection"
                  :key="JSON.stringify(pdfStore.selectedTextElements)"
                  @update:selection="checkIfSelectionNeedsProMode"
                  v-show="textElementsToShow.length > 0"
                >
                  <template #empty>
                    <div class="p-text-center">
                      <p>No text found.</p>
                      <p>
                        Depending on how the PDF file was created, text might have been
                        automatically converted to a path.
                      </p>
                    </div>
                  </template>
                  <template #groupheader="slotProps">
                    <span class="font-bold">Page {{ slotProps.data.page + 1 }}</span>
                  </template>
                  <Column selectionMode="multiple" style="width: 0px"></Column>
                  <Column field="page" header="Page"></Column>
                  <Column field="text">
                    <template #filter="{ filterModel, filterCallback }">
                      <InputText
                        class="low-height"
                        v-model="filterModel.value"
                        type="text"
                        @input="filterCallback()"
                        placeholder="Search"
                      />
                    </template>
                    <template #body="slotProps">
                      <div class="textElement">
                        <span>{{ slotProps.data.text }}</span>
                      </div>
                    </template>
                  </Column>
                  <Column field="idForSelection" header="id" v-if="pdfStore.devMode"></Column>
                  <Column header="Style" v-if="pdfStore.proMode">
                    <template #body="slotProps">
                      <div class="style-icons">
                        <span
                          class="material-symbols-outlined"
                          v-if="slotProps.data.hasFill"
                          v-tooltip.left="
                            'Element has a fill color specified.\n\nColorspace(s):\n - ' +
                            slotProps.data.fillColorSpaceNames.join('\n - ')
                          "
                          >format_color_fill</span
                        >
                        <span
                          class="material-symbols-outlined"
                          v-if="slotProps.data.hasStroke"
                          v-tooltip.left="
                            'Element has a stroke color specified.\n\nColorspace(s):\n - ' +
                            slotProps.data.strokeColorSpaceNames.join('\n - ')
                          "
                          >border_color</span
                        >
                      </div>
                    </template>
                  </Column>
                  <Column>
                    <template #body="slotProps">
                      <Button
                        style="width: 1.6em; padding-block: 0;"
                        v-if="
                          slotProps.data.canBeSplitIntoLetters || slotProps.data.canBeSplitIntoWords
                        "
                        v-tooltip.left="
                          'Split into inidividual ' +
                          (slotProps.data.canBeSplitIntoWords ? 'words' : 'letters')
                        "
                        @click="
                          splitTextElement(
                            slotProps.data.page,
                            slotProps.data.rawElement,
                            slotProps.data.canBeSplitIntoWords
                          )
                        "
                        severity="secondary"
                        outlined
                      >
                        <template #icon>
                          <span class="material-symbols-outlined mr-0"
                            >format_letter_spacing_2</span
                          >
                        </template>
                      </Button>
                      <span
                        v-if="slotProps.data.isUnsplittableWithMultipleWords"
                        class="material-symbols-outlined splitInfo"
                        v-tooltip.left="
                          'These letters were defined with a particular spacing, hence cannot be split up'
                        "
                      >
                        format_letter_spacing_2
                      </span>
                    </template>
                  </Column>
                </DataTable>
                <p
                  v-show="
                    pdfStore.textElementSelectionFilter !== null &&
                    pdfStore.textElementSelectionFilter.length === 0
                  "
                >
                  No text elements found.
                </p>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <div class="p-tabview-title title-with-badge" data-pc-section="headertitle">
                  <span>Paths</span>
                  <Badge
                    v-show="pdfStore.selectedPathElements.length"
                    :value="pdfStore.selectedPathElements.length"
                  />
                </div>
              </template>
              <div class="path-element-tab-container">
                <div
                  class="path-elements-filter-note"
                  v-show="pdfStore.pathElementSelectionFilter !== null"
                >
                  <span>Here is a list of all elements close to your click.</span><br />
                  <span
                    @click="() => (pdfStore.pathElementSelectionFilter = null)"
                    class="clear-filter-button"
                    >Clear filter</span
                  >
                </div>
                <DataTable
                  :value="pathElementsToShow"
                  group-rows-by="page"
                  scrollable
                  row-group-mode="subheader"
                  showGridlines
                  scroll-height="flex"
                  size="small"
                  v-model:selection="pdfStore.selectedPathElements"
                  v-model:expandedRowGroups="expandedPagesPath"
                  expandableRowGroups
                  class="width-100 path-element-table"
                  @update:selection="checkIfSelectionNeedsProMode"
                  :key="JSON.stringify(pdfStore.selectedPathElements)"
                  dataKey="idForSelection"
                  v-show="pathElementsToShow.length > 0"
                >
                  <template #empty>
                    <div class="p-text-center">
                      <span>No path elements found.</span>
                    </div>
                  </template>
                  <template #groupheader="slotProps">
                    <span class="font-bold">Page {{ slotProps.data.page + 1 }}</span>
                  </template>
                  <Column selectionMode="multiple" style="width: 0px"></Column>
                  <Column field="page" header="Page"></Column>
                  <Column header="Preview">
                    <template #body="slotProps">
                      <div class="svg-preview-container">
                        <SvgPreview
                          :svgElement="slotProps.data"
                          :viewport="viewportsPerPage[slotProps.data.page]"
                          :key="JSON.stringify(slotProps.data)"
                        />
                      </div>
                    </template>
                  </Column>
                  <Column field="idForSelection" header="id" v-if="pdfStore.devMode"></Column>
                  <Column header="Style" style="width: 0;" v-if="pdfStore.proMode">
                    <template #body="slotProps">
                      <div class="style-icons">
                        <span
                          class="material-symbols-outlined"
                          v-if="slotProps.data.hasFill"
                          v-tooltip.left="
                            'Element has a fill color specified.\n\nColorspace(s):\n - ' +
                            slotProps.data.fillColorSpaceNames.join('\n - ')
                          "
                          >format_color_fill</span
                        >
                        <span
                          class="material-symbols-outlined"
                          v-if="slotProps.data.hasStroke"
                          v-tooltip.left="
                            'Element has a stroke color specified.\n\nColorspace(s):\n - ' +
                            slotProps.data.strokeColorSpaceNames.join('\n - ')
                          "
                          >border_color</span
                        >
                      </div>
                    </template>
                  </Column>
                </DataTable>
                <p v-show="!pathElementsToShow.length">No path elements found.</p>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </SplitterPanel>
      <SplitterPanel :size="40">
        <div class="edit-options">
          <DataTable
            :value="selectableColors"
            class="datatable"
            showGridlines
            scrollable
            scroll-height="flex"
            size="small"
            v-model:selection="selectedColors"
            dataKey="id"
          >
            <Column style="width: 0px;">
              <template #body="slotProps">
                <Checkbox
                  binary
                  :modelValue="selectedColors.some((color) => color.id === slotProps.data.id)"
                  @input="(checked) => {
                    const index = selectedColors.findIndex((color) => color.id === slotProps.data.id)
                    if(index > -1)
                      selectedColors.splice(index, 1)
                    else
                      selectedColors.push(slotProps.data)
                  }"
                />
              </template>
            </Column>
            <Column field="name" header="Color">
              <template #body="slotProps">
                <div class="name">
                  <span>{{ slotProps.data.display_name }}</span>
                </div>
              </template>
            </Column>
            <Column field="intensity" header="Intensity" style="text-align: center; width: 0px;">
              <template #body="slotProps">
                <!-- <div class="intensity">
                  <span>{{ slotProps.data.intensity }}</span>
                  <Slider v-model="slotProps.data.intensity" :min="0" :max="100" />
                </div> -->
                <IntensityPopupSlider
                  v-model="slotProps.data.intensity"
                  :min="slotProps.data.min_intensity_percent"
                  :max="slotProps.data.max_intensity_percent"
                  inputId="intensity"
                  v-if="slotProps.data.min_intensity_percent < slotProps.data.max_intensity_percent"
                />
                <span class="ml-1" v-if="slotProps.data.min_intensity_percent < slotProps.data.max_intensity_percent">%</span>
                <span v-else v-tooltip.left="'This color has a fixed intensity.'">-</span>
              </template>
            </Column>
            <Column field="mix" style="width: 0px;">
              <template #header>
                <div class="p-column-header-content">
                  <span class="p-column-title mr-1">Mix?</span>
                  <QuestionMarkTooltip
                    text="Certain effets can be combined with the existing colors."
                  />
                </div>
              </template>
              <template #body="slotProps">
                <div class="mix">
                  <InputSwitch
                    v-model="slotProps.data.mix"
                    :binary="true"
                    v-if="slotProps.data.allow_mixing === null"
                    v-tooltip.left="'Mix this color with underlaying colors.'"
                  />
                  <span v-else v-tooltip.left="'This color cannot be mixed.'">-</span>
                </div>
              </template>
            </Column>
          </DataTable>
          <div class="edit-buttons pl-2 pr-2">
            <Button
              type="button"
              label="Apply"
              class="button"
              severity="primary"
              raised
              v-if="!pdfStore.proMode"
              @click="applyEdit(true, true)"
              :disabled="
                !(
                  (pdfStore.selectedTextElements.length || pdfStore.selectedPathElements.length) &&
                  selectedColors.length
                )
              "
            >
              <template #icon>
                <span class="p-button-icon p-button-icon-left material-symbols-outlined"
                  >format_color_fill</span
                >
              </template>
            </Button>
            <Button
              type="button"
              label="Fill"
              class="button"
              severity="primary"
              raised
              v-if="pdfStore.proMode"
              @click="applyEdit(true, false)"
              :disabled="
                !(
                  (pdfStore.selectedTextElements.length || pdfStore.selectedPathElements.length) &&
                  selectedColors.length &&
                  colorsAllowFill
                )
              "
            >
              <template #icon>
                <span class="p-button-icon p-button-icon-left material-symbols-outlined"
                  >format_color_fill</span
                >
              </template>
            </Button>
            <Button
              type="button"
              label="Stroke"
              class="button"
              severity="primary"
              raised
              v-if="pdfStore.proMode"
              @click="applyEdit(false, true)"
              :disabled="
                !(
                  (pdfStore.selectedTextElements.length || pdfStore.selectedPathElements.length) &&
                  selectedColors.length &&
                  colorsAllowStroke
                )
              "
            >
              <template #icon>
                <span class="p-button-icon p-button-icon-left material-symbols-outlined"
                  >border_color</span
                >
              </template>
            </Button>
            <Button
              type="button"
              label="Reset"
              v-tooltip.top="'Reset all selected elements to their original color'"
              class="button"
              severity="secondary"
              raised
              v-if="showUndoButton"
              @click="resetColors"
            >
              <template #icon>
                <span class="p-button-icon p-button-icon-left material-symbols-outlined">undo</span>
              </template>
            </Button>
          </div>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
</template>

<script setup lang="ts">
import type { IsLoading } from '@/App.vue';
import { useSpotColorStore } from '@/stores/instance/spotColor';
import { usePdfStore } from '@/stores/pdf';
import { FilterMatchMode } from 'primevue/api';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import { computed, inject, ref, watch } from 'vue';
import { TextElement } from '../../../pdfLib';
import IntensityPopupSlider from './IntensityPopupSlider.vue';
import SvgPreview from './SvgPreview.vue';
import QuestionMarkTooltip from './QuestionMarkTooltip.vue';
import Checkbox from 'primevue/checkbox';

const colorStore = useSpotColorStore();

const colorsDisplayed = computed(() => {
  return colorStore.spotColors.map((color) => ({
    ...color,
    min_intensity_percent: color.min_intensity * 100,
    max_intensity_percent: color.max_intensity * 100,
    intensity: 100,
    allow_mixing: color.mixing_allowed,
    mix:
      color.mixing_allowed === null || typeof color.mixing_allowed === 'undefined'
        ? false
        : color.mixing_allowed,
    imageData: null
  }));
});
const colorsDisplayedRef = ref<NonNullable<typeof colorsDisplayed.value>>([]);
watch(
  colorsDisplayed,
  (value) => {
    colorsDisplayedRef.value = value;
  },
  { immediate: true, deep: true }
);

const pdfStore = usePdfStore();

const selectedColors = ref<NonNullable<typeof colorsDisplayed.value>>([]);
const selectableColors = computed(() => {
  return colorsDisplayedRef.value.filter((colorThatMightBeCompatible) => {
    if(selectedColors.value.some((selectedColor) => selectedColor.id === colorThatMightBeCompatible.id ))
      return true
    return selectedColors.value.every((alreadySelectedColor) => {
      console.log(alreadySelectedColor.combinable_colors)
      return alreadySelectedColor.combinable_colors.some((colorThatIsCombineableWithTheSelectedOne) => {
        return colorThatIsCombineableWithTheSelectedOne.id === colorThatMightBeCompatible.id
      }) ||
      colorThatMightBeCompatible.combinable_colors.some((colorThatCanBeCombinedWithThePotential) => {
        return colorThatCanBeCombinedWithThePotential.id === alreadySelectedColor.id
      })
    })
  })
})
const colorsAllowStroke = computed(() => {
  return selectedColors.value.every((color) => color.stroke_allowed);
});
const colorsAllowFill = computed(() => {
  return selectedColors.value.every((color) => color.fill_allowed);
});

const expandedPagesText = ref([0]);
const expandedPagesPath = ref([0]);

const viewportsPerPage = ref(pdfStore.pdfDocument!.pageViewport);

const textFilters = ref({
  text: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS
  }
});

const isLoading = inject('isLoading') as IsLoading;

const splitTextElement = (page: number, element: TextElement, preserveWords: boolean) => {
  isLoading!.set(true);
  try {
    pdfStore.pdfDocument!.parsedContentStreamPerPage[page].splitTextElement(element, preserveWords);
  } catch (e) {
    console.error(e);
  }

  pdfStore.pdfDocument!.triggerChange(pdfStore.pdfDocument!.compile());
  isLoading!.set(false);
};

const allSelectedElements = computed(() => {
  const selectedTextElements = pdfStore.textElements.filter((element) =>
    pdfStore.selectedTextElements.some(
      (selectedElement) => selectedElement.idForSelection === element.idForSelection
    )
  );
  const selectedPathElements = pdfStore.pathElements.filter((element) =>
    pdfStore.selectedPathElements.some(
      (selectedElement) => selectedElement.idForSelection === element.idForSelection
    )
  );

  return [...selectedTextElements, ...selectedPathElements];
});

const applyEdit = (fill: boolean, stroke: boolean) => {
  isLoading!.set(true);

  setTimeout(() => {
    const pageIndices = new Set<number>();

    allSelectedElements.value.forEach((element) => {
      const page = element.page;
      pageIndices.add(page);
      const contentStream = pdfStore.pdfDocument!.parsedContentStreamPerPage[page];
      selectedColors.value.forEach((color) => {
        if (element.hasUndoeableOperations) contentStream.resetElement(element.printwebId);
        if (color.mix) {
          contentStream.duplicateElement(element.printwebId);
        }
        contentStream.setElementMultiply(element.printwebId, color.mix);
        if (color.do_overprint)
          contentStream.setElementOverprintMode(element.printwebId, color.overprint_mode);
        if (fill) {
          contentStream.setElementFillOverprint(element.printwebId, color.do_overprint);
          contentStream.setElementFillColor(element.printwebId, color, color.intensity / 100);
        }
        if (stroke) {
          contentStream.setElementStrokeOverprint(element.printwebId, color.do_overprint);
          contentStream.setElementStrokeColor(element.printwebId, color, color.intensity / 100);
        }
      });
    });

    pageIndices.forEach((page) => {
      const contentStream = pdfStore.pdfDocument!.parsedContentStreamPerPage[page];
      contentStream.execute();
    });

    const compiled = pdfStore.pdfDocument!.compile();
    pdfStore.pdfDocument!.triggerChange(compiled);
  }, 100);
};

const checkIfSelectionNeedsProMode = () => {
  if (pdfStore.proMode) return;

  if (
    pdfStore.textElements.some(
      (element) =>
        element.hasStroke &&
        element.hasFill &&
        pdfStore.selectedTextElements.some(
          (selectedElement) => selectedElement.idForSelection === element.idForSelection
        )
    )
  ) {
    pdfStore.proMode = true;
    return;
  }

  if (
    pdfStore.pathElements.some(
      (element) =>
        element.hasStroke &&
        element.hasFill &&
        pdfStore.selectedPathElements.some(
          (selectedElement) => selectedElement.idForSelection === element.idForSelection
        )
    )
  ) {
    pdfStore.proMode = true;
    return;
  }
};

const activeElementPanelIndex = ref(0);

const textElementsToShow = computed(() => {
  return pdfStore.textElementSelectionFilter?.map((_, index) => pdfStore.textElementSelectionFilter![pdfStore.textElementSelectionFilter!.length - 1 - index]) || pdfStore.textElements;
});
const pathElementsToShow = computed(() => {
  return pdfStore.pathElementSelectionFilter?.map((_, index) => pdfStore.pathElementSelectionFilter![pdfStore.pathElementSelectionFilter!.length - 1 - index]) || pdfStore.pathElements;
});

watch(
  () => [textElementsToShow.value, pathElementsToShow.value],
  (val) => {
    const texts = val[0];
    const paths = val[1];

    if (texts.length === 0 && paths.length === 0) return;
    if (texts.length === 0) activeElementPanelIndex.value = 1;
    if (paths.length === 0) activeElementPanelIndex.value = 0;
  },
  { immediate: true }
);

const showUndoButton = computed(() => {
  const selectedTextElements = pdfStore.textElements.filter((element) =>
    pdfStore.selectedTextElements.some(
      (selectedElement) => selectedElement.idForSelection === element.idForSelection
    )
  );
  if (selectedTextElements.some((element) => element.hasUndoeableOperations)) return true;

  const selectedPathElements = pdfStore.pathElements.filter((element) =>
    pdfStore.selectedPathElements.some(
      (selectedElement) => selectedElement.idForSelection === element.idForSelection
    )
  );
  console.log(selectedPathElements)
  if (selectedPathElements.some((element) => element.hasUndoeableOperations)) return true;

  return false;
});

const resetColors = () => {
  isLoading!.set(true);

  setTimeout(() => {
    const pageIndices = new Set<number>();

    allSelectedElements.value.forEach((element) => {
      const page = element.page;
      pageIndices.add(page);
      const contentStream = pdfStore.pdfDocument!.parsedContentStreamPerPage[page];
      contentStream.resetElement(element.printwebId);
    });

    pageIndices.forEach((page) => {
      const contentStream = pdfStore.pdfDocument!.parsedContentStreamPerPage[page];
      contentStream.execute();
    });

    const compiled = pdfStore.pdfDocument!.compile();
    pdfStore.pdfDocument!.triggerChange(compiled);
    isLoading!.set(false);
  }, 100);
};
</script>

<style scoped lang="scss">
#container {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr max-content;
}

.edit-options {
  overflow: hidden;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr max-content;
  gap: 0.5rem;
  border-top: 1px solid var(--surface-d);

  .datatable {
    min-height: 0;
  }
}

.edit-buttons,
.element-selection {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.edit-buttons {
  padding-inline: 0.5rem;
  padding-block-end: 0.5rem;
}

.edit-buttons button {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1px;
  // background-color: #fff !important;
  // border: 1px solid var(--surface-d) !important;
  // color: #343a40 !important;
}

.name {
  white-space: nowrap;
}

.mix {
  width: 100%;
  display: grid;
  place-items: center;
}

.element-selection,
.element-selection > * {
  height: 100%;
  width: 100%;
}

.element-selection {
  border-bottom: 1px solid var(--surface-d);
}

.text-element-tab-container {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: hidden;

  :deep(thead tr:first-child) {
    display: none;
  }
}

.path-element-tab-container {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: hidden;
}

.text-element-table,
.path-element-table {
  height: initial !important;
  min-height: 0;
  grid-row: 2;
}

.text-elements-filter-note,
.path-elements-filter-note {
  padding: 0.5rem;
}

.clear-filter-button {
  cursor: pointer;
  text-decoration: underline;
}

.low-height {
  height: 1.5em;
}

.svg-preview-container {
  display: grid;
  place-items: center;
}

.textElement {
  white-space: pre-wrap;
  word-break: break-word;
}

.splitInfo {
  display: block;
  text-align: center;
}

.style-icons {
  display: flex;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  color: #64748B;
}

.title-with-badge {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 0.5em;

  :deep(.p-badge) {
    margin-block: -10em; // this is needed so that the badge doesn't enlarge the title
  }
}
</style>
