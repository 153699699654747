<template>
  <header>
    <Button icon="pi pi-arrow-left" @click="back" v-if="showReturnArrow" severity="secondary" raised />
    <ImageView
      :src="instanceStore.logo"
      alt="logo"
      v-if="instanceStore.isReady && instanceStore.logo && !instanceStore.isError"
      class="logo"
    />
    <img v-else-if="instanceStore.isError" src="../assets/logo.svg" alt="logo" class="logo" />
    <div v-if="showReturnArrow" class="edit-options">
      <div class="pro-mode" @click="handleProModeClick">
        <span>Pro-Mode</span>
        <InputSwitch v-model="pdfStore.proMode" @click.stop />
      </div>
      <AwaitButton text="Download" @click="download" id="download-button" 
      raised />
    </div>
    <Button
      v-if="userStore.authToken && showLogoutButton"
      icon="pi pi-sign-out"
      id="logout-button"
      @click="userStore.logout()"
    />
  </header>
</template>

<script setup lang="ts">
import { usePdfStore } from '@/stores/pdf';
import { computed, inject, watch } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import type { IsLoading } from '@/App.vue';
import AwaitButton from './AwaitButton.vue';
import JSZip from 'jszip';
import { useUserStore } from '@/stores/admin/user';
import InputSwitch from 'primevue/inputswitch';
import { useSpotColorStore } from '@/stores/instance/spotColor';
import { useInstanceStore } from '@/stores/instance/instance';
import ImageView from './ImageView.vue';

const router = useRouter();
const userStore = useUserStore();
const isLoading = inject('isLoading') as IsLoading;

const instanceStore = useInstanceStore();
const pdfStore = usePdfStore();
const colorStore = useSpotColorStore();
const all_technical_names = computed(() =>
  colorStore.spotColors.map((color) => color.technical_name)
);

const showReturnArrow = computed(
  () => !isLoading?.value && router.currentRoute.value.name === 'edit'
);

const showLogoutButton = computed(() => !router.currentRoute.value.meta.requiresValidUUID);

const back = () => {
  pdfStore.clear();
  isLoading!.set(false);
  router.push('/upload');
};

const download = async () => {
  const zip = new JSZip();
  zip.file('everything.pdf', pdfStore.pdfDocument!.compile());

  zip.file(
    'no_spot_colors.pdf',
    pdfStore.pdfDocument!.getPdfWithoutSpotColors(all_technical_names.value)
  );

  const allUsedColorSpaces = [...new Set<string>(pdfStore.pdfDocument!.parsedContentStreamPerPage.flatMap((page) => page.getAllUsedColorSpaces().flatMap((color) => color.name)))];
  colorStore.spotColors.forEach((color) => {
    if(!allUsedColorSpaces.includes(color.technical_name))
      return;
    const colorName = color.internal_name.replace(/ /g, '_');
    const spotColor = zip.folder(colorName)!;
    spotColor.file(`only_${colorName}.pdf`, pdfStore.pdfDocument!.getPdfWithOnlySpotColor(color.technical_name));
    spotColor.file(`no_${colorName}.pdf`, pdfStore.pdfDocument!.getPdfWithoutSpotColors([color.technical_name]))
  });

  const previews = zip.folder('preview')!;
  (await pdfStore.previewBlobs!).forEach((image, index) => {
    previews.file(`page-${index + 1}.png`, image);
  });

  zip.generateAsync({ type: 'blob' }).then((content) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(content);
    link.download = 'download.zip';
    link.click();
  });
};

const handleProModeClick = (e) => {
  if (e.ctrlKey) {
    pdfStore.devMode = !pdfStore.devMode;
    return;
  }

  pdfStore.proMode = !pdfStore.proMode;
};
</script>

<style scoped lang="scss">
header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 0.5em 1em;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;

  img {
    grid-column: 2;
  }
}

#download-button {
  width: 10em;
}

#logout-button {
  justify-self: end;
}

.edit-options {
  justify-self: end;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5em;
}
.pro-mode {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  justify-items: center;
  gap: 0.5em;
  padding: 0.5rem;
  font-size: smaller;
  border: 1px solid var(--surface-d);
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.logo {
  height: 40px;
}
</style>
